<div class="modal fade" tabindex="-1" role="dialog" bsModal #modal="bs-modal">

	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable quickview">

		<div *ngIf="wishList" class="modal-content">

			<div class="modal-header">
				<h2 class="modal-title">{{'HEADER_WISHLIST' | translate}}</h2>
				<button type="button" class="quickview__close" (click)="modal.hide()">
					<app-icons icon="cross-12"></app-icons>
				</button>
			</div>

			<div class="modal-body">
				<div class="quickview__gallery">
					<div class="d-flex flex-column justify-content-sm-between" mat-dialog-actions>
						<div *ngIf="!wishList.length" class="block-empty">
							<div class="container">
								<div class="block-empty__body">
									<h3 class="block-empty__title"
										style="font-weight: bold">{{ 'HEADER_WISHLIST_EMPTY_TITLE'|translate }}</h3>
								</div>
							</div>
						</div>

						<mat-selection-list [multiple]="false">
							<ul style="list-style: none" class="p-0">
								<li *ngFor="let list of updatedList" [value]="list.id">
									<div class="d-flex justify-content-start gap-3">
										<mat-checkbox [checked]="list.isContain" class="example-margin"
													  (click)="addRemoveToList(list.id, list)">
											<div style="width: 150px; overflow: hidden; text-overflow: ellipsis;"
												 title="{{list.name}}">
												{{list.name}}
											</div>
										</mat-checkbox>
									</div>
								</li>
							</ul>
						</mat-selection-list>
					</div>

				</div>
			</div>
			<div class="modal-footer">
				<div class="quickview__product">

					<div class="quickview__product-name">
						<div mat-dialog-actions>
							<div><h5>{{'CREATE_WISH_LIST' | translate}}</h5></div>
						</div>
					</div>
					<div class="d-flex quickview__product-actions">
						<div class="quickview__product-actions-item">
							<div>
								<form [formGroup]="wishListForm">
									<input type="text" formControlName="wishListName" class="form-control">
								</form>
							</div>

						</div>
						<div class="quickview__product-actions-item quickview__product-actions-item--addtocart">
							<div class="d-flex flex-row justify-content-sm-end">
								<button [disabled]="wishListForm.invalid" class="btn btn-primary btn-block"
										(click)="addList()">
									{{'CREATE_LIST' | translate}}
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
