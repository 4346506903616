import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Location} from "@angular/common";
import {DashboardService} from "../../../dashboard.service";
import {Base} from "../../../../../core/base";
import {SnackBarService} from "../../../../../core/services/snack-bar.service";

@Component({
	selector: 'app-change-order-status',
	templateUrl: './change-order-status.component.html'
})
export class ChangeOrderStatusComponent extends Base implements OnInit {

	constructor(private dialogRef: MatDialogRef<ChangeOrderStatusComponent>,
				@Inject(MAT_DIALOG_DATA) public data) {
		super();
	}

	ngOnInit(): void {
	}

	cancel(): void {
		this.dialogRef.close();
	}

	changeStatus() {
		this.dialogRef.close(true);
	}
}
