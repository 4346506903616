<div style="padding: 15px;" class="modal-content mt-3">
	<button class="quickview__close" (click)="close()">
		<app-icons icon="cross-12"></app-icons>
	</button>
	<input type="file" style="display: block" accept="image/*" (change)="onChange($event)">
	<div class="d-flex flex-row justify-content-between">
		<button class="btn btn-primary mt-3" (click)="uploadAvatar()">
			{{'BUTTON_SAVE' | translate}}
		</button>
	</div>
</div>
