import {Component, HostBinding, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {AuthService} from "../../auth/auth.service";
import {FormControl, FormGroup} from "@angular/forms";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ShopAddressesComponent} from "../../../shared/components/shop-addresses/shop-addresses.component";
import {DashboardService} from "../../dashboard/dashboard.service";
import {ProductService} from "../../product/services/product.service";
import {GlobalService} from "../../../core/services/global.service";
import {Base} from "../../../core/base";
import {takeUntil} from "rxjs/operators";
import {ApiResultModel} from "../../../core/interfaces/api-result.model";
import {GetShopResModel, ShopModel} from "../../../core/interfaces/shop.model";
import {CategoriesContentItemModel} from "../../../core/interfaces/categories/categories.model";
import {DateAdapter} from "@angular/material/core";
import {environment} from "../../../../environments/environment";
import {ShopAddressesService} from "../../../core/services/shop-addresses.service";
import {ShopStateEnum} from "../../../core/enums/shop.enum";
import {TranslationKeys} from "../../../core/data/translation-keys";
import {ToastrService} from "ngx-toastr";

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html'
})
export class HeaderComponent extends Base implements OnInit {
	showDepartments: boolean;
	categories: CategoriesContentItemModel[] = [];
	sellerUrl = environment.sellerUrl;
	showShopsPopup: boolean;
	shopQuery = {
		page: 1,
		size: 10,
		sort: 's.updatedAt',
		order: 'desc',
		search: '',
		state: ShopStateEnum.Active
	};
	pages = [
		{title: 'About Us', url: 'pages/about-us'},
		{title: 'Contact Us', url: 'pages/contact-us'},
		{title: 'Terms & Conditions', url: '/pages/terms-conditions'},
		{title: 'FAQ', url: '/pages/asked-questions'},
	]

	dialogRef: MatDialogRef<ShopAddressesComponent>
	shops: ShopModel[] = [];
	@HostBinding('class.main-menu') classMainMenu = true;

	form: FormGroup = new FormGroup({
		shop: new FormControl()
	})

	constructor(public translate: TranslateService,
				private router: Router,
				public authService: AuthService,
				private shopAddressesService: ShopAddressesService,
				private dialog: MatDialog,
				private toastr: ToastrService,
				private dashboardService: DashboardService,
				private productService: ProductService,
				private globalService: GlobalService,
				private dateAdapter: DateAdapter<any>) {
		super();
	}

	get isAuthenticated() {
		return this.authService.isAuthenticated();
	}

	ngOnInit(): void {
		if (this.isAuthenticated) {
			this.authService.getAuth().pipe(takeUntil(this.destroy$)).subscribe({
				next: (res) => {
					if (res.payload?.customer?.id) {
						this.getShops(this.shopQuery);
					}
				}
			})
		}

		this.authService.changeShopAddress.pipe(takeUntil(this.destroy$)).subscribe({
			next: (res) => {
				if (res && !localStorage.getItem('shop')) {}
				this.form.controls['shop'].setValue(res?.shopName);
				localStorage.setItem('shop', JSON.stringify(res));
			}
		})
	}

	hideCategoryMenu(): void {
		this.globalService.onHideCategoriesMenu.next(true);
	}

	private getShops(shopQuery): void {
		this.dashboardService.getShops(shopQuery).pipe(takeUntil(this.destroy$)).subscribe((res: ApiResultModel<GetShopResModel>) => {
			this.shops = res.payload.content;
			if (localStorage.getItem('shop') !== 'undefined') {
				this.form.controls['shop'].setValue(JSON.parse(localStorage.getItem('shop')).shopName);
				this.authService.changeShopAddress.next(JSON.parse(localStorage.getItem('shop')));
			} else if (this.shops.length > 0) {
				this.form.controls['shop'].setValue(this.shops[0]?.shopName);
				this.authService.changeShopAddress.next(this.shops[0]);
				localStorage.setItem('shop', JSON.stringify(this.shops[0]));
			}
		});
	}

	selectShop(): void {
		if (this.showShopsPopup || !localStorage.getItem('customerId')) {
			this.toastr.info(this.translate.instant(TranslationKeys.NeedAccount));
			return;
		}

		this.showShopsPopup = true;
		this.shopAddressesService.show().subscribe({
			complete: () => {
				this.showShopsPopup = false;
				this.authService.changeShopAddress.subscribe(res => {
					if (res) {
						localStorage.setItem('shop', JSON.stringify(res));
						this.form.controls['shop'].setValue(res.shopName);
					}
				})
			},
		});
	}

	goToCategory(event): void {
		this.showDepartments = false;
		this.productService.changeCategoryId.next(event.node.data);
		this.productService.goToCategory(event.node.data);
	}

	changeLang(language: string) {
		localStorage.setItem('locale', language);
		this.translate.use(language);
		this.dateAdapter.setLocale(language);
	}

	goToHome(): void {
		this.router.navigateByUrl('/home');
	}
}
