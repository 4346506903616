import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ProductService} from "../../product/services/product.service";
import {AppConfig} from "../../../core/config/appConfig";

export type BlockBrandsLayout = 'columns-8-full' | 'columns-7-sidebar';

@Component({
	selector: 'app-block-suppliers',
	templateUrl: './block-suppliers.component.html'
})
export class BlockSuppliersComponent implements OnInit {

	@Input() layout: BlockBrandsLayout = 'columns-8-full';
	@Input() suppliers = [];
	@HostBinding('class.block') classBlock = true;
	@HostBinding('class.block-brands') classBlockBrands = true;
	@HostBinding('class.block-brands--layout--columns-8-full') get classBlockBrandsLayoutColumns8Full(): boolean {
		return this.layout === 'columns-8-full';
	}
	@HostBinding('class.block-brands--layout--columns-7-sidebar') get classBlockBrandsLayoutColumns7Sidebar(): boolean {
		return this.layout === 'columns-7-sidebar';
	}
	imagePath = AppConfig.imagePath;

	constructor(private router: Router,
				private productService: ProductService) {
	}

	ngOnInit(): void {
	}

	goToSupplierBusinessPage(supplier): void {
		this.productService.goToCategory({ supplier: supplier.id })
	}

}
