import {Component, OnInit} from '@angular/core';
import {HomeService} from "./home.service";
import {GlobalService} from "../../core/services/global.service";
import {SupplierContentItemModel} from "../../core/interfaces/users/supplier/suppliers-list.model";
import {ProductsContentItemModel, ProductsDataModel} from "../../core/interfaces/products/products-data.model";
import {takeUntil} from "rxjs/operators";
import {AuthService} from "../auth/auth.service";
import {Base} from "../../core/base";
import {ApiResultModel} from "../../core/interfaces/api-result.model";

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html'
})

export class HomeComponent extends Base implements OnInit {
	latestNewsProducts = [];
	featuredProducts: ProductsContentItemModel[] = [];
	saleProducts: ProductsContentItemModel[] = [];
	newArrivalsProducts: ProductsContentItemModel[] = [];
	topRatedProducts: ProductsContentItemModel[] = [];
	bestsellersProducts: ProductsContentItemModel[] = [];
	specialOffersProducts: ProductsContentItemModel[] = [];
	topDiscountsProducts: ProductsContentItemModel[] = [];
	suppliers: SupplierContentItemModel[] = [];
	blockZones: any;
	loading: boolean;
	shopId: string;
	shop: any;
	query = {
		period: 30,
		category: '',
		type: '',
		shopId: ''
	}

	constructor(
		private homeService: HomeService,
		private authService: AuthService,
		private globalService: GlobalService) {
		super();
	}

	ngOnInit(): void {
		this.shop = localStorage.getItem('shop') != 'undefined' ? JSON.parse(localStorage.getItem('shop')) : {};
		if (this.shop?.id) {
			this.query.shopId = this.shop.id;
		} else {
			delete this.query.shopId;
		}

		if (this.authService.isAuthenticated()) {
			this.getSuppliers();
		}
		this.getLatestNewsProducts();
		this.getFeaturedProducts();
		this.getTopDeals();
		this.getNewArrivals();
		this.getTopRatedProducts();
		this.getBestSellersProducts();
		this.getTopDiscounts();
	}

	private getProductsByShop(): void {
		this.authService.changeShopAddress.pipe(takeUntil(this.destroy$)).subscribe(res => {
			if (res) {
				this.shopId = res?.id;
				if (!this.shopId) {
					delete this.query.shopId;
				} else {
					this.query.shopId = this.shopId;
				}
				this.getLatestNewsProducts();
				this.getFeaturedProducts();
				this.getTopDeals();
				this.getNewArrivals();
				this.getTopRatedProducts();
				this.getBestSellersProducts();
				this.getTopDiscounts();
			}
		})
	}

	private getLatestNewsProducts(): void {
		this.loading = true;
		this.query.type = 'latest-news';
		this.homeService.getPosts(this.query)
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.latestNewsProducts = res.payload.content;
				this.loading = false;
			})
	}

	private getFeaturedProducts(): void {
		this.loading = true;
		this.query.type = 'featured';
		this.globalService.getHomePageAllProducts(this.query)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (res: ApiResultModel<ProductsDataModel>) => {
					this.featuredProducts = res.payload.content;
					this.loading = false;
				},
				error: () => {
					this.loading = false;
				}

			})
	}

	private getTopDeals(): void {
		this.loading = true;
		this.query.type = 'top-deals';
		this.globalService.getHomePageAllProducts(this.query)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (res: ApiResultModel<ProductsDataModel>) => {
					this.saleProducts = res.payload.content;
					this.loading = false;
				},
				error: () => {
					this.loading = false;
				}
			})
	}

	private getNewArrivals(): void {
		this.loading = true;
		this.query.type = 'new-arrivals';
		this.globalService.getHomePageAllProducts(this.query)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (res: ApiResultModel<ProductsDataModel>) => {
					this.newArrivalsProducts = res.payload.content;
					this.loading = false;
				},
				error: () => {
					this.loading = false;
				}
			})
	}

	private getTopRatedProducts(): void {
		this.query.type = 'top-rated';
		this.globalService.getHomePageAllProducts(this.query)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (res: ApiResultModel<ProductsDataModel>) => {
					this.topRatedProducts = res.payload.content;
				},
				error: () => {
					this.loading = false;
				}
			})
	}

	private getBestSellersProducts(): void {
		this.query.type = 'best-seller';
		this.globalService.getHomePageAllProducts(this.query)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (res: ApiResultModel<ProductsDataModel>) => {
					this.bestsellersProducts = res.payload.content;
				},
				error: () => {
					this.loading = false;
				}
			})
	}

	private getSpecialOffersProducts(): void {
		this.globalService.getHomePageAllProducts({
			period: 30,
			category: '',
			type: 'top-rewards',
			shopId: this.shopId ? this.shopId : ''
		})
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (res: ApiResultModel<ProductsDataModel>) => {
					this.specialOffersProducts = res.payload.content;
				},
				error: () => {
					this.loading = false;
				}
			})
	}

	private getTopDiscounts(): void {
		this.query.type = 'top-discounts';
		this.globalService.getHomePageAllProducts(this.query)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
					next: (res: ApiResultModel<ProductsDataModel>) => {
						this.topDiscountsProducts = res.payload.content;
					},
					error: () => {
						this.loading = false;
					}
				}
			)
	}

	private getSuppliers(): void {
		this.globalService.getSuppliers(null)
			.pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.suppliers = res.payload.content;
		})
	}

	private getCategories(): void {
		this.homeService.getCategories()
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.blockZones = res.payload.content.filter(item => item.isActive != false);
			})
	}
}
