import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding, Inject,
	Input,
	OnInit,
	PLATFORM_ID,
	QueryList,
	ViewChild,
	ViewChildren
} from '@angular/core';
import {Subject} from "rxjs";
import {OwlCarouselOConfig} from "ngx-owl-carousel-o/lib/carousel/owl-carousel-o-config";
import {CarouselComponent, SlidesOutputData} from "ngx-owl-carousel-o";
import {environment} from "../../../../environments/environment";

export type ProductGalleryLayout = 'product-sidebar' | 'product-full' | 'quickview';

export interface ProductGalleryItem {
	id: string;
	image: string;
}

@Component({
	selector: 'app-product-gallery',
	styleUrls: ['./product-gallery.component.scss'],
	templateUrl: './product-gallery.component.html'
})
export class ProductGalleryComponent implements OnInit {
	@Input() images: any;
	@Input() image: string;
	@Input() @HostBinding('attr.data-layout') layout!: ProductGalleryLayout;
	@ViewChild('featuredCarousel', { read: CarouselComponent }) featuredCarousel!: CarouselComponent;
	@ViewChild('thumbnailsCarousel', { read: CarouselComponent }) thumbnailsCarousel!: CarouselComponent;
	@ViewChildren('imageElement', { read: ElementRef }) imageElements!: QueryList<ElementRef>;
	@HostBinding('class.product-gallery') classProductGallery = true;
	@HostBinding('class.product-gallery--layout--product-full') get classProductGalleryLayoutProductFull(): boolean {
		return this.layout === 'product-full';
	}
	@HostBinding('class.product-gallery--layout--product-sidebar') get classProductGalleryLayoutProductSidebar(): boolean {
		return this.layout === 'product-sidebar';
	}
	@HostBinding('class.product-gallery--layout--quickview') get classProductGalleryLayoutQuickview(): boolean {
		return this.layout === 'quickview';
	}
	private destroy$: Subject<void> = new Subject<void>();
	currentItem: ProductGalleryItem|null = null;
	imagePath = environment.imagePath;
	showGallery = true;
	carouselOptions!: Partial<OwlCarouselOConfig>;
	thumbnailsCarouselOptions!: Partial<OwlCarouselOConfig>;

	constructor(
		@Inject(PLATFORM_ID) private platformId: any,
		private cd: ChangeDetectorRef,
	) { }

	ngOnInit(): void {
			this.initOptions();
			this.cd.detectChanges();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	featuredCarouselTranslated(event: SlidesOutputData): void {
		if (event.slides?.length) {
			const activeImageId = event.slides[0].id;

			if (!this.thumbnailsCarousel.slidesData.find(slide => slide.id === activeImageId && slide.isActive)) {
				this.thumbnailsCarousel.to(activeImageId);
			}
		}
	}

	getDirDependentIndex(index: number) {
		return index;
	}

	onThumbnailImageClick(item): void {
		this.image = item;
	}

	getThumbBounds(index: number): any|null {
		const imageElements = this.imageElements.toArray();
		const dirDependentIndex = this.getDirDependentIndex(index);

		if (!imageElements[dirDependentIndex]) {
			return null;
		}

		const tag = imageElements[dirDependentIndex].nativeElement;
		const width = tag.naturalWidth;
		const height = tag.naturalHeight;
		const rect = tag.getBoundingClientRect();
		const ration = Math.min(rect.width / width, rect.height / height);
		const fitWidth = width * ration;
		const fitHeight = height * ration;

		return {
			x: rect.left + (rect.width - fitWidth) / 2 + window.pageXOffset,
			y: rect.top + (rect.height - fitHeight) / 2 + window.pageYOffset,
			w: fitWidth,
		};
	}

	initOptions(): void {
		this.carouselOptions = {
			dots: false,
			autoplay: false,
			responsive: {
				0: { items: 4 },
			},
		};
		this.thumbnailsCarouselOptions = {
			dots: false,
			autoplay: false,
			margin: 10,
			items: 5,
			responsive: {
				580: { items: 8, margin: 10 },
				520: { items: 7, margin: 10 },
				400: { items: 6, margin: 10 },
				320: { items: 5, margin: 8 },
				260: { items: 4, margin: 8 },
				0: { items: 3 },
			},
		};
	}
}
