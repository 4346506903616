import {Component, EventEmitter, HostBinding, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {MobileMenuService} from "../../../../core/services/mobile-menu.service";
import {Router} from "@angular/router";
import {ApiResultModel} from "../../../../core/interfaces/api-result.model";
import {GetShopResModel} from "../../../../core/interfaces/shop.model";
import {DashboardService} from "../../../dashboard/dashboard.service";
import {PageEvent} from "@angular/material/paginator";
import {ShopStateEnum} from "../../../../core/enums/shop.enum";

@Component({
	selector: 'app-mobile-menu-links',
	templateUrl: './mobile-menu-links.component.html',
})
export class MobileMenuLinksComponent implements OnInit {
	@Input() items: any[] = [];
	@Input() isShops: boolean;
	@Output() itemClick: EventEmitter<any> = new EventEmitter<any>();
	@HostBinding('class.mobile-menu__links') classMobileMenuLinks = true;
	@Output() paginate: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
	pageEvent: PageEvent;
	letter: string;
	pagination: any;
	shopQuery = {
		page: 1,
		size: 10,
		sort: 's.updatedAt',
		order: 'desc',
		search: '',
		state: ShopStateEnum.Active
	};

	constructor(private menu: MobileMenuService,
				private dashboardService: DashboardService,
				private router: Router) {
	}

	ngOnInit(): void {
	}

	onItemClick(event: MouseEvent, item: any, panel: TemplateRef<any>): void {
		this.router.navigateByUrl(item.url);
		this.itemClick.emit(item);
	}

	applyFilter(letter: string): void {
		this.shopQuery.search = letter;
		this.dashboardService.getShops(this.shopQuery).subscribe({
			next: (res: ApiResultModel<GetShopResModel>) => {
				this.pagination = res.payload.pagination;
				this.items = res.payload.content;
			},
			error: () => {
			}
		});
	}

	onPaginateChange(event: PageEvent): void {
		event.pageIndex = event.pageIndex + 1;
		this.paginate.emit(event);
		this.shopQuery.page = event.pageIndex;
		this.shopQuery.size = event.pageSize;
		this.dashboardService.getShops(this.shopQuery).subscribe((res: ApiResultModel<GetShopResModel>) => {
			this.pagination = res.payload.pagination
			this.items = res.payload.content;
		})
	}
}
