
<app-block-space layout="divider-nl"></app-block-space>

<app-block-products *ngIf="featuredProducts?.length"
					[loading]="loading"
					[blockTitle]="'HEADER_FEATURED_PRODUCTS'|translate"
					layout="grid-6"
					[rows]="1"
					[featuredProducts]="featuredProducts || []"
></app-block-products>
<app-block-space layout="divider-nl"></app-block-space>

<app-block-sale *ngIf="saleProducts?.length"
				[loading]="loading"
				[saleProducts]="saleProducts || []">
</app-block-sale>

<app-block-space layout="divider-nl"></app-block-space>
<app-block-products *ngIf="topRatedProducts?.length"
					[loading]="loading"
					[blockTitle]="'HEADER_TOP_RATED'|translate"
					layout="grid-6"
					[rows]="1"
					[featuredProducts]="topRatedProducts || []"
></app-block-products>

<app-block-space layout="divider-nl"></app-block-space>
<app-block-products *ngIf="topDiscountsProducts?.length"
					[loading]="loading"
					[blockTitle]="'HEADER_SPECIAL_OFFERS'|translate"
					layout="grid-6"
					[rows]="1"
					[featuredProducts]="topDiscountsProducts || []"
></app-block-products>
<app-block-space layout="divider-nl"></app-block-space>

<app-block-products *ngIf="bestsellersProducts?.length"
					[loading]="loading"
					[blockTitle]="'HEADER_BESTSELLERS'|translate"
					layout="grid-6"
					[rows]="1"
					[featuredProducts]="bestsellersProducts || []"
></app-block-products>

<app-block-space layout="divider-nl"></app-block-space>

<app-block-products *ngIf="newArrivalsProducts?.length"
					[blockTitle]="'HEADER_NEW_ARRIVALS'|translate"
					layout="horizontal"
					layout="grid-6"
					[rows]="1"
					[featuredProducts]="newArrivalsProducts || []"
></app-block-products>


<app-block-space layout="divider-nl"></app-block-space>

<!--<app-block-suppliers *ngIf="suppliers?.length"-->
<!--					 layout="columns-8-full"-->
<!--					 [suppliers]="suppliers || []">-->
<!--</app-block-suppliers>-->

<app-block-space class="d-xl-block d-none" layout="divider-nl"></app-block-space>

<app-block-space layout="divider-nl"></app-block-space>
