import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./modules/home/home.component";
import {AppConfig} from "./core/config/appConfig";
import {AuthGuard} from "./core/guards/auth.guard";
import {RedirectGuard} from "./core/guards/redirect.guard";

const routes: Routes = [
	{path: 'home', component: HomeComponent},
	{
		path: AppConfig.routes.authentication.path,
		loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
		canActivate: [RedirectGuard]

	},
	{
		path: AppConfig.routes.dashboard.path,
		loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
		canActivate: [AuthGuard]

	},
	{
		path: AppConfig.routes.order.path,
		loadChildren: () => import('./modules/order/order.module').then(m => m.OrderModule),
		canActivate: [AuthGuard]
	},
	{
		path: AppConfig.routes.product.path,
		loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
	},
	{
		path: AppConfig.routes.pages.path,
		loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule),
	},
	{path: '', redirectTo: 'home', pathMatch: 'full'},
	{path: '**', redirectTo: 'pages/not-found'}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
