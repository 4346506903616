<app-block-space layout="spaceship-ledge-height"></app-block-space>

<div class="block order-success">
	<div class="container">
		<div class="order-success__body mt-5">
			<div class="card">
				<div class="order-list">
					<table>
						<thead class="order-list__header">
						<tr>
							<th class="order-list__column-label" colspan="1"></th>
							<th class="order-list__column-label" colspan="2">{{ 'TABLE_PRODUCT'|translate }}</th>
							<th class="order-list__column-label">{{ 'TABLE_QUANTITY'|translate }}</th>
							<th class="order-list__column-label">{{ 'TABLE_PRICE'|translate }}</th>
							<th class="order-list__column-label">{{ 'TABLE_REWARD'|translate }}</th>
							<th class="order-list__column-label">{{ 'TABLE_DISCOUNT'|translate }}</th>
							<th class="order-list__column-total">{{ 'TABLE_TOTAL'|translate }}</th>
						</tr>
						</thead>
						<tbody class="order-list__products">
						<tr *ngFor="let item of cardItems">
							<td class="order-list__column-image">
								<div class="image image--type--product">
									<a class="image__body">
										<img class="image__tag"
											 [src]=" item?.product.gallery.length ? (imagePath + item.product.gallery[0]?.thumbnail?.url) : 'assets/images/categories/category-1.jpg'"
											 alt="">
									</a>
								</div>
							</td>
							<td class="order-list__column-product">
								<a>{{ item.product.productName }}</a>
								<ul class="cart-table__options">
									<li>{{ item?.properties?.unit?.name }} (x{{item?.properties?.unit?.contents}})</li>
								</ul>
							</td>
							<td class="order-list__column-quantity">
								{{ item.quantity }}
							</td>
							<td class="order-list__column-total">{{item.price}} ₽</td>
							<td class="order-list__column-total">{{(item.reward * item.quantity * item?.properties?.unit?.contents | number: '1.0-2')}}</td>
							<td class="order-list__column-total">{{(item.discount || 0) + ' %'}}</td>
							<td class="order-list__column-total">{{ (item?.price * ((100 - item?.discount) / 100)) * item?.quantity * (+item?.properties?.unit?.contents) | number: '1.0-2'}}
								₽
							</td>
						</tr>
						</tbody>
						<tbody class="order-list__subtotals">
						<tr>
							<th class="order-list__column-label" colspan="3">{{ 'TOTAL_REWARD'|translate }}</th>
							<td class="order-list__column-total">{{totalReward | number: '1.0-2'}}</td>
						</tr>
						<tr>
							<th class="order-list__column-label" colspan="3">{{ 'TOTAL_PRICE'|translate }}</th>
							<td class="order-list__column-total">{{totalAmount | number: '1.0-2'}} ₽</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="order-success__addresses">
				<div class="order-success__address card address-card">
					<div class="card__loader"></div>
					<div class="address-card__badge tag-badge tag-badge--theme">{{ 'SHOP_ADDRESS' | translate }}</div>
					<div class="address-card__body">
						<div class="address-card__name">{{ shop?.companyName }}</div>
						<div class="address-card__row">
							{{shop?.deliveryAddress.country?.countryName[translate.currentLang]}}<br>
							{{shop?.deliveryAddress.state?.regionsName[translate.currentLang]}}<br>
							{{shop?.deliveryAddress.city }}<br>
							{{shop?.deliveryAddress.address_1}}
						</div>
						<div class="address-card__row">
							<div
								class="address-card__row-title">{{ 'TEXT_PHONE_NUMBER'|translate }}</div>
							<div class="address-card__row-content">{{ shop?.deliveryAddress.phoneNumber1}}</div>
						</div>
					</div>
				</div>
				<div class="order-success__address card address-card">
					<div class="card__loader"></div>
					<div
						class="address-card__badge tag-badge tag-badge--theme">{{ 'SUPPLIER_ADDRESS' | translate }}</div>
					<div class="address-card__body">
						<div class="address-card__name">{{ supplier?.companyName }}</div>
						<div class="address-card__row">
							{{supplier?.address.country?.countryName[translate.currentLang]}}<br>
							{{supplier?.address.state?.regionsName[translate.currentLang]}}<br>
							{{supplier?.address.city}}<br>
							{{supplier?.address.address_1}}
						</div>
						<div class="address-card__row">
							<div
								class="address-card__row-title">{{ 'TEXT_PHONE_NUMBER'|translate }}</div>
							<div class="address-card__row-content">{{ supplier?.address.phoneNumber1}}</div>
						</div>
					</div>
				</div>
			</div>
			<form [formGroup]="orderForm">
				<div class="card mt-5">
					<div class="container">
						<div class="d-flex flex-row form-group">
							<div>
								<mat-form-field appearance="outline" class="mat-form-field">
									<mat-label>{{ 'ORDER_DELIVERY_DATE'|translate }}</mat-label>
									<input matInput [matDatepicker]="picker" formControlName="shippingDate" (click)="picker.open()" [min]="minDate" readonly>
									<mat-error>{{'ERROR_FORM_REQUIRED' | translate}}</mat-error>
									<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
									<mat-datepicker #picker></mat-datepicker>
								</mat-form-field>
							</div>
						</div>
						<mat-form-field class="w-100 mt-2" appearance="outline">
							<mat-label>{{'COMMENT' | translate}}</mat-label>
							<textarea #message formControlName="comment" maxlength="256" matInput></textarea>
							<mat-hint align="end">{{message.value.length}} / 256</mat-hint>
						</mat-form-field>
					</div>
				</div>
			</form>

			<div class="d-flex flex-row justify-content-sm-between mt-3">
				<button class="btn btn-primary" type="submit" (click)="goBack()">
					{{'GO_BACK' | translate}}
				</button>
				<button *ngIf="!showSuccessCreated" class="btn btn-primary" type="submit" (click)="createOrder()">
					{{'CREATE_ORDER' | translate}}
				</button>
			</div>
		</div>

	</div>
</div>

<app-block-space layout="before-footer"></app-block-space>
