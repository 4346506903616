import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DashboardService} from "../../../dashboard/dashboard.service";
import {AppConfig} from "../../../../core/config/appConfig";
import {ApiResultModel} from "../../../../core/interfaces/api-result.model";
import {GetOrderByIdResModel} from "../../../../core/interfaces/order/order.model";
import {Base} from "../../../../core/base";
import {takeUntil} from "rxjs/operators";

@Component({
	selector: 'app-create-order',
	templateUrl: './create-order.component.html'
})
export class CreateOrderComponent extends Base implements OnInit {
	orderId: string;
	order: any;

	constructor(private activatedRoute: ActivatedRoute,
				private dashboardService: DashboardService) {
		super();
	}

	ngOnInit(): void {
		this.orderId = this.activatedRoute.snapshot.queryParams.id;

		this.getOrderById();
	}

	private getOrderById() {
		this.dashboardService.getOrdersById(this.orderId).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<GetOrderByIdResModel>) => {
				this.order = res.payload;
			},
			error: (err: any) => {
				console.log(err);
			}
		})
	}
}
