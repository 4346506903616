<div class="container">
	<div *ngIf="suppliers?.length < 1" class="products-view__empty">
		<div class="products-view__empty-title">
			{{ 'TEXT_NO_MATCHING_ITEMS_TITLE'|translate }}
		</div>
	</div>
	<ul class="block-brands__list">
		<ng-container *ngFor="let supplier of suppliers">
			<li class="block-brands__item">
				<a (click)="goToSupplierBusinessPage(supplier)" class="block-brands__item-link">
					<img [src]="supplier?.logoType ? (imagePath + supplier?.logoType?.filename) : 'assets/images/defaultSupplierOrCustomer.jpg'" alt="">

					<span class="block-brands__item-name">{{ supplier.companyName }}</span>
				</a>
			</li>
			<li class="block-brands__divider" role="presentation"></li>
		</ng-container>
	</ul>
</div>

