import {
	AfterViewInit,
	Component,
	ElementRef,
	HostBinding,
	Inject,
	Input,
	NgZone,
	OnInit,
	PLATFORM_ID,
	SimpleChanges, ViewChild
} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {Observable} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";
import {Base} from "../../../core/base";

export function fromMatchMedia(query: string, skipFirst = true): Observable<MediaQueryList> {
	return new Observable(observer => {
		const mediaQueryList = matchMedia(query);

		const onChange = () => observer.next(mediaQueryList);

		if (!skipFirst) {
			onChange();
		}

		if (mediaQueryList.addEventListener) {
			mediaQueryList.addEventListener('change', onChange);

			return () => mediaQueryList.removeEventListener('change', onChange);
		} else {
			// noinspection JSDeprecatedSymbols
			mediaQueryList.addListener(onChange);

			// noinspection JSDeprecatedSymbols
			return () => mediaQueryList.removeListener(onChange);
		}
	});
}

@Component({
	selector: 'app-block-header',
	templateUrl: './block-header.component.html',
})
export class BlockHeaderComponent extends Base implements OnInit, AfterViewInit {

	@Input() pageTitle: string = '';

	@Input() afterHeader = true;

	@HostBinding('class.block-header') classBlockHeader = true;

	@HostBinding('class.block-header--has-title') get classBlockHeaderHasTitle(): boolean {
		return !!this.pageTitle;
	}

	@ViewChild('titleElement') titleElementRef!: ElementRef;

	get titleElement(): HTMLElement {
		return this.titleElementRef?.nativeElement;
	}

	get element(): HTMLElement {
		return this.elementRef.nativeElement;
	}

	reCalcTitleWidth = false;

	constructor(
		@Inject(PLATFORM_ID) private platformId: any,
		private zone: NgZone,
		private elementRef: ElementRef,
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['pageTitle'] && !changes['pageTitle'].isFirstChange()) {
			this.reCalcTitleWidth = true;
		}
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		if (!isPlatformBrowser(this.platformId)) {
			return;
		}

		this.zone.runOutsideAngular(() => {
			fromMatchMedia('(min-width: 1200px)', false).pipe(
				filter(x => x.matches),
				takeUntil(this.destroy$)
			).subscribe(() => this.calcTitleWidth());
		});
	}

	ngAfterViewChecked(): void {
		if (this.reCalcTitleWidth) {
			this.reCalcTitleWidth = false;
			this.calcTitleWidth();
		}
	}

	calcTitleWidth(): void {
		// So that breadcrumbs correctly flow around the page title, we need to know its width.
		// This code simply conveys the width of the page title in CSS.

		if (!this.element || !this.titleElement) {
			return;
		}

		const width = this.titleElement.getBoundingClientRect().width;

		this.element.style.setProperty('--block-header-title-width', `${width}px`);
	}

}
