import {NgModule} from "@angular/core";
import {HomeComponent} from "./home.component";
import {BlockPostsComponent} from './block-posts/block-posts.component';
import {SharedModule} from "../../shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {CarouselModule} from "ngx-owl-carousel-o";
import {HomeService} from "./home.service";
import {BlockPostPageComponent} from '../pages/block-post-page/block-post-page.component';
import {BlockFeaturesComponent} from './block-features/block-features.component';
import {BlockProductsComponent} from './block-products/block-products.component';
import {BlockSaleComponent} from './block-sale/block-sale.component';
import {BlockZoneComponent} from './block-zone/block-zone.component';
import {ProductModule} from "../product/product.module";
import {NgMaterialModule} from "../../ng-material.module";
import {BlockProductsColumnComponent} from './block-products-column/block-products-column.component';
import {BlockSuppliersComponent} from './block-suppliers/block-suppliers.component';

@NgModule({
	declarations: [
		HomeComponent,
		BlockPostsComponent,
		BlockPostPageComponent,
		BlockFeaturesComponent,
		BlockProductsComponent,
		BlockSaleComponent,
		BlockZoneComponent,
		BlockProductsColumnComponent,
		BlockSuppliersComponent],
	imports: [
		CommonModule,
		TranslateModule,
		SharedModule,
		CarouselModule,
		ProductModule,
		NgMaterialModule
	],
	providers: [
		HomeService
	]
})

export class HomeModule {
}
