<div class="card ng-star-inserted">
	<div class="order-header">
		<div class="order-header__actions">
			<a class="btn btn-primary btn-sm m-1" (click)="goToList()">{{'BACK_TO_LIST' | translate}}</a>
		</div>
		<h2 class="order-header__title">{{'TEXT_ORDER_WITH_NUMBER' | translate}}{{' #' + order?.orderNumber}}</h2>
		<div class="order-header__subtitle">
			<ul style="list-style: none; padding: 0">
				<li>{{'CREATED_DATE_TITLE' | translate}}<b>{{order?.createdAt | localizedDate: dateFormat}}</b></li>
				<li>{{'UPDATED_DATE_TITLE' | translate}}<b>{{order?.updatedAt | localizedDate: dateFormat}}</b></li>
				<li>{{'STATUS' | translate}}<b>{{': ' + (order?.status | translate)}}</b></li>
			</ul>
			<button [hidden]="
				order?.status === orderStatuses.Canceled
				 ||order?.status === orderStatuses.Arrived
				  ||order?.status === orderStatuses.Declined
				   ||order?.status === orderStatuses.Returned" class="btn btn-primary btn-sm"
					(click)="changeOrder(order?.id)">{{'CHANGE_ORDER' | translate}}</button>
			<button class="btn btn-primary btn-sm m-1" (click)="repeatOrder()">{{'REPEAT_ORDER' | translate}}</button>
		</div>
	</div>
	<div class="order-divider"></div>
	<div class="card-table">
		<div class="table-responsive-sm">
			<table mat-table [dataSource]="dataSource">

				<ng-container matColumnDef="product">
					<th mat-header-cell *matHeaderCellDef
						style="color: black; font-weight: bold"> {{'TABLE_PRODUCT' | translate}} </th>
					<td style="cursor: pointer" (click)="goToProduct(row.productId.id)" mat-cell
						*matCellDef="let row"> {{row.productName}}
						<ul class="cart-table__options">
							<li>{{ row?.properties?.unit?.name }} (x{{row?.properties?.unit?.contents}})</li>
						</ul>
					</td>

					<td mat-footer-cell *matFooterCellDef><b>{{ 'TABLE_TOTAL' | translate }}</b></td>
				</ng-container>

				<ng-container matColumnDef="quantity">
					<th mat-header-cell *matHeaderCellDef
						style="color: black; font-weight: bold; white-space: nowrap;"> {{'TABLE_QUANTITY' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.quantity}} </td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<ng-container matColumnDef="price">
					<th mat-header-cell *matHeaderCellDef
						style="color: black; font-weight: bold"> {{'TABLE_PRICE' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.price}} ₽</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<ng-container matColumnDef="total">
					<th mat-header-cell *matHeaderCellDef
						style="color: black; font-weight: bold"> {{'SUM' | translate}} </th>
					<td mat-cell
						*matCellDef="let row"> {{(row?.price * ((100 - row?.discount) / 100)) * row?.quantity * (+row?.properties?.unit?.contents) | number: '1.0-2'}}
						₽
					</td>
					<td mat-footer-cell *matFooterCellDef><b>{{order?.orderTotal | number: '1.0-2'}} ₽</b></td>
				</ng-container>

				<ng-container matColumnDef="reward">
					<th mat-header-cell *matHeaderCellDef
						style="color: black; font-weight: bold"> {{'TABLE_REWARD' | translate}} </th>
					<td mat-cell
						*matCellDef="let row"> {{row.reward * row.quantity * (+row?.properties?.unit?.contents) | number: '1.0-2'}}
						<span style="color: red"> B</span></td>
					<td mat-footer-cell *matFooterCellDef><b>{{order?.rewardTotal}}<span
						style="color: red"> B</span></b></td>
				</ng-container>

				<ng-container matColumnDef="discount">
					<th mat-header-cell *matHeaderCellDef
						style="color: black; font-weight: bold"> {{'TABLE_DISCOUNT' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.discount}} </td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
			</table>
			<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

		</div>
	</div>
</div>
<div class="card mt-3">
	<div class="col-sm-12 col-12 px-2">
		<div class="card address-card address-card--featured">
			<div class="address-card__body">
				<div class="address-card__name">{{'COMMENT' | translate}}</div>
				{{order?.comment}}
			</div>
		</div>
	</div>
	<div class="col-sm-12 col-12 px-2">
		<div class="card address-card address-card--featured">
			<div class="address-card__body">
				<div class="address-card__name">{{'ORDER_DELIVERY_DATE' | translate}}</div>
				{{order?.shippingDate | localizedDate: dateFormat}}
			</div>
		</div>
	</div>
</div>

<div class="mt-3 mx-n2 d-flex justify-content-between">
	<div class="col-sm-6 col-12 address-card-width">
		<div class="card address-card address-card--featured">
			<div class="card__loader"></div>
			<div
				class="address-card__badge tag-badge tag-badge--theme ng-star-inserted">{{'SUPPLIER' | translate}}</div>
			<div class="address-card__body">
				<div class="address-card__name">{{order?.supplier.companyName}}</div>
				<div
					class="address-card__row">{{order?.supplier.address.country?.countryName[translate.currentLang]}}</div>
				<div
					class="address-card__row">{{order?.supplier.address.state?.regionsName[translate.currentLang]}}</div>
				<div class="address-card__row">{{order?.supplier.address.city}}</div>
				<div class="address-card__row">{{order?.supplier.address.address_1}}</div>
				<div class="address-card__row d-flex">
					<div class="address-card__row-title">{{'TEXT_PHONE_NUMBER' | translate}}:</div>
					<div class="address-card__row-content"><span
						class="m-lg-2">{{order?.supplier.address.phoneNumber1}}</span></div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-sm-6 col-12 mt-sm-0 mt-3 address-card-width">
		<div class="card address-card address-card--featured">
			<div class="card__loader"></div>
			<div
				class="address-card__badge tag-badge tag-badge--theme ng-star-inserted">{{'SHOP' | translate}}</div>
			<div class="address-card__body">
				<div class="address-card__name">{{order?.shop.shopName}}</div>
				<div
					class="address-card__row">{{order?.shop.deliveryAddress.country?.countryName[translate.currentLang]}}</div>
				<div
					class="address-card__row">{{order?.shop.deliveryAddress.state?.regionsName[translate.currentLang]}}</div>
				<div class="address-card__row">{{order?.shop.deliveryAddress.city}}</div>
				<div class="address-card__row">{{order?.shop.deliveryAddress.address_1}}</div>
				<div class="address-card__row d-flex">
					<div class="address-card__row-title">{{'TEXT_PHONE_NUMBER' | translate}}:</div>
					<div class="address-card__row-content"><span
						class="m-lg-2">{{order?.shop.deliveryAddress.phoneNumber1}}</span></div>
				</div>
			</div>
		</div>
	</div>
</div>
