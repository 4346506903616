import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../../../auth/auth.service";
import {ReplaySubject} from "rxjs";
import {CardService} from "../../../../order/shopping-cart/card.service";
import {WishListService} from "../../../../order/wish-list/wish-list.service";
import {ShoppingCartResModel} from "../../../../../core/interfaces/shopping-cart/shopping-cart.model";
import {ShopModel} from "../../../../../core/interfaces/shop.model";
import {AppConfig} from "../../../../../core/config/appConfig";
import {Base} from "../../../../../core/base";
import {takeUntil} from "rxjs/operators";

@Component({
	selector: 'app-header-indicators',
	templateUrl: './header-indicators.component.html'
})
export class
HeaderIndicatorsComponent extends Base implements OnInit {
	showAccountMenu: boolean;
	showCard: boolean;
	shoppingCards: ShoppingCartResModel[];
	wishListCount: ReplaySubject<number> = this.wishListService.wishListChange;
	cardListCount: ReplaySubject<number> = this.cardService.cardListChange;
	shop: ShopModel;
	shopId: string;

	constructor(private router: Router,
				public authService: AuthService,
				private cardService: CardService,
				private wishListService: WishListService) {
		super();
	}

	get isAuthenticated() {
		return this.authService.isAuthenticated();
	}

	ngOnInit(): void {
		this.shop = localStorage.getItem('shop') !== 'undefined' ? JSON.parse(localStorage.getItem('shop')) : {};
		this.shopId = this.shop ? this.shop.id : null;

		if (this.isAuthenticated) {
			this.authService.getAuth().pipe(takeUntil(this.destroy$)).subscribe({
				next: (res) => {
					if (res.payload?.customer?.id) {
						this.wishListService.getWishList().pipe(takeUntil(this.destroy$)).subscribe();
					}
				}
			})
		}

		this.authService.changeShopAddress.pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.shopId = res?.id;
			this.getCards();
		})

		this.isLoggedOut();
		this.showAccountMenu = false;
		this.getCards();
	}

	goToAuth(): void {
		this.router.navigateByUrl(`authentication/customer/${AppConfig.routes.authentication.childRouts.customerAuth.login}`);
	}

	private isLoggedOut(): void {
		this.authService.isLogOut.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.showAccountMenu = false;
		})
	}

	accountMenu(): void {
		this.showAccountMenu = !this.showAccountMenu;
	}

	hideShowShopCard(): void {
		this.getCards();
		this.showCard = !this.showCard;
	}

	goToWishListPage(): void {
		this.router.navigateByUrl('order/wish-list');
	}

	closeDropDown(): void {
		this.showCard = false;
	}

	closeMenu(): void {
		this.showAccountMenu = false;
	}

	getCards(): void {
		if (this.shopId && this.authService.isAuthenticated()) {
			this.cardService.getCards({shopId: this.shopId}).pipe(takeUntil(this.destroy$)).subscribe((res) => {
				this.shoppingCards = res.payload.content;
			})
		}
	}

	goToShoppingCart(): void {
		this.router.navigateByUrl('order/shopping-cart');
	}
}
