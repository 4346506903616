<ng-template #parent let-category="item">
	<ng-container *ngIf="category?.parent">
		<ng-container *ngTemplateOutlet="parent; context: {item: category?.parent}"></ng-container>
	</ng-container>
	<li class="filter-category__item filter-category__item--parent">
        <span class="filter-category__arrow">
            <app-icons icon="arrow-rounded-left-6x9"></app-icons>
        </span>
		<a (click)="goToCategory(category.id)">{{ category?.name[translate.currentLang] }}</a>
	</li>
</ng-template>
<ul class="filter-category__list">
	<li
		*ngIf="!showAllCategories"
		class="filter-category__item filter-category__item--parent"
	>
        <span class="filter-category__arrow">
            <app-icons icon="arrow-rounded-left-6x9"></app-icons>
        </span>
		<a (click)="getAllCategories()">{{ 'LINK_ALL_PRODUCTS'|translate }}</a>
	</li>
	<ng-container *ngIf="!showAllCategories">
		<ng-container *ngIf="category?.parent">
			<ng-container *ngTemplateOutlet="parent; context: {item: category?.parent}"></ng-container>
		</ng-container>
		<li
			class="filter-category__item"
			[class.filter-category__item--current]="category?.id"
		>
			<a>{{ category?.name[translate.currentLang] }}</a>
		</li>
		<ng-container *ngFor="let child of category?.children | categoryActive">
			<li class="filter-category__item filter-category__item--child">
				<a (click)="goToCategory(child.id)">{{ child.name[translate.currentLang] }}</a>
			</li>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="showAllCategories">
		<ng-container *ngFor="let category of categories | categoryActive">
			<li class="filter-category__item filter-category__item--child">
				<a (click)="goToCategory(category.id)">{{ category?.name[translate.currentLang] }}</a>
			</li>
		</ng-container>
	</ng-container>
</ul>

