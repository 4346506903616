import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {AppConfig} from "../../core/config/appConfig";

@Injectable()
export class HomeService {

	constructor(private http: HttpClient) {
	}

	getPosts(query) {
		return this.http.get<any>(`${AppConfig.baseUrl}${AppConfig.endpoints.posts.getPosts}`, { params: query })
	}

	getPostsById(id) {
		return this.http.get<any>(`${AppConfig.baseUrl}${AppConfig.endpoints.posts.getPostsById}${id}`)
	}

	getCategories() {
		return this.http.get<any>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.getCategories}`)
	}

}
