<div class="card flex-grow-1 mb-md-0 mr-0 mr-lg-3 ml-0 ml-lg-4">
	<form [formGroup]="verificationForm">
		<div [hidden]="currentStep !== 1" class="card-body card-body--padding--2">
			<h3>{{'FORGOT_PASSWORD_MESSAGE'|translate }}</h3>
			<div class="d-flex flex-column form-group">
				<mat-form-field appearance="outline">
					<mat-label>{{'INPUT_PHONE_NUMBER_LABEL'|translate }}</mat-label>
					<ngx-mat-intl-tel-input
						formControlName="mobile"
						[onlyCountries]="['ru']"
						name="mobile"
						#phone>
					</ngx-mat-intl-tel-input>
					<mat-hint>{{ 'EG'|translate }} : {{phone?.selectedCountry.placeHolder}}</mat-hint>
				</mat-form-field>
			</div>
			<mat-error *ngIf="showErrorMessage">{{errorMessage | translate}}</mat-error>
			<div class="d-flex flex-row justify-content-end mt-3">
				<button class="btn btn-outline-secondary" type="button" (click)="goToLogin()">
					{{'GO_BACK' | translate}}
				</button>
				<button [disabled]="verificationForm?.controls['mobile'].invalid" class="btn btn-primary"
						(click)="sendMobile()">
					{{'BUTTON_SEND' | translate}}
				</button>
			</div>
		</div>
		<div [hidden]="currentStep !== 2" class="card-body card-body--padding--2">
			<h3>{{'VERIFY_MESSAGE' | translate}}
				<span>{{verificationForm.controls['mobile'].value}}</span>
			</h3>
			<div class="d-flex flex-column form-group">
				<mat-form-field appearance="outline" class="mat-form-field">
					<mat-label>{{ 'INPUT_VERIFICATION_CODE'|translate }}</mat-label>
					<input matInput type="text" min="0" size="4" maxLength="4" formControlName="token"
						   autocomplete="off" #nameInput onlyNumber>
				</mat-form-field>
				<mat-error *ngIf="wrongToken">{{'VERIFICATION_TOKEN_NOT_FOUND_OR_EXPIRED' | translate}}</mat-error>
			</div>
			<div class="d-flex flex-row justify-content-end align-items-baseline">
				<div class="d-flex">
			<span *ngIf="!resend" class="token-countdown">
				<countdown *ngIf="verificationData?.duration"
						   [config]="{ leftTime: verificationData?.duration, format: 'mm:ss' }"
						   (event)="handleEvent($event)">
				</countdown>
			</span>
				</div>
				<div class="d-flex align-content-center" *ngIf="resend">
					<button class="btn btn-primary mt-3" type="button"
							(click)="resendVerify()">{{"RESEND_CODE" | translate}}
					</button>
				</div>
				<div class="d-flex"></div>
				<div class="d-flex flex-row justify-content-end">
					<button class="btn btn-outline-secondary" (click)="goToLogin()">
						{{'GO_BACK' | translate}}
					</button>
				</div>
				<div *ngIf="showSendButton" class="d-flex flex-row justify-content-end">
					<button [disabled]="verificationForm?.controls['token'].invalid" class="btn btn-primary mt-3"
							(click)="sendVerifyCode()">
						{{'BUTTON_VERIFY' | translate}}
					</button>
				</div>
			</div>
		</div>
	</form>
	<form [formGroup]="passwordForm">
		<div [hidden]="currentStep !== 3" class="card-body card-body--padding--2">
			<div class="d-flex flex-column form-group">
				<mat-form-field appearance="outline" class="mat-form-field">
					<mat-label>{{ 'INPUT_PASSWORD_LABEL'|translate }}</mat-label>
					<input matInput [type]="hide? 'text': 'password'"
						   formControlName="password" #nameInput>
					<mat-icon class="material-icons" matSuffix (click)="hide = !hide" style="cursor: pointer">
						{{hide ? 'visibility' : 'visibility_off'}}
					</mat-icon>
					<mat-error>{{'VALIDATION_ERROR_PASSWORD' | translate}}</mat-error>
				</mat-form-field>
			</div>
			<div class="d-flex flex-column form-group" style="margin-top: 30px">
				<mat-form-field appearance="outline" class="mat-form-field">
					<mat-label>{{ 'INPUT_PASSWORD_REPEAT_LABEL'|translate }}</mat-label>
					<input matInput [type]="hide? 'text': 'password'"
						   formControlName="confirmPassword">
				</mat-form-field>
				<mat-error
					*ngIf="matchPassword">
					{{'VALIDATION_ERROR_CONFIRM' | translate}}</mat-error>
			</div>
			<div class="d-flex flex-row justify-content-end">
				<button
					[disabled]="passwordForm?.controls['password'].invalid || passwordForm?.controls['confirmPassword'].invalid || matchPassword"
					class="btn btn-primary" (click)="createPassword()">
					{{'BUTTON_SAVE' | translate}}
				</button>
			</div>
			<div class="d-flex flex-row justify-content-end">
				<button class="btn btn-secondary mt-3" type="button" (click)="goToLogin()">
					{{'CUSTOMER_LOGIN' | translate}}
				</button>
			</div>
		</div>

	</form>
</div>
