import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable(
	{
		providedIn: 'root'
	}
)
export class JwtInterceptor implements HttpInterceptor {

	constructor() {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		if (request.headers.keys().length > 1) {
			return next.handle(request)
		}

		const token = localStorage.getItem('access_token');

		if(token) {
			const clonedReq = request.clone({
				setHeaders: {
					'Authorization':`Bearer ${token}`
			}});
			return next.handle(clonedReq);
		} else {
			return next.handle(request);
		}
	}
}
