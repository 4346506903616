import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {WishListDataModel} from "../../../../core/interfaces/wish-list/wish-list-data.model";
import {WishListService} from "../wish-list.service";
import {Base} from "../../../../core/base";
import {switchMap, takeUntil} from "rxjs/operators";
import {ModalDirective} from "ngx-bootstrap/modal";
import {timer} from "rxjs";

@Component({
	selector: 'app-wish-list-pop-up',
	templateUrl: './wish-list-pop-up.component.html'
})
export class WishListPopUpComponent extends Base implements OnInit, AfterViewInit {
	@ViewChild('modal') modal!: ModalDirective;

	wishList: WishListDataModel[] = [];
	updatedList: WishListDataModel[] = [];
	wishListForm: FormGroup = new FormGroup({
		wishListName: new FormControl('', Validators.required)
	})

	showCreateList: boolean;
	productId: string;
	constructor(private wishListService: WishListService,
	) {
		super();
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		this.wishListService.showWishListPopup$.pipe(
			switchMap(product => {
				this.modal.show();
				this.productId = product.id;
				return timer(150);
			}),
			takeUntil(this.destroy$),
		).subscribe(() => {
			this.getWishList();
		});

		this.modal.onHidden.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.wishList = null;
		});
	}

	cancel(): void {
		// this.dialogRef.close();
	}

	addList(): void {
		const body = {
			name: this.wishListForm.controls['wishListName'].value
		}
		this.wishListService.createWishList(body).pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				this.showCreateList = false;
				this.getWishList();
				this.wishListForm.reset();
			},
			error: (err) => {
			}
		})
	}

	addRemoveToList(id: string, list: any): void {
		if (list.isContain) {
			this.wishListService.removeFromFavorite(id, this.productId).pipe(takeUntil(this.destroy$)).subscribe({
				next: (res) => {
					this.getWishList();
				},
				error: (err) => {
				}
			})
		} else {
			this.wishListService.addToWishList(id, this.productId).pipe(takeUntil(this.destroy$)).subscribe({
				next: (res) => {
					this.getWishList();
				},
				error: (err) => {
				}
			});
		}

	}

	private getWishList(): void {
		this.wishListService.getWishList().pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.wishList = res.payload.content;
			this.updatedList = [];

			this.wishList.forEach(list => {
				let isContain = false;
				list.products?.forEach(product => {
					if (product.id === this.productId) {
						isContain = true;
					}
				})

				list = {
					...list,
					isContain: isContain
				}

				this.updatedList.push(list);

			})
		})
	}
}
