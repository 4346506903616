<div style="overflow: hidden; padding: 10px">
	<button class="quickview__close" (click)="close()">
		<app-icons icon="cross-12"></app-icons>
	</button>
<div style="padding: 5px;">
	<h1 style="text-align: center">{{'Are you agree........ ?' | translate}}</h1>
	<div class="d-flex flex-row justify-content-sm-between mt-3">
		<button mat-stroked-button color="primary" (click)="accept()">
			{{'BUTTON_ACCEPT' | translate}}
		</button>
		<button mat-raised-button color="primary" (click)="decline()">
			{{'BUTTON_DECLINE' | translate}}
		</button>
	</div>
</div>
</div>
