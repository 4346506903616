import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialogRef} from "@angular/material/dialog";
import {PartnershipDialogComponent} from "../partnership-dialog/partnership-dialog.component";
import {TranslationKeys} from "../../../core/data/translation-keys";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";

@Component({
	selector: 'app-upload-avatar',
	templateUrl: './upload-avatar.component.html'
})
export class UploadAvatarComponent implements OnInit {
	file: File = null;
	uploadedImage: FormData;

	constructor(private translate: TranslateService,
				private dialogRef: MatDialogRef<PartnershipDialogComponent>,
				private toastr: ToastrService
	) {
	}

	ngOnInit(): void {
	}

	onChange(event): void {
		this.file = event.target.files[0];

		if (this.file) {
			const imageFormData = new FormData();
			imageFormData.append('file', this.file, this.file.name);
			this.uploadedImage = imageFormData;
		}
	}

	close(): void {
		this.dialogRef.close();
	}

	uploadAvatar(): void {
		this.dialogRef.close(this.uploadedImage);
	}
}
