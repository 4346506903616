<div class="container">
	<app-section-header
		[sectionTitle]="blockTitle"
		[arrows]="true"
		[groups]="groups"
		[currentGroup]="currentGroup"
		[links]="links"
		(next)="carousel.next()"
		(prev)="carousel.prev()"
		(changeGroup)="changeGroup.emit($any($event))"
	></app-section-header>

	<div *ngIf="featuredProducts?.length < 1" class="products-view__empty">
		<div class="products-view__empty-title">
			{{ 'TEXT_NO_MATCHING_ITEMS_TITLE'|translate }}
		</div>
	</div>

	<div
		*ngIf="showCarousel"
		class="block-products-carousel__carousel"
		[class.block-products-carousel__carousel--loading]="loading"
		[class.block-products-carousel__carousel--has-items]="featuredProducts.length > 0"
	>
		<div class="block-products-carousel__carousel-loader"></div>

		<owl-carousel-o [options]="carouselOptions" appOwlPreventClick>
			<ng-template *ngFor="let product of featuredProducts" carouselSlide>
				<div class="block-products-carousel__column">
				<app-product-card
					class="block-products-carousel__cell"
					[layout]="productCardLayout"
					[exclude]="productCardExclude"
					[image]="product?.gallery[0]?.filename"
					[product]="product"></app-product-card>
				</div>
			</ng-template>
		</owl-carousel-o>
	</div>
</div>

