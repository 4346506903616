import {Component, HostBinding, OnInit} from '@angular/core';
import {ProductService} from "../../../../product/services/product.service";
import {AppConfig} from "../../../../../core/config/appConfig";
import {NavigationService} from "../../../../../core/services/navigation-service";
import {Base} from "../../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
	selector: 'app-header-search',
	templateUrl: './header-search.component.html'
})
export class HeaderSearchComponent extends Base implements OnInit {
	// @HostBinding('class.search') classSearch = true;
	suggestionsIsOpen: boolean;
	vehiclePickerIsOpen: boolean;
	products = [];
	imagePath = AppConfig.galleryPath;
	searchForm: FormGroup = new FormGroup({
		text: new FormControl(),
	});

	constructor(private productService: ProductService,
				private navigationService: NavigationService) {
		super();
	}

	ngOnInit(): void {
	}

	onInput(event): void {
		// this.toggleSuggestions(true);
		console.log('aaa')
		// this.navigationService.goToSearch(event.target.value);
		// this.getProducts({text:  event.target.value});
	}

	// toggleSuggestions(force?: boolean): void {
	// 	this.suggestionsIsOpen = force !== undefined ? force : !this.suggestionsIsOpen;
	//
	// 	if (this.suggestionsIsOpen) {
	// 		this.toggleVehiclePicker(false);
	// 	}
	// }
	//
	// toggleVehiclePicker(force?: boolean): void {
	// 	this.vehiclePickerIsOpen = force !== undefined ? force : !this.vehiclePickerIsOpen;
	//
	// 	if (this.vehiclePickerIsOpen) {
	// 		this.toggleSuggestions(false);
	// 	}
	// }

	// onInputFocus(event): void {
	// 	this.getProducts({text: event.target.value});
	// 	this.toggleSuggestions(true);
	// }

	// private getProducts(data): void {
	// 	this.productService.getProducts(data).pipe(takeUntil(this.destroy$)).subscribe(res => {
	// 		this.products = res.payload.content.products;
	// 	})
	// }

	search() {
		let text = this.searchForm.controls['text'].value
		this.navigationService.goToSearch(text);
	}

	// goToProduct(product): void {
	// 	this.navigationService.goToProduct(product.id);
	// }
}
