import {Injectable} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AppConfig} from "../../core/config/appConfig";
import {Observable} from "rxjs";
import {ChangePassReqModel, ChangeUserResModel} from "../../core/interfaces/users/change-user.model";
import {ApiResultModel} from "../../core/interfaces/api-result.model";
import {
	GetOrderByIdResModel,
	GetOrderResModel,
	StatusResModel,
	StatusUpdate
} from "../../core/interfaces/order/order.model";
import {GetShopResModel} from "../../core/interfaces/shop.model";
import {GetUsersListResModel} from "../../core/interfaces/users/supplier/supplier.model";
import {GetPartnerShipsListModel} from "../../core/interfaces/users/partnership.model";

@Injectable()
export class DashboardService {

	dataApi = "4feba07aa3599be028bc7666d81c2c158f767ae1"

	constructor(private http: HttpClient) {
	}

	changePasswordForm(): FormGroup {
		return new FormGroup({
			currentPassword: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)]),
			newPassword: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)]),
			repeatPassword: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)]),
		})
	}

	changePassword(data: ChangePassReqModel): Observable<ApiResultModel<ChangeUserResModel>> {
		return this.http.post<ApiResultModel<ChangeUserResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.changePassword}`, data)
	}

	getOrders(page: number = 0, size: number = 25, sort: string = 'o.createdAt', order = 'desc', search: string = ''): Observable<ApiResultModel<GetOrderResModel>> {
		return this.http.get<ApiResultModel<GetOrderResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.orders.getOrders}`, {
			params: {
				orderBy: sort,
				orderDirection: order,
				page: '' + page,
				limit: '' + size,
				search
			}
		});
	}

	getOrdersById(id: string): Observable<ApiResultModel<GetOrderByIdResModel>> {
		return this.http.get<ApiResultModel<GetOrderByIdResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.orders.getOrdersById}${id}`);
	}

	editOrder(id: string, data: any): Observable<ApiResultModel<GetOrderByIdResModel>> {
		return this.http.patch<ApiResultModel<GetOrderByIdResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.orders.editOrder}${id}`, data);
	}

	editOrderStatus(id: string, data: StatusUpdate): Observable<ApiResultModel<StatusResModel>> {
		return this.http.patch<ApiResultModel<StatusResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.orders.editOrderStatus}${id}`, data);
	}

	getShops(query): Observable<ApiResultModel<GetShopResModel>> {
		return this.http.get<ApiResultModel<GetShopResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.shops.getShops}`, {
			params: query
		});
	}

	archiveShop(id: string): Observable<ApiResultModel<any>> {
		return this.http.patch<ApiResultModel<any>>(`${AppConfig.baseUrl}${AppConfig.endpoints.shops.archive}${id}`, {});
	}

	getPayments(page: number = 0, size: number = 25, sort: string = 'p.createdAt', order = 'desc', search: string = ''): Observable<ApiResultModel<any>> {
		return this.http.get<ApiResultModel<any>>(`${AppConfig.baseUrl}${AppConfig.endpoints.payments}`, {
			params: {
				orderBy: sort,
				orderDirection: order,
				page: '' + page,
				limit: '' + size,
				search
			}
		});
	}

	getUsers(page: number, limit: number): Observable<ApiResultModel<GetUsersListResModel>> {
		let params = new HttpParams()
			.set("orderBy", "u.id")
			.set("orderDirection", "DESC")
			.set("page", page)
			.set("limit", limit);
		return this.http.get<ApiResultModel<GetUsersListResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.getUsers}${params}`);
	}

	getPartners(shopId: any, page: number = 1, limit: number = 10, sort: string = 'p.createdAt', order = 'desc'): Observable<ApiResultModel<GetPartnerShipsListModel>> {
		let params = new HttpParams()
			.set("shopId", shopId)
			.set("page", page)
			.set("limit", limit)
			.set("orderBy", sort)
			.set("orderDirection", order);
		return this.http.get<ApiResultModel<GetPartnerShipsListModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.getPartners}${params}`);
	}

	getCompanyData(value: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Token ${this.dataApi}`,
			})
		};
		const body = Object.assign(
			{},
			{query: value}
		);

		return this.http.post<any>('https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party', body, httpOptions);
	}
}
