<div class="modal fade" tabindex="-1" role="dialog" bsModal #modal="bs-modal">

	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable quickview">
		<div class="modal-content">

			<div class="modal-header">
				<h2 class="modal-title">{{'EDIT_LIST_NAME' | translate}}</h2>
				<button type="button" class="quickview__close" (click)="modal.hide()">
					<app-icons icon="cross-12"></app-icons>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="listNameForm">
					<mat-form-field appearance="outline" class="w-50">
						<mat-label>{{'TABLE_NAME' | translate}}</mat-label>
						<input formControlName="name" maxlength="256" matInput>
					</mat-form-field>
					<mat-error *ngIf="showErrorMessage">{{errorMessage | translate}}</mat-error>
				</form>
			</div>
			<div class="modal-footer">
				<div class="d-flex flex-row justify-content-sm-end mt-3">
					<button class="btn btn-primary" (click)="edit()">
						{{'BUTTON_SAVE' | translate}}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
