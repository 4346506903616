import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {MobileMenuService} from "../../../../core/services/mobile-menu.service";
import {TranslateService} from "@ngx-translate/core";
import {FormControl, FormGroup} from "@angular/forms";
import {AuthService} from "../../../auth/auth.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DashboardService} from "../../../dashboard/dashboard.service";
import {ShopAddressesComponent} from "../../../../shared/components/shop-addresses/shop-addresses.component";
import {Base} from "../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {ApiResultModel} from "../../../../core/interfaces/api-result.model";
import {GetShopResModel} from "../../../../core/interfaces/shop.model";
import {ShopStateEnum} from "../../../../core/enums/shop.enum";

@Component({
	selector: 'app-mobile-menu-settings',
	templateUrl: './mobile-menu-settings.component.html'
})
export class MobileMenuSettingsComponent extends Base implements OnInit {
	@HostBinding('class.mobile-menu__settings-list') classMobileMenuSettingsList = true;
	language: string = localStorage.getItem('locale').toUpperCase();
	dialogRef: MatDialogRef<ShopAddressesComponent>
	form: FormGroup = new FormGroup({
		shop: new FormControl()
	})
	shops: any[] = [];
	languages: any;
	shopQuery = {
		page: 1,
		size: 10,
		sort: 's.updatedAt',
		order: 'desc',
		search: '',
		state: ShopStateEnum.Active
	};

	constructor(
		public menu: MobileMenuService,
		public translate: TranslateService,
		public authService: AuthService,
		private dialog: MatDialog,
		private dashboardService: DashboardService,
	) {
		super();
	}

	ngOnInit(): void {
		if (this.isAuthenticated) {
			this.authService.getAuth().pipe(takeUntil(this.destroy$)).subscribe({
				next: (res) => {
					if (res.payload?.customer?.id) {
						this.getShops(this.shopQuery);
					}
				}
			})
		}
	}

	get isAuthenticated() {
		return this.authService.isAuthenticated();
	}

	private getShops(query): void {
		this.dashboardService.getShops(query).pipe(takeUntil(this.destroy$)).subscribe((res: ApiResultModel<GetShopResModel>) => {
			this.shops = res.payload.content;
		});
	}

	changeLang(language: string) {
		localStorage.setItem('locale', language);
		this.translate.use(language);
	}

	setLanguage(event): void {
		localStorage.setItem('locale', event);
		this.translate.use(event);
		this.menu.close();
		this.language = localStorage.getItem('locale').toUpperCase();
	}

	setShop(event): void {
		this.authService.changeShopAddress.next(event);
		this.menu.close();
		localStorage.setItem('shop', JSON.stringify(event));
	}
}
