import {Component, OnInit} from '@angular/core';
import {FilterCheck} from "../../../../../core/data/filter.data";

@Component({
	selector: 'app-filter-check',
	templateUrl: './filter-check.component.html'
})
export class FilterCheckComponent implements OnInit {

	filters = FilterCheck;

	constructor() {
	}

	ngOnInit(): void {
	}

}
