import {Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output} from '@angular/core';
import {CardService} from "../card.service";
import {Router} from "@angular/router";
import {AuthService} from "../../../auth/auth.service";
import {Subscription} from "rxjs";
import {ShoppingCartResModel} from "../../../../core/interfaces/shopping-cart/shopping-cart.model";
import {Base} from "../../../../core/base";
import {takeUntil} from "rxjs/operators";

@Component({
	selector: 'app-shopping-cart-drop-down',
	templateUrl: './shopping-cart-drop-down.component.html'
})
export class ShoppingCartDropDownComponent extends Base implements OnInit, OnDestroy {

	@HostBinding('class.dropcart') classDropcart = true;
	shoppingCard: ShoppingCartResModel[] = [];
	shop = JSON.parse(localStorage.getItem('shop'));
	shopId  = this.shop.id;

	@Output() closeCardDropDown = new EventEmitter;
	private allSubscriptions = new Subscription();

	constructor(
		private cardService: CardService,
		private router: Router,
		private authService: AuthService
	) {
		super();
	}

	ngOnInit(): void {
		this.authService.changeShopAddress.pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.shopId = res.id;
			this.getCards();
		})
	}

	removeCard(event: Event, id: string): void {
		event.stopPropagation();
		this.cardService.removeAllCard(id).pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.getCards();
		})
	}

	goToCardPage(id) {
		this.closeCardDropDown.emit(true);
		this.router.navigate(['order/shopping-cart'], {queryParams: { id: id	}});
	}

	ngOnDestroy(): void {
		this.allSubscriptions.unsubscribe();
	}

	private getCards(): void {
		this.cardService.getCards({shopId: this.shopId}).pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.shoppingCard = res.payload.content;
		})
	}
}
