<ng-container *ngIf="isShopAddresses">
	<form [formGroup]="legalAddressForm">
		<div class="d-flex flex-column form-group">
			<form [formGroup]="legalAddressForm.controls['country']">
				<mat-form-field appearance="outline" class="mat-form-field w-100">
					<mat-label>{{ 'INPUT_COUNTRY_LABEL'|translate }}</mat-label>
					<mat-select formControlName="id">
						<mat-option *ngFor="let country of countries" [value]="country.id"
									(click)="onChangeCountry(country.countryName[translate.currentLang])">
							{{country.countryName[translate.currentLang]}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</form>

			<form [formGroup]="legalAddressForm.controls['state']" [class.readonly]="!legalAddressForm.controls['country'].value.id">
				<mat-form-field appearance="outline" class="mat-form-field w-100">
					<mat-label>{{ 'REGION'|translate }}</mat-label>
					<mat-select formControlName="id">
						<mat-option *ngFor="let province of provinces" [value]="province.id"
									(click)="onChangeProvince(province.regionName[translate.currentLang])">
							{{province.regionName[translate.currentLang]}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</form>

			<mat-form-field appearance="outline" class="mat-form-field">
				<mat-label>{{ 'CITY'|translate }}</mat-label>
				<input type="text" matInput formControlName="city"
					   [readonly]="!legalAddressForm.controls['state'].value.id"
					   [matAutocomplete]="auto1"/>
				<mat-autocomplete #auto1="matAutocomplete">
					<mat-option *ngFor="let city of cities" [value]="city.title.text">
						{{city.title.text}}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<mat-form-field appearance="outline" class="mat-form-field">
				<mat-label>{{ 'INPUT_FIRST_ADDRESS_LABEL'|translate }}</mat-label>
				<input type="text" matInput formControlName="address_1"
					   [readonly]="!legalAddressForm.controls['city'].value"
					   [matAutocomplete]="auto2"/>
				<mat-autocomplete #auto2="matAutocomplete">
					<mat-option *ngFor="let suggestion of suggestions" [value]="suggestion.title.text" (click)="onChangeAddress(suggestion.title.text)">
						{{ suggestion.title.text }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

		</div>
		<div class="d-flex flex-column form-group">
			<mat-form-field appearance="outline" class="mat-form-field">
				<mat-label>{{ 'INPUT_SECOND_ADDRESS_LABEL'|translate }}</mat-label>
				<input matInput type="text" formControlName="address_2" maxlength="100">
				<mat-error>{{ 'ERROR_FORM_REQUIRED' | translate }}</mat-error>
			</mat-form-field>
		</div>
		<div class="d-flex flex-column form-group">
			<mat-form-field appearance="outline" class="mat-form-field">
				<mat-label>{{ 'INPUT_POSTCODE_LABEL'|translate }}</mat-label>
				<input matInput type="text" formControlName="postCode" maxlength="20">
				<mat-error>{{ 'ERROR_FORM_REQUIRED' | translate }}</mat-error>
			</mat-form-field>
		</div>
		<div class="form-group d-flex flex-column">
			<mat-form-field appearance="outline">
				<mat-label>{{ 'INPUT_PHONE_NUMBER_LABEL'|translate }}</mat-label>
				<ngx-mat-intl-tel-input
					formControlName="phoneNumber1"
					[onlyCountries]="['ru']"
					name="mobile">
				</ngx-mat-intl-tel-input>
			</mat-form-field>
		</div>
		<div class="form-group d-flex flex-column">
			<mat-form-field appearance="outline">
				<mat-label>{{ 'INPUT_OTHER_PHONE_NUMBER_LABEL'|translate }}</mat-label>
				<ngx-mat-intl-tel-input
					formControlName="phoneNumber2"
					[onlyCountries]="['ru']"
					name="mobile">
				</ngx-mat-intl-tel-input>
			</mat-form-field>
		</div>
	</form>
	<form [formGroup]="deliveryAddressForm">
		<div class="d-flex flex-column form-group">
			<form [formGroup]="deliveryAddressForm.controls['country']">
				<mat-form-field appearance="outline" class="mat-form-field w-100">
					<mat-label>{{ 'INPUT_COUNTRY_LABEL'|translate }}</mat-label>
					<mat-select formControlName="id">
						<mat-option *ngFor="let country of countries" [value]="country.id"
									(click)="onChangeCountry(country.countryName[translate.currentLang])">
							{{country.countryName[translate.currentLang]}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</form>

			<form [formGroup]="deliveryAddressForm.controls['state']" [class.readonly]="!deliveryAddressForm.controls['country'].value.id">
				<mat-form-field appearance="outline" class="mat-form-field w-100">
					<mat-label>{{ 'REGION'|translate }}</mat-label>
					<mat-select formControlName="id">
						<mat-option *ngFor="let province of provinces" [value]="province.id"
									(click)="onChangeProvince(province.regionName[translate.currentLang])">
							{{province.regionName[translate.currentLang]}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</form>

			<mat-form-field appearance="outline" class="mat-form-field">
				<mat-label>{{ 'CITY'|translate }}</mat-label>
				<input type="text" matInput formControlName="city"
					   [readonly]="!deliveryAddressForm.controls['state'].value.id"
					   [matAutocomplete]="auto1"/>
				<mat-autocomplete #auto1="matAutocomplete">
					<mat-option *ngFor="let city of cities" [value]="city.title.text">
						{{city.title.text}}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<mat-form-field appearance="outline" class="mat-form-field">
				<mat-label>{{ 'INPUT_FIRST_ADDRESS_LABEL'|translate }}</mat-label>
				<input type="text" matInput formControlName="address_1"
					   [readonly]="!deliveryAddressForm.controls['city'].value"
					   [matAutocomplete]="auto2"/>
				<mat-autocomplete #auto2="matAutocomplete">
					<mat-option *ngFor="let suggestion of suggestions" [value]="suggestion.title.text" (click)="onChangeAddress(suggestion.title.text)">
						{{ suggestion.title.text }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

		</div>
		<div class="d-flex flex-column form-group">
			<mat-form-field appearance="outline" class="mat-form-field">
				<mat-label>{{ 'INPUT_SECOND_ADDRESS_LABEL'|translate }}</mat-label>
				<input matInput type="text" formControlName="address_2" maxlength="100">
				<mat-error>{{ 'ERROR_FORM_REQUIRED' | translate }}</mat-error>
			</mat-form-field>
		</div>
		<div class="d-flex flex-column form-group">
			<mat-form-field appearance="outline" class="mat-form-field">
				<mat-label>{{ 'INPUT_POSTCODE_LABEL'|translate }}</mat-label>
				<input matInput type="text" formControlName="postCode" maxlength="20">
				<mat-error>{{ 'ERROR_FORM_REQUIRED' | translate }}</mat-error>
			</mat-form-field>
		</div>
		<div class="form-group d-flex flex-column">
			<mat-form-field appearance="outline">
				<mat-label>{{ 'INPUT_PHONE_NUMBER_LABEL'|translate }}</mat-label>
				<ngx-mat-intl-tel-input
					formControlName="phoneNumber1"
					[onlyCountries]="['ru']"
					name="mobile">
				</ngx-mat-intl-tel-input>
			</mat-form-field>
		</div>
		<div class="form-group d-flex flex-column" *ngIf="showSecondNumber">
			<mat-form-field appearance="outline">
				<mat-label>{{ 'INPUT_OTHER_PHONE_NUMBER_LABEL'|translate }}</mat-label>
				<ngx-mat-intl-tel-input
					formControlName="phoneNumber2"
					[onlyCountries]="['ru']"
					name="mobile">
				</ngx-mat-intl-tel-input>
			</mat-form-field>
		</div>
	</form>
</ng-container>
