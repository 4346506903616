import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {AuthService} from "../../modules/auth/auth.service";

@Injectable({
	providedIn: 'root'
})

export class OnlyCustomersGuard implements CanActivate {

	constructor(
		private authService: AuthService,
		private router: Router
	) {
	}

	canActivate(): boolean {
		if (this.authService.isAuthenticated() && this.router.url !== 'dashboard/customer-dashboard') {
			if (localStorage.getItem('supplierId')) {
				this.router.navigateByUrl('dashboard/supplier-dashboard/supplier/account')
			}
		}
		return true
	}

}
