<div class="card">
	<div class="d-flex justify-content-between">
		<div class="card-header">
			<h2>{{"PAYMENTS_LIST" | translate}}</h2>
		</div>
		<div class="card-header">
			<mat-form-field appearance="outline">
				<mat-label>{{'SEARCH' | translate}}</mat-label>
				<input matInput (keyup)="applyFilter($event.target.value)" #input>
			</mat-form-field>
		</div>
	</div>


	<div>
		<div class="table-responsive-sm">

			<table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

				<!--		<ng-container matColumnDef="number">-->
				<!--			<th mat-header-cell *matHeaderCellDef mat-sort-header style="color: black; font-weight: bold"> {{'TABLE_NUMBER' | translate}} </th>-->
				<!--			<td mat-cell *matCellDef="let row"> {{row.id}} </td>-->
				<!--		</ng-container>-->

				<ng-container matColumnDef="deposit">
					<th mat-header-cell *matHeaderCellDef mat-sort-header="p.deposit" style="color: black; font-weight: bold"> {{'TABLE_DEPOSIT' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.deposit}} ₽</td>
				</ng-container>

				<ng-container matColumnDef="reward">
					<th mat-header-cell *matHeaderCellDef mat-sort-header="p.reward" style="color: black; font-weight: bold"> {{'TABLE_REWARD' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.reward}} <span style="color: red"> B</span></td>
				</ng-container>

				<ng-container matColumnDef="total-deposit">
					<th mat-header-cell *matHeaderCellDef style="color: black; font-weight: bold"> {{'TABLE_TOTAL_DEPOSIT' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.totalDeposit}} ₽</td>
				</ng-container>

				<ng-container matColumnDef="order">
					<th mat-header-cell *matHeaderCellDef style="color: black; font-weight: bold"> {{'TABLE_ORDER' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.order ? row.order.orderNumber : ''}} </td>
				</ng-container>

				<ng-container matColumnDef="createdAt">
					<th mat-header-cell *matHeaderCellDef mat-sort-header="p.createdAt"
						style="color: black; font-weight: bold"> {{'TABLE_DATE' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.createdAt | localizedDate: dateFormat}} </td>
				</ng-container>

				<!--		<ng-container matColumnDef="updatedDate">-->
				<!--			<th mat-header-cell *matHeaderCellDef mat-sort-header-->
				<!--				style="color: black; font-weight: bold"> {{'TABLE_UPDATED_DATE' | translate}} </th>-->
				<!--			<td mat-cell *matCellDef="let row"> {{row.updatedAt | localizedDate: dateFormat}} </td>-->
				<!--		</ng-container>-->

				<ng-container matColumnDef="total-reward">
					<th mat-header-cell *matHeaderCellDef style="color: black; font-weight: bold"> {{'TABLE_TOTAL_REWARD' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.totalReward}} </td>
				</ng-container>

				<ng-container matColumnDef="type">
					<th mat-header-cell *matHeaderCellDef mat-sort-header="p.type" style="color: black; font-weight: bold"> {{'TABLE_TYPE' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.type | translate}} </td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell" colspan="4"><h2>{{ 'TEXT_NO_MATCHING_PAYMENT_ITEMS_TITLE'|translate }}{{' ' + input.value}}</h2></td>
				</tr>
			</table>
			<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

			<mat-paginator [pageSizeOptions]="[10, 25, 100]" [length]="pagination?.totalRecords"
						   (page)="pageEvent = $event; onPaginateChange($event)" showFirstLastButtons></mat-paginator>
		</div>
	</div>
</div>




