<div class="site site--desktop-header--{{ header.desktopVariantClass }} site--mobile-header--mobile-{{ header.mobileVariant }}">
	<app-loading-bar></app-loading-bar>

	<div class="site__container">
		<header class="site__mobile-header">
			<app-mobile-header></app-mobile-header>
		</header>
		<header class="site__header">
			<app-header></app-header>
		</header>
		<div class="site__body">
			<router-outlet></router-outlet>
		</div>
		<footer class="site__footer">
			<app-footer></app-footer>
		</footer>
	</div>

	<app-mobile-menu></app-mobile-menu>
</div>
<app-product-quick-view></app-product-quick-view>
<app-wish-list-pop-up></app-wish-list-pop-up>
<app-shop-addresses></app-shop-addresses>
<app-change-wish-list-name></app-change-wish-list-name>
<app-partnership-dialog></app-partnership-dialog>
