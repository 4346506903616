<div class="block-split block-split--has-sidebar">
	<div class="container">
		<div class="block-split__row row no-gutters">
			<div class="block-split__item block-split__item-sidebar col-auto">
				<div class="sidebar__body">
					<div class="sidebar__content">
						<div class="widget widget-filters widget-filters--offcanvas--mobile">
							<div class="widget__header widget-filters__header">
								<h4>{{'HEADER_FILTERS' | translate}}</h4>
							</div>
							<div class="widget-filters__list" [formGroup]="form">
								<div *ngFor="let filter of filters; trackBy: trackBySlug" class="widget-filters__item">
									<div class="filter filter--opened" appCollapseItem="filter--opened"
										 #collapseItem="appCollapseItem">
										<button *ngIf="filter.type === 'range' && (options?.floor !== options?.ceil)" type="button" class="filter__title" (click)="collapseItem.toggle()">
											{{ filter.name | translate }}
											<span class="filter__arrow"><app-icons
												icon="arrow-rounded-down-12x7"></app-icons></span>
										</button>
										<div class="filter__body">
											<div class="filter__container">
<!--												<app-filter-shop-address *ngIf="filter.type === 'address'"-->
<!--																		 formControlName=address>-->
<!--												</app-filter-shop-address>-->
												<app-filter-category *ngIf="filter.type === 'category'"
																	 [options]="filter" [category]="category">
												</app-filter-category>
												<app-filter-range *ngIf="filter.type === 'range' && hasLoadedProducts  && (options?.floor !== options?.ceil)"
																  [options]="options"
																  formControlName=range>
												</app-filter-range>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="widget-filters__actions d-flex">
								<button class="btn btn-secondary btn-sm"
										(click)="reset()">{{'BUTTON_RESET' | translate}}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
