import {Component, OnInit} from '@angular/core';
import {Base} from "../../../../core/base";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Location} from "@angular/common";
import {CustomerAuthService} from "../customer-auth.service";
import {takeUntil} from "rxjs/operators";
import {ApiResultModel} from "../../../../core/interfaces/api-result.model";
import {Router} from "@angular/router";
import {AppConfig} from "../../../../core/config/appConfig";
import {ErrorMessage} from "@angular/compiler-cli/ngcc/src/execution/cluster/api";
import {AuthService} from "../../auth.service";

@Component({
	selector: 'app-customer-verification',
	templateUrl: './customer-verification.component.html'
})
export class CustomerVerificationComponent extends Base implements OnInit {
	currentStep = 1
	verificationForm: FormGroup;
	passwordForm: FormGroup;
	showErrorMessage: boolean;
	wrongToken: boolean;
	errorMessage: string;
	verificationData: any;
	resend = false;
	showSendButton = true;
	hide = false;
	matchPassword = false;

	constructor(private fb: FormBuilder,
				private location: Location,
				private router: Router,
				private authService: AuthService,
				private customerAuthService: CustomerAuthService,
	) {
		super();
	}

	ngOnInit() {
		this.initVerificationForm();
		this.initPasswordForm();
		this.checkConfirmPasswordValidation();
	}

	private initVerificationForm(): void {
		this.verificationForm = this.fb.group({
			mobile: ['', [Validators.required, Validators.pattern(/^\+[1-9]\d{1,14}$/), Validators.minLength(8)]],
			token: '',
		})
	}

	private initPasswordForm(): void {
		this.passwordForm = this.fb.group({
			password: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)]],
			confirmPassword: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)]],
		})
	}

	goToLogin(): void {
		this.location.back();
	}

	sendMobile(): void {
		const reqData = {
			mobile: this.verificationForm.get('mobile').value
		}
		if (this.verificationForm.get('mobile').valid) {
			if (this.router.url.split('/')[3] === AppConfig.routes.authentication.childRouts.customerAuth.forgotPassword) {
				this.customerAuthService.customerForgotPassword(reqData)
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: (res: ApiResultModel<{ message: string, duration: number }>) => {
							this.showErrorMessage = false;
							this.currentStep = 2;
							this.verificationData = res.payload;
						},
						error: (error) => {
							this.showErrorMessage = true;
							this.errorMessage = error.error.message
						}
					})
			} else if (this.router.url.split('/')[3] === AppConfig.routes.authentication.childRouts.customerAuth.registration) {
				this.customerAuthService.customerRegistration(reqData)
					.pipe(takeUntil(this.destroy$))
					.subscribe({
						next: (res: ApiResultModel<any>) => {
							this.currentStep = 2;
							this.verificationData = res.payload;
							this.showErrorMessage = false;
						},
						error: (error) => {
							this.showErrorMessage = true;
							this.errorMessage = error.error.message;
						}
					})
			}

		}
	}

	handleEvent(e: any): void {
		if (e.action == 'done') {
			this.resend = true;
			this.showSendButton = false;
		}
	}

	sendVerifyCode(): void {
		const reqData = {
			mobile: this.verificationForm.get('mobile').value,
			token: this.verificationForm.get('token').value
		}
		if (this.verificationForm.get('mobile').valid && this.verificationForm.get('token').valid) {
			this.customerAuthService.customerVerifyAccount(reqData).subscribe({
				next: () => {
					this.showSendButton = false;
					this.currentStep = 3;
				},
				error: (error) => {
					this.wrongToken = error.error.message;
				}
			})
		} else {
			this.wrongToken = true;
		}
	}

	resendVerify(): void {
		const reqData = {
			mobile: this.verificationForm.get('mobile').value
		}
		this.resend = false;
		this.showSendButton = true;

		this.customerAuthService.resendCustomerVerifyCode(reqData).pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (res: ApiResultModel<any>) => {
					this.verificationData = res.payload;
					this.wrongToken = false;
				},
				error: (error: ErrorMessage) => {
				}
			})
	}

	createPassword(): void {
		const reqData = {
			password: this.passwordForm.get('password').value,
			mobile: this.verificationForm.get('mobile').value,
			token: this.verificationForm.get('token').value
		}
		if (!this.checkConfirmPasswordValidation()) {
			if (this.router.url.split('/')[3] === AppConfig.routes.authentication.childRouts.customerAuth.forgotPassword) {
				this.customerAuthService.resetCustomerPassword(reqData).pipe(takeUntil(this.destroy$)).subscribe(() => {
					this.location.back();
				})
			} else if (this.router.url.split('/')[3] === AppConfig.routes.authentication.childRouts.customerAuth.registration) {
				this.customerAuthService.createPassword(reqData).pipe(takeUntil(this.destroy$)).subscribe((res) => {
					localStorage.setItem('access_token', res.payload.token.accessToken);
					this.authService.loggedInCustomer.next(true);
					this.authService.isLogIn.next(true);
					this.router.navigateByUrl('dashboard/customer-dashboard/customer/create-profile');
				})
			}
		} else {
			this.matchPassword = true;
		}
	}

	checkConfirmPasswordValidation(): boolean {
		const password = this.passwordForm.controls['password'];
		const confirmPassword = this.passwordForm.controls['confirmPassword'];

		this.passwordForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(res => {
			if (confirmPassword?.value && password.value) {
				this.matchPassword = (password.value !== confirmPassword.value);
			}
		})
		return this.matchPassword;
	}
}
