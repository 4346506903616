<div class="widget-categories-list__body">
	<ul class="widget-categories-list__root">
		<li
			*ngFor="let category of categories | categoryActive"
			class="widget-categories-list__root-item"
			[class.widget-categories-list__root-item--has-children]="getChildren(category).length > 0"
			appCollapseItem="widget-categories-list--open"
			#collapseItem="appCollapseItem"
		>
			<a (click)="goToCategory(category.id)" class="widget-categories-list__root-link">{{ category.name[translate.currentLang] }}</a>

			<ng-container *ngIf="getChildren(category).length > 0">
				<ul class="widget-categories-list__child">
					<li
						*ngFor="let sub of getChildren(category)|slice:0:(getChildren(category).length > 6 ? 5 : 6) | categoryActive"
						class="widget-categories-list__child-item"
					>
						<a (click)="goToCategory(sub.id)" class="widget-categories-list__child-link">{{ sub.name[translate.currentLang] }}</a>
					</li>
				</ul>
			</ng-container>
		</li>
	</ul>
</div>

