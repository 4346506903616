<div class="container">
	<div class="block-header__body">
		<div class="block-header__breadcrumb breadcrumb ng-star-inserted"></div>
		<h1 class="block-header__title"
			style="margin: 0 auto; font-size: 2.25rem;font-weight: 700;padding-top: 10px;padding-bottom: 40px">{{'HEADER_SHOP' | translate }}</h1>
	</div>
</div>

<div class="block block-split block-split--has-sidebar">
	<div class="container">
		<div class="block-split__row row no-gutters">
			<div class="block-split__item block-split__item-sidebar col-auto ng-star-inserted">
				<app-widget-category-list class="card widget widget-categories-list ng-star-inserted"
										  [categories]="categoryData">
				</app-widget-category-list>
			</div>
			<div class="block-split__item block-split__item-content col-auto flex-grow-1">
				<div class="block ng-star-inserted">
					<div class="categories-list categories-list--layout--columns-3-sidebar">
						<ul class="categories-list__body">
							<li *ngFor="let subcategory of categoryData | categoryActive"
								class="categories-list__item categories-list__item--has-image ng-star-inserted"
								(click)="goToCategory(subcategory.id)">
								<a>
									<div *ngIf="subcategory.image" class="image image--type--category ng-star-inserted">
										<div class="image__body">
											<img class="image__tag" src="assets/images/categories/category-1.jpg"
												 alt="">
										</div>
									</div>
									<div class="categories-list__item-name">{{subcategory.name[translate.currentLang]}}</div>
								</a>
								<!--								<div class="categories-list__item-products">-->
								<!--									{{ subcategory.children.length + ' '}}{{'TEXT_PRODUCTS' | translate}}-->
								<!--								</div>-->
								<div class="categories-list__item-products ng-star-inserted"></div>
							</li>

							<li class="categories-list__divider"></li>
						</ul>
					</div>
				</div>
				<div class="block-space block-space--layout--divider-nl ng-star-inserted"></div>
			</div>
		</div>
	</div>
</div>

<div class="block-space block-space--layout--before-footer"></div>
<!--<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>-->
