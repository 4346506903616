import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AppConfig} from "../config/appConfig";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {ProductsContentItemModel, ProductsDataModel} from "../interfaces/products/products-data.model";
import {SuppliersListModel} from "../interfaces/users/supplier/suppliers-list.model";
import {ApiResultModel} from "../interfaces/api-result.model";
import {PartnershipsContentItemModel, PartnershipSendReqModel} from "../interfaces/users/partnership.model";
import {tap} from "rxjs/operators";
import {TranslationKeys} from "../data/translation-keys";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {CategoriesContentItemModel, GetCategoriesResModel} from "../interfaces/categories/categories.model";
import {PartnershipStatusEnum} from "../enums/partnerships.enum";
import {GetSupplierResModel} from "../interfaces/users/supplier/supplier.model";
import axios from "axios";

@Injectable({
	providedIn: 'root'
})
export class GlobalService {
	onHideCategoriesMenu: Subject<boolean> = new Subject();

	constructor(private http: HttpClient,
				private toastr: ToastrService,
				private translate: TranslateService) {}

	getHomePageAllProducts(query: any): Observable<ApiResultModel<ProductsDataModel>> {
		return this.http.get<ApiResultModel<ProductsDataModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.getHomePageAllProducts}`, {params: query});
	}

	getPartnerShipById(id: number | string): Observable<ApiResultModel<PartnershipsContentItemModel>> {
		return this.http.get<ApiResultModel<PartnershipsContentItemModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.getPartnerShipById}/${id}`);
	}


	sendReqToSupplier(supplierId: string, shopId: string): Observable<ApiResultModel<PartnershipSendReqModel>> {
		return  this.http.post<ApiResultModel<PartnershipSendReqModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.sendReqToSupplier}/${supplierId}/${shopId}`, {}).pipe(tap({
			next: () => {
				this.toastr.success(this.translate.instant(TranslationKeys.RequestSent));
			},
			error: (error) => {
				this.toastr.error(this.translate.instant(error.error.message));
			}
		}));
	}

	getSuppliers(query: any): Observable<ApiResultModel<SuppliersListModel>> {
		return this.http.get<ApiResultModel<SuppliersListModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.getSuppliers}`, {params: query});
	}

	uploadLogo(params: string, file: FormData): Observable<any> {
		return this.http.post<any>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.products.uploadLogo}/${params}`, file);
	}

	uploadGallery(params: string, files: FormData): Observable<any> {
		return this.http.post<any>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.products.uploadGallery}${params}`, files);
	}

	updateGallery(files: FormData): Observable<any> {
		return this.http.put<any>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.products.updateGallery}`, files);
	}

	deleteGalleryImage(params: number): Observable<any> {
		return this.http.delete<any>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.products.deleteGalleryImage}${params}`);
	}

	uploadAvatar(files: FormData): Observable<any> {
		return this.http.post(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.uploadAvatar}`, files);
	}

	uploadPhoto(id: string, files: FormData): Observable<number> {
		return this.http.post<number>(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.uploadPhoto}/${id}`, files);
	}

	getCategories(): Observable<ApiResultModel<GetCategoriesResModel>> {
		return this.http.get<ApiResultModel<GetCategoriesResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.getCategories}`);
	}

	getCategoriesById(id: string): Observable<ApiResultModel<CategoriesContentItemModel>> {
		return this.http.get<ApiResultModel<CategoriesContentItemModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.getCategoriesById}${id}`);
	}

	getProductsByShop(query: {id: string, shopId?: string}): Observable<ApiResultModel<ProductsContentItemModel>> {
		return this.http.get<ApiResultModel<ProductsContentItemModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.products.productsById}${query.id}`, { params: query});
	}

	respondRequest(id: string, status: {status: PartnershipStatusEnum }): Observable<ApiResultModel<any>> {
		return this.http.put<ApiResultModel<any>>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.respondRequest}/${id}`, status);
	}

	partnershipUpdate(id: string, dto): Observable<ApiResultModel<any>> {
		return this.http.put<ApiResultModel<any>>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.partnershipUpdate}/${id}`, dto);
	}

	deleteRequest(id: string): Observable<ApiResultModel<any>> {
		return this.http.delete<any>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.deleteRequest}/${id}`).pipe(tap({
			next: () => {
			},
			error: (error) => {
			}
		}));
	}

	getSupplier(id: string, shopId?: string): Observable<ApiResultModel<GetSupplierResModel>> {
		return this.http.get<ApiResultModel<GetSupplierResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.getSupplier}${id}?shopId=${shopId}`);
	}
}
