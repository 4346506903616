import {NgModule} from '@angular/core';
import {OrderModule} from "../order/order.module";
import {HeaderComponent} from "./header/header.component";
import {FooterComponent} from "./footer/footer.component";
import {HeaderIndicatorsComponent} from "./header/components/header-indicators/header-indicators.component";
import {HeaderSearchComponent} from "./header/components/header-search/header-search.component";
import {SharedModule} from "../../shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {DashboardModule} from "../dashboard/dashboard.module";
import {ClickOutsideModule} from "ng-click-outside";
import {RouterModule} from "@angular/router";
import {NgMaterialModule} from "../../ng-material.module";
import {MatListModule} from "@angular/material/list";
import {TreeModule} from "@circlon/angular-tree-component";
import {CategoriesComponent} from "./header/components/categories/categories.component";
import {MegamenuComponent} from "./header/components/megamenu/megamenu.component";

@NgModule({
	declarations: [
		HeaderComponent,
		FooterComponent,
		HeaderIndicatorsComponent,
		HeaderSearchComponent,
		CategoriesComponent,
		MegamenuComponent
	],
	exports: [
		HeaderComponent,
		FooterComponent,
		HeaderIndicatorsComponent,
		HeaderSearchComponent
	],
    imports: [
        OrderModule,
        SharedModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        DashboardModule,
        ClickOutsideModule,
        RouterModule,
        NgMaterialModule,
        TreeModule,
        MatListModule,
        CommonModule
    ],
	providers: [
	],
	entryComponents: [
	]
})
export class LayoutModule {
}
