import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {Location} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {DashboardService} from "../../../dashboard.service";
import {ApiResultModel} from "../../../../../core/interfaces/api-result.model";
import {GetOrderByIdResModel} from "../../../../../core/interfaces/order/order.model";
import {Base} from "../../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {AppConfig} from "../../../../../core/config/appConfig";
import {OrderStatusesEnum} from "../../../../../core/enums/order-statuses.enum";
import {NavigationService} from "../../../../../core/services/navigation-service";
import {TranslationKeys} from "../../../../../core/data/translation-keys";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-orders-view',
	templateUrl: './orders-view.component.html'
})
export class OrdersViewComponent extends Base implements OnInit {
	orderId: string;
	orderStatus: any;
	dataSource:  MatTableDataSource<any>;
	displayedColumns: string[] = ['product', 'quantity', 'price', 'discount', 'reward', 'total'];
	priceSum: number = 0;
	rewardSum: number = 0
	isLoading: boolean;
	supplierId: string | number;
	order: GetOrderByIdResModel;
	dateFormat = AppConfig.constants.longDate;
	orderStatuses = OrderStatusesEnum

	constructor(private activateRouter: ActivatedRoute,
				private router: Router,
				private location: Location,
				private toastr: ToastrService,
				public translate: TranslateService,
				private dashboardService: DashboardService,
				private dialog: MatDialog,
				private navigationService: NavigationService) {
		super();
	}

	ngOnInit(): void {
		this.orderStatus = this.activateRouter.snapshot.queryParams.status;
		this.orderId = this.activateRouter.snapshot.queryParams.id;
		this.getOrderById();
	}

	private getOrderById(): void {
		this.isLoading = true;
		this.dashboardService.getOrdersById(this.orderId).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<GetOrderByIdResModel>) => {
				this.isLoading = false;
				this.order = res.payload;
				this.dataSource = new MatTableDataSource(res.payload.orderItems);
				this.supplierId = res.payload.supplier.id;

				this.dataSource.filteredData.forEach(a => {
					this.priceSum += a.price
					this.rewardSum += +a.reward
				});
			},
			error: () => {
				this.isLoading = false;
			}
		})
	}

	goToList(): void {
		this.location.back();
	}

	changeOrder(id: string): void {

		this.router.navigate(['dashboard/order-edit'], { queryParams: { id: id } });
	}

	goToProduct(id: string): void {
		this.navigationService.goToProduct(id);
	}

	repeatOrder(): void {
		this.toastr.info(this.translate.instant(TranslationKeys.DevelopmentStage));
	}
}
