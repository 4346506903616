import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {WishListService} from "../wish-list.service";
import {takeUntil, timer} from "rxjs";
import {Base} from "../../../../core/base";
import {switchMap} from "rxjs/operators";
import {ModalDirective} from "ngx-bootstrap/modal";

@Component({
	selector: 'app-change-wish-list-name',
	templateUrl: './change-wish-list-name.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeWishListNameComponent extends Base implements OnInit, AfterViewInit {
	@ViewChild('modal') modal!: ModalDirective;
	listNameForm: FormGroup;
	listId: string;
	showErrorMessage: boolean;
	errorMessage: string;

	constructor(private wishListService: WishListService,
				private cdRef: ChangeDetectorRef) {
		super();
	}

	ngOnInit(): void {
		this.listNameForm = new FormGroup({
			name: new FormControl('', Validators.required)
		})
	}

	ngAfterViewInit(): void {
		this.showErrorMessage = false;
		this.wishListService.showWishListEditPopup$.pipe(
			switchMap(list => {
				this.listId = list.id;
				this.modal.show();
				this.listNameForm.get('name').setValue(list.name)
				return timer(150);
			}),
			takeUntil(this.destroy$),
		).subscribe(() => {
		});

		this.modal.onHidden.pipe(takeUntil(this.destroy$)).subscribe(() => {
		});
	}

	edit(): void {
		this.wishListService.updateFavoriteList(this.listId, this.listNameForm.getRawValue()).pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				this.modal.hide();
				this.cdRef.markForCheck();
				this.wishListService.changeWishListName$.next(this.listNameForm.value);
			},
			error: (err) => {
				this.showErrorMessage = true;
				this.errorMessage = err.error.message;
				this.cdRef.markForCheck();
			}
		})
	}
}
