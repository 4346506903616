export enum OrderStatusesEnum {
	Pending = 'pending',
	Accepted = 'accepted',
	Declined = 'declined',
	Shipped = 'shipped',
	Arrived = 'arrived',
	Returned = 'returned',
	Canceled = 'canceled',
	Updated = 'updated'
}
