import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {AppConfig} from "../../core/config/appConfig";
import {RedirectGuard} from "../../core/guards/redirect.guard";
import {AuthComponent} from "./auth.component";

const routes: Routes = [
	{ path: '', redirectTo: `customer/${AppConfig.routes.authentication.childRouts.customerAuth.login}`, pathMatch: 'full' },
	{
		path: '', component: AuthComponent, children: [
			{
				path: 'customer',
				loadChildren: () => import('./customer-auth/customer-auth.module').then(m => m.CustomerAuthModule),
				canActivate: [RedirectGuard]

			}
		]
	},

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AuthRoutingModule {
}
