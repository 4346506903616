<ul class="filter-rating__list">
	<li
		*ngFor="let item of value"
		class="filter-rating__item"
		[class.filter-rating__item--disabled]="item.count === 0"
	>
		<label class="filter-rating__item-label">
			<div class="filter-rating__item-input"
			>
				<span class="input-check__body">
    <input
		class="input-check__input"
		type="checkbox"
	>
    <span class="input-check__box"></span>
    <span class="input-check__icon">
        <svg width="9px" height="7px">
            <path d="M9,1.395L3.46,7L0,3.5L1.383,2.095L3.46,4.2L7.617,0L9,1.395Z"/>
        </svg>
    </span>
</span>
			</div>
			<span class="filter-rating__item-stars">
				<div class="rating__body">
     		 	<div
					*ngFor="let i of [1, 2, 3, 4, 5]"
					class="rating__star"
					[class.rating__star--active]="item.rating >= i"
				></div>
				</div>
            </span>
			<span *ngIf="item.count" class="filter-rating__item-counter">{{ item.count }}</span>
		</label>
	</li>
</ul>

