import {Component, OnInit} from '@angular/core';
import {FilterRadio} from "../../../../../core/data/filter.data";

@Component({
	selector: 'app-filter-radio',
	templateUrl: './filter-radio.component.html'
})
export class FilterRadioComponent implements OnInit {

	filters = FilterRadio;

	constructor() {
	}

	ngOnInit(): void {
	}

}
