import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {AuthService} from "../../modules/auth/auth.service";

@Injectable({
	providedIn: 'root'
})

export class RedirectGuard implements CanActivate {

	constructor(
		private authService: AuthService,
		private router: Router
	) {
	}

	canActivate(): boolean {
		if (this.authService.isAuthenticated() && this.router.url !== 'authentication') {
			this.router.navigateByUrl('dashboard/customer-dashboard/customer/account')
		}
		return true
	}

}
