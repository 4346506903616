<div class="block post-view">
	<div class="post-view__header post-header post-header--has-image">
		<div class="post-header__image"
			[ngStyle]="{
                'background-image': 'url(assets/images/posts/post-1.jpg)'
            }"
		></div>
		<div class="post-header__body">
			<div class="post-header__categories">
				<ul class="post-header__categories-list">
					<li class="post-header__categories-item">
						<a href="" class="post-header__categories-link">Latest News</a>
					</li>
				</ul>
			</div>
			<h1 class="post-header__title">{{postName}}</h1>
			<div class="post-header__meta">
				<ul class="post-header__meta-list">
					<li class="post-header__meta-item">By <a href="" class="post-header__meta-link">Jessica Moore</a></li>
					<li class="post-header__meta-item">November 30, 2018</li>
					<li class="post-header__meta-item"><a href="" class="post-header__meta-link">4 Comments</a></li>
				</ul>
			</div>
		</div>
		<div class="post-header__decor" type="bottom">
			<div class="decor__body">
				<div class="decor__start"></div>
				<div class="decor__end"></div>
				<div class="decor__center"></div>
			</div>
		</div>
	</div>
</div>
<div class="container">
	<div class="post-view__item post-view__item-post">
		<div class="post-view__card post">
			<div class="post__body typography">
				<p>
					Vestibulum sagittis justo sit amet nisl semper, et pulvinar elit maximus. Morbi interdum velit quis magna placerat lobortis
					eget pharetra magna. Nulla tristique sollicitudin turpis, eget maximus risus faucibus non. Nulla vestibulum ipsum risus,
					vitae maximus nunc bibendum quis.
				</p>
				<p>
					Raesent eu consequat nibh. Quisque ullamcorper, augue eu fringilla sodales, leo metus volutpat risus, at suscipit ipsum diam
					eget sem. Maecenas dictum elit in enim molestie, vel sollicitudin erat ultricies. Sed risus tellus, molestie finibus dui quis,
					suscipit consequat ex.
				</p>
				<blockquote>
					<p>
						Sed a dictum elit. In iaculis porttitor luctus. Maecenas ultricies dolor et semper placerat.
						Proin at lectus felis.
					</p>
					<p><cite>John Mcarthy</cite></p>
				</blockquote>
				<p>
					Vivamus in nisi at turpis rhoncus feugiat. Mauris scelerisque non ante et ultrices. Donec sit amet sem lobortis,
					ullamcorper felis at, finibus sem. Curabitur tincidunt neque nunc.
				</p>
				<h2>Nam Eget Blandit Diam</h2>
				<p>
					Quisque semper magna eget libero maximus, a sollicitudin nunc hendrerit. Cras efficitur, ante vitae fringilla rutrum, mi tortor
					dapibus metus, in egestas metus erat sit amet orci. Ut faucibus non ante dapibus efficitur. Nam eget blandit diam, imperdiet
					condimentum neque. Donec risus nisi, aliquet a commodo ac, elementum vitae leo.
				</p>
				<p>
					Vestibulum sagittis justo sit amet nisl semper, et pulvinar elit maximus. Morbi interdum velit quis magna placerat lobortis
					eget pharetra magna.
				</p>
				<p>
					<strong>Nulla fringilla:</strong> <a href="">Donec aliquet at felis et dignissim</a>
				</p>

				<figure>
					<a href="">
						<img src="assets/images/posts/post-3.jpg" alt="">
					</a>
					<figcaption>Nunc viverra, dui nec commodo dignissim, libero arcu.</figcaption>
				</figure>

				<p>
					Vestibulum non varius lectus. Cras vel elit id ligula laoreet imperdiet. Mauris quis laoreet velit. Suspendisse sed velit nec
					ante facilisis pharetra.
				</p>
				<p>
					Phasellus ut elit vestibulum, dignissim mi non, suscipit ex. Praesent eu consequat nibh. Quisque ullamcorper, augue eu
					fringilla sodales, leo metus volutpat risus, <a href="">at suscipit ipsum diam eget sem</a>. Maecenas dictum elit in enim
					molestie, vel sollicitudin erat ultricies. Sed risus tellus, molestie finibus dui quis, suscipit consequat ex.
				</p>
				<hr>
				<h2>Nunc Dapibus Varius Ligula</h2>
				<p>
					Maecenas ultrices arcu ut feugiat semper. Praesent dictum tincidunt justo, ac tincidunt ante fermentum at. Vestibulum non
					varius lectus. Cras vel elit id ligula laoreet imperdiet. Mauris quis laoreet velit. Suspendisse sed velit nec ante facilisis
					pharetra. Duis vitae fermentum elit. Integer ac mattis elit.
				</p>
				<p>
					Mauris scelerisque non ante et ultrices. Donec sit amet sem lobortis:
				</p>
				<ol>
					<li>
						Duis <strong>finibus imperdiet ultricies</strong>. Donec vel pretium turpis. In auctor euismod posuere.
					</li>
					<li>
						Praesent dictum tincidunt justo, ac tincidunt ante fermentum at. Vestibulum non varius lectus. Cras vel elit id ligula
						laoreet imperdiet.
					</li>
					<li>
						<strong>In iaculis porttitor luctus</strong>. Maecenas ultricies dolor et semper placerat. Proin at lectus felis. Quisque
						dapibus auctor justo id dictum.
					</li>
				</ol>
				<p>
					Ut faucibus non ante dapibus efficitur. Nam eget blandit diam, imperdiet condimentum neque. Donec risus nisi, aliquet a
					commodo ac, elementum vitae leo.
				</p>
			</div>

			<div class="post__pagination">
				<div class="post__pagination-title">
					Pages
				</div>
				<div class="post__pagination-list">
					<ul>
						<li><span class="post__pagination-link post__pagination-link--current">1</span></li>
						<li><a href="" class="post__pagination-link">2</a></li>
						<li><a href="" class="post__pagination-link">3</a></li>
					</ul>
				</div>
			</div>

			<div class="post__footer">
				<div class="post__tags tags tags--sm">
					<div class="tags__list">
						<a href="">Promotion</a>
						<a href="">Power Tool</a>
						<a href="">Wrench</a>
						<a href="">Electrodes</a>
					</div>
				</div>
			</div>

			<div class="post__author">
				<div class="post__author-avatar">
					<img src="assets/images/avatars/avatar-4.jpg" alt="">
				</div>
				<div class="post__author-info">
					<div class="post__author-name">
						Ryan Ford
					</div>
					<div class="post__author-about">
						Aliquam ullamcorper elementum sagittis. Etiam lacus lacus, mollis in mattis in, vehicula eu nulla. Nulla nec tellus
						pellentesque.
					</div>
				</div>
			</div>
		</div>

		<div class="post-view__card post-navigation">
			<div class="post-navigation__body">
				<a class="post-navigation__item post-navigation__item--prev" href="">
					<div class="post-navigation__item-image">
					</div>
					<div class="post-navigation__item-info">
						<div class="post-navigation__direction">
							<div class="post-navigation__direction-arrow">
								<app-icons icon="arrow-rounded-left-7x11"></app-icons>
							</div>
							<div class="post-navigation__direction-title">
								Previous post
							</div>
						</div>
						<div class="post-navigation__item-title">
						</div>
					</div>
				</a>

				<a class="post-navigation__item post-navigation__item--next" href="">
					<div class="post-navigation__item-info">
						<div class="post-navigation__direction">
							<div class="post-navigation__direction-title">
								Next post
							</div>
							<div class="post-navigation__direction-arrow">
								<app-icons icon="arrow-rounded-right-7x11"></app-icons>
							</div>
						</div>
						<div class="post-navigation__item-title">
						</div>
					</div>
					<div class="post-navigation__item-image">

					</div>
				</a>
			</div>
		</div>

		<div class="post-view__card">
			<h2 class="post-view__card-title">Write A Comment</h2>

			<form class="post-view__card-body">
				<div class="form-row">
					<div class="form-group col-md-4">
						<label for="comment-first-name">First Name</label>
						<input type="text" class="form-control" id="comment-first-name" placeholder="First Name">
					</div>
					<div class="form-group col-md-4">
						<label for="comment-last-name">Last Name</label>
						<input type="text" class="form-control" id="comment-last-name" placeholder="Last Name">
					</div>
					<div class="form-group col-md-4">
						<label for="comment-email">Email Address</label>
						<input type="email" class="form-control" id="comment-email" placeholder="Email Address">
					</div>
				</div>
				<div class="form-group">
					<label for="comment-content">Comment</label>
					<textarea class="form-control" id="comment-content" rows="6"></textarea>
				</div>
				<div class="form-group mb-0">
					<button type="submit" class="btn btn-primary mt-md-4 mt-2">Post Comment</button>
				</div>
			</form>
		</div>
	</div>
</div>
<app-block-space layout="before-footer"></app-block-space>

