import {Component, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import {ShopCategory} from "../../../core/interfaces/products/category";
import {CarouselComponent} from "ngx-owl-carousel-o";
import {OwlCarouselOConfig} from "ngx-owl-carousel-o/lib/carousel/owl-carousel-o-config";
import {BehaviorSubject, Observable} from "rxjs";
import {HomeService} from "../home.service";
import {ProductService} from "../../product/services/product.service";
import {GlobalService} from "../../../core/services/global.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ProductsContentItemModel} from "../../../core/interfaces/products/products-data.model";
import {AppConfig} from "../../../core/config/appConfig";
import {NavigationService} from "../../../core/services/navigation-service";
import {Base} from "../../../core/base";
import {takeUntil} from "rxjs/operators";

interface BlockZoneTab {
	name: string;
	value: number
}

@Component({
	selector: 'app-block-zone',
	templateUrl: './block-zone.component.html'
})
export class BlockZoneComponent extends Base implements OnInit {

	products: Observable<any[]>;
	children: ShopCategory[] = [];

	tabs:BlockZoneTab[] = [
		{name: 'TEXT_TAB_FEATURED', value: 0},
		{name: 'TEXT_TAB_BESTSELLERS', value: 1},
		{name: 'TEXT_TAB_TOP_RATED', value: 2}
	];

	showFeatured: boolean = true;
	showBestSellers: boolean;
	showTopRated: boolean;
	featuredProducts: ProductsContentItemModel[] = [];
	topRatedProducts: ProductsContentItemModel[] = [];
	bestsellersProducts: ProductsContentItemModel[] = [];
	get currentTab(): any { return this.currentTab$.value; }
	currentTab$!: BehaviorSubject<BlockZoneTab>;
	carouselOptions!: Partial<OwlCarouselOConfig>;
	loading: boolean;
	shop: any;

	imagePath = AppConfig.imagePath;

	@Input() image: any = '';

	@Input() mobileImage: string = '';

	@Input() category: ShopCategory|null = null;

	@HostBinding('class.block') classBlock = true;

	@HostBinding('class.block-zone') classBlockZone = true;

	@ViewChild(CarouselComponent) carousel!: CarouselComponent;

	@HostBinding('class.block-zone--loading') get classBlockSaleLoading() { return this.loading; }

	constructor(private homeService: HomeService,
				private productService: ProductService,
				private globalService: GlobalService,
				private router: Router,
				private translate: TranslateService,
				private navigationService: NavigationService) {
		super();
	}

	ngOnInit(): void {
		this.shop = localStorage.getItem('shop') != 'undefined' ? JSON.parse(localStorage.getItem('shop')) : {};
		this.initOptions();
		this.getFeaturedProducts();
		this.getBestSellersProducts();
		this.getTopRatedProducts();
		this.currentTab$ = new BehaviorSubject<BlockZoneTab>(this.tabs[0]);
	}

	setCurrentTab(tab): void {
		this.currentTab$.next(tab);

		switch (tab.value) {
			case 0:
				this.showFeatured = true;
				this.showBestSellers = false;
				this.showTopRated = false;
				break;
			case 1:
				this.showFeatured = false;
				this.showBestSellers = true;
				this.showTopRated = false;
				break;
			case 2:
				this.showFeatured = false;
				this.showBestSellers = false;
				this.showTopRated = true;
				break;
		}
	}

	goToCategory(event) {
		this.productService.goToCategory({ categories: event.id });
		this.productService.changeCategoryId.next(event);
	}

	private getFeaturedProducts(): void {
		this.loading = true;
		this.globalService.getHomePageAllProducts({category: this.category.id, type: 'featured', shopId: this.shop?.id}).pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.featuredProducts = res.payload.content;
			this.loading = false;
		})
	}

	private getBestSellersProducts(): void {
		this.loading = true;
		this.globalService.getHomePageAllProducts({category: this.category.id ,type: 'best-seller', shopId: this.shop?.id}).pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.bestsellersProducts = res.payload.content;
			this.loading = false;
		})
	}

	private getTopRatedProducts(): void {
		this.loading = true;
		this.globalService.getHomePageAllProducts({category: this.category.id ,type: 'top-rated', shopId: this.shop?.id}).pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.topRatedProducts = res.payload.content;
			this.loading = false;
		})
	}

	initOptions(): void {
		this.carouselOptions = {
			dots: false,
			margin: 20,
			loop: true,
			items: 4,
			responsive: {
				1020: { items: 4, margin: 20 },
				630: { items: 4, margin: 16 },
				410: { items: 2, margin: 16 },
				0: { items: 1 },
			},
		};
	}

	goToProduct(id) {
		this.navigationService.goToProduct(id);
	}

}
