import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-block-features',
	templateUrl: './block-features.component.html'
})
export class BlockFeaturesComponent implements OnInit {

	@Input() layout = 'top-strip';

	@HostBinding('class.block') classBlock = true;

	@HostBinding('class.block-features') classBlockFeatures = true;

	@HostBinding('class.block-features--layout--top-strip') get classBlockFeaturesLayoutTopStrip(): boolean {
		return this.layout === 'top-strip';
	}

	@HostBinding('class.block-features--layout--bottom-strip') get classBlockFeaturesLayoutBottomStrip(): boolean {
		return this.layout === 'bottom-strip';
	}

	constructor() {
	}

	ngOnInit(): void {
	}

}
