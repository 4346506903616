<div class="container">
	<ul class="block-features__list">
		<li class="block-features__item">
			<div class="block-features__item-icon">
				<app-icons icon="fi-free-delivery-48"></app-icons>
			</div>
			<div class="block-features__item-info">
				<div class="block-features__item-title">{{ 'TEXT_SHOP_FEATURE_FREE_SHIPPING_TITLE'|translate }}</div>
				<div class="block-features__item-subtitle">{{ 'TEXT_SHOP_FEATURE_FREE_SHIPPING_SUBTITLE'|translate }}</div>
			</div>
		</li>
		<li class="block-features__item">
			<div class="block-features__item-icon">
				<app-icons icon="fi-24-hours-48"></app-icons>
			</div>
			<div class="block-features__item-info">
				<div class="block-features__item-title">{{ 'TEXT_SHOP_FEATURE_SUPPORT_TITLE'|translate }}</div>
				<div class="block-features__item-subtitle">{{ 'TEXT_SHOP_FEATURE_SUPPORT_SUBTITLE'|translate }}</div>
			</div>
		</li>
		<li class="block-features__item">
			<div class="block-features__item-icon">
				<app-icons icon="fi-payment-security-48"></app-icons>
			</div>
			<div class="block-features__item-info">
				<div class="block-features__item-title">{{ 'TEXT_SHOP_FEATURE_SECURITY_TITLE'|translate }}</div>
				<div class="block-features__item-subtitle">{{ 'TEXT_SHOP_FEATURE_SECURITY_SUBTITLE'|translate }}</div>
			</div>
		</li>
		<li class="block-features__item">
			<div class="block-features__item-icon">
				<app-icons icon="fi-tag-48"></app-icons>
			</div>
			<div class="block-features__item-info">
				<div class="block-features__item-title">{{ 'TEXT_SHOP_FEATURE_HOT_OFFERS_TITLE'|translate }}</div>
				<div class="block-features__item-subtitle">{{ 'TEXT_SHOP_FEATURE_HOT_OFFERS_SUBTITLE'|translate }}</div>
			</div>
		</li>
	</ul>
</div>
