import {
	Component,
	EventEmitter,
	HostBinding,
	Inject,
	Input,
	OnInit,
	Output,
	PLATFORM_ID,
	SimpleChanges
} from '@angular/core';
import {ShopSidebarService} from "../../services/sidebar.service";
import {takeUntil} from "rxjs/operators";
import {isPlatformBrowser} from "@angular/common";
import {Observable, Subject} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {Options} from "@angular-slider/ngx-slider";

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

	@Input() form: FormGroup;
	@Input() category: any;
	@Input() options: Options;
	@Input() hasLoadedProducts: boolean;

	private destroy$: Subject<void> = new Subject<void>();
	@Input() offcanvas: 'always' | 'mobile' = 'always';

	@HostBinding('class.sidebar') classSidebar = true;

	@HostBinding('class.sidebar--offcanvas--always') get classSidebarOffcanvasAlways(): boolean {
		return this.offcanvas === 'always';
	}

	@HostBinding('class.sidebar--offcanvas--mobile') get classSidebarOffcanvasMobile(): boolean {
		return this.offcanvas === 'mobile';
	}

	@HostBinding('class.sidebar--open') get classSidebarOpen(): boolean {
		return this.sidebar.isOpen;
	}


	constructor(public sidebar: ShopSidebarService,
				@Inject(PLATFORM_ID) private platformId: any) {
		this.sidebar.isOpen$.pipe(
			takeUntil(this.destroy$),
		).subscribe(isOpen => {
			if (isOpen) {
				this.open();
			} else {
				this.close();
			}
		});

		if (isPlatformBrowser(this.platformId)) {
			this.fromMatchMedia('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe(media => {
				if (this.offcanvas === 'mobile' && this.sidebar.isOpen && !media.matches) {
					this.sidebar.close();
				}
			});
		}
		``
	}

	ngOnInit(): void {
	}

	ngOnChanges(simpleChanges: SimpleChanges): void {
		this.category = simpleChanges.category?.currentValue;
	}

	private open(): void {
		if (isPlatformBrowser(this.platformId)) {
			const bodyWidth = document.body.offsetWidth;

			document.body.style.overflow = 'hidden';
			document.body.style.paddingRight = (document.body.offsetWidth - bodyWidth) + 'px';
		}
	}

	private close(): void {
		if (isPlatformBrowser(this.platformId)) {
			document.body.style.overflow = '';
			document.body.style.paddingRight = '';
		}
	}

	fromMatchMedia(query: string, skipFirst = true): Observable<MediaQueryList> {
		return new Observable(observer => {
			const mediaQueryList = matchMedia(query);

			const onChange = () => observer.next(mediaQueryList);

			if (!skipFirst) {
				onChange();
			}

			if (mediaQueryList.addEventListener) {
				mediaQueryList.addEventListener('change', onChange);

				return () => mediaQueryList.removeEventListener('change', onChange);
			} else {
				mediaQueryList.addListener(onChange);

				return () => mediaQueryList.removeListener(onChange);
			}
		});
	}

}
