<ng-container *ngIf="isEmpty && !isLoading">

	<div class="block-empty" style="margin-top: 3rem">
		<div class="container">
			<div class="block-empty__body">
				<h1 class="block-empty__title"
					style="font-weight: bold">{{ 'HEADER_SHOPPING_CART_EMPTY_TITLE'|translate }}</h1>
				<div
					class="block-empty__message"
					[innerHTML]="'HEADER_SHOPPING_CART_EMPTY_SUBTITLE'|translate">
				</div>
				<div class="block-empty__action">
					<a routerLink="/" class="btn btn-primary btn-sm">{{ 'BUTTON_GO_TO_HOMEPAGE'|translate }}</a>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<div *ngIf="!isEmpty && !isLoading" class="block-header block-header--has-title block-header--has-breadcrumb">
	<div class="container">
		<div class="block-header__body">
			<div class="block-header__breadcrumb breadcrumb ng-star-inserted"></div>
			<h1 class="block-header__title"
				style="margin: 0 auto; font-weight: bold; font-size: 2.25rem">{{'HEADER_SHOPPING_CART' | translate}}</h1>
		</div>
	</div>
	<div class="block">
		<div class="container">
			<div class="row">
				<div class="col-12 col-lg-3 d-flex">
					<div class="account-nav flex-grow-1">
						<ul class="account-nav__list p-3">
							<li class="account-nav__item gap-3 d-flex justify-content-between"
								[ngStyle]="listId === lists.id ? { background: '#f2f2f2' } : ''"
								*ngFor="let lists of shoppingCarts; let i = index">
								<a class="truncate" (click)="getCardById(lists.id)">
									<span>{{lists?.supplier?.companyName}}</span>
									<div class="dropcart__item-price">{{lists?.cartTotal | number: '1.0-2'}} ₽</div>
								</a>
								<button mat-icon-button [matMenuTriggerFor]="menu">
									<mat-icon>more_vert</mat-icon>
								</button>

								<mat-menu #menu="matMenu">
									<button mat-menu-item (click)="removeCart(lists.id)">
										<mat-icon>delete</mat-icon>
										<span>{{'DELETE_CART' | translate}}</span>
									</button>
								</mat-menu>
							</li>
						</ul>
					</div>
				</div>
				<div class="col-12 col-lg-9 mt-4 mt-lg-0">
					<ng-container>
						<div class="block">
							<div class="container container--max--xl">
								<div class="wishlist">
									<table class="wishlist__table">
										<thead class="wishlist__head">
										<tr class="wishlist__row wishlist__row--head">
											<th class="cart-table__column cart-table__column&#45;&#45;image"></th>
											<th class="cart-table__column cart-table__column--product">{{ 'TABLE_PRODUCT'|translate }}</th>
											<th class="cart-table__column cart-table__column--price">{{ 'TABLE_PRICE'|translate }}</th>
											<th class="cart-table__column cart-table__column--quantity">{{ 'TABLE_QUANTITY'|translate }}</th>
											<!--											<th class="cart-table__column cart-table__column&#45;&#45;quantity">{{ 'INPUT_UNIT_LABEL'|translate }}</th>-->
											<th class="cart-table__column cart-table__column--total">{{ 'TABLE_REWARD'|translate }}</th>
											<th class="cart-table__column cart-table__column--total">{{ 'INPUT_DISCOUNT_LABEL'|translate }}</th>
											<th class="cart-table__column cart-table__column--total">{{ 'TABLE_TOTAL'|translate }}</th>
											<th class="cart-table__column cart-table__column--remove"></th>
										</tr>
										</thead>
										<tbody class="cart-table__body">
										<tr *ngFor="let item of cardItems; index as i" class="cart-table__row">
											<td class="cart-table__column cart-table__column--image">
												{{ i + 1 }}
											</td>
											<td class="cart-table__column cart-table__column--image">
												<div class="image image--type--product">
													<a (click)="goToProduct(item.product.id)" class="image__body">
														<img class="image__tag"
															 [src]="item.product.gallery.length ? imagePath + item.product.gallery[0]?.thumbnail?.url : 'assets/images/categories/category-1.jpg'"
															 alt="">
													</a>
												</div>
											</td>
											<td class="cart-table__column cart-table__column--product">
												<a (click)="goToProduct(item.product.id)"
												   class="cart-table__product-name">{{ item?.product.productName }}</a>
												<ul class="cart-table__options">
													<li>{{ item?.properties?.unit?.name }} (x{{item?.properties?.unit?.contents}})</li>
												</ul>
											</td>
											<td class="cart-table__column cart-table__column--price"
												[attr.data-title]="'TABLE_PRICE'|translate">
												{{ item?.price}} ₽
											</td>
											<td class="cart-table__column cart-table__column--quantity"
												[attr.data-title]="'TABLE_QUANTITY'|translate">
												<div class="input-number">
													<input
														onlyNumber
														(input)="steQuantityByKeyDown(i, $event.target.value, item.id)"
														oninput="this.value = Math.abs(this.value)"
														min=1
														class="input-number__input form-control"
														type="number"
														[value]="item?.quantity">
													<div class="input-number__add"
														 (click)="plus(i, item?.quantity, item.id)"></div>
													<div class="input-number__sub"
														 (click)="minus(i, item?.quantity, item.id)"></div>
												</div>
											</td>
											<!--											<td class="cart-table__column cart-table__column&#45;&#45;quantity"-->
											<!--												[attr.data-title]="'INPUT_UNIT_LABEL'|translate">-->
											<!--												{{item?.properties}}-->
											<!--											</td>-->
											<td class="cart-table__column cart-table__column--total"
												style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;"
												[attr.data-title]="'TABLE_REWARD'|translate">
												{{ (item?.reward * item?.quantity * (+item?.properties?.unit?.contents)) | number: '1.0-2'}}<span style="color: red"> B</span>
											</td>
											<td class="cart-table__column cart-table__column--total"
												[attr.data-title]="'INPUT_DISCOUNT_LABEL'|translate">
												{{ item?.discount || 0 }}%
											</td>
											<td class="cart-table__column cart-table__column--total"
												style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;"
												[attr.data-title]="'TABLE_TOTAL'|translate">
												{{ (item?.price * ((100 - item?.discount) / 100)) * item?.quantity * (+item?.properties?.unit?.contents) | number: '1.0-2'}} ₽
											</td>
											<td class="cart-table__column cart-table__column--remove">
												<button
													(click)="removeProduct(item?.id)"
													type="button"
													class="cart-table__remove btn btn-sm btn-icon btn-muted"
												>
													<span>X</span>
												</button>
											</td>
										</tr>
										</tbody>
										<tfoot class="cart-table__foot">
										<tr>
	
											<td colspan="9">
												<div class="cart-table__actions">
													<form class="cart-table__coupon-form form-row">
														<div class="form-group mb-0 col flex-grow-1">
															<mat-error
																*ngIf="isLow">{{'ORDER_SUM_IS_LOW' | translate}}{{' ' + supplier.supplierSettings.minOrderSum}}
															</mat-error>
														</div>
														<div class="form-group mb-0 col-auto">
														</div>
													</form>
													<div class="cart-table__update-button">
														<button
															type="button"
															class="btn btn-sm btn-primary"
															[disabled]="!needUpdate"
															(click)="updateCard()"
														>
															{{ 'BUTTON_UPDATE_CART'|translate }}
														</button>
													</div>
												</div>
											</td>
										</tr>
										</tfoot>
									</table>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="block-space block-space--layout--before-footer"></div>
			<div class="d-flex flex-row justify-content-between">
				<div></div>
				<div class="cart__totals">
					<div class="card">
						<div class="card-body card-body--padding--2">
							<h2 class="card-title">{{ 'HEADER_CART_TOTALS'|translate }}</h2>

							<table class="cart__totals-table">
								<tbody>
								<tr>
									<th>{{'SUPPLIER_NAME' | translate}}</th>
									<td>{{supplier?.companyName}}</td>
								</tr>
								<tr>
									<th>{{'SHOP' | translate}}</th>
									<td>{{shop?.shopName}}</td>
								</tr>
								<tr>
									<th>{{'TOTAL_REWARD' | translate}}</th>
									<td>{{totalReward | number: '1.0-2'}}</td>
								</tr>
								<tr>
									<th>{{'TOTAL_PRICE' | translate}}</th>
									<td>{{cartTotal | number: '1.0-2'}} ₽</td>
								</tr>
								</tbody>
							</table>

							<div class="d-flex flex-row justify-content-end">
								<button class="btn btn-primary mt-3" (click)="review()" [disabled]="needUpdate || isLow">
									{{'REVIEW' | translate}}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="block-space block-space--layout--before-footer"></div>
<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
