import {Component, EventEmitter, HostBinding, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ProductService} from "../../../services/product.service";
import {TranslateService} from "@ngx-translate/core";
import {Base} from "../../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {
	CategoriesContentItemModel,
	GetCategoriesResModel
} from "../../../../../core/interfaces/categories/categories.model";
import {ApiResultModel} from "../../../../../core/interfaces/api-result.model";
import {GlobalService} from "../../../../../core/services/global.service";

@Component({
	selector: 'app-filter-category',
	templateUrl: './filter-category.component.html'
})
export class FilterCategoryComponent extends Base implements OnInit {
	category: CategoriesContentItemModel;
	id: number;
	parent: string;
	@HostBinding('class.filter-category') classFilterCategories = true;
	@Input() options;
	@Output() changedCategory = new EventEmitter;
	categories: CategoriesContentItemModel[] = [];
	showAllCategories: boolean;

	constructor(private productService: ProductService,
				public translate: TranslateService,
				public globalService: GlobalService,
				public activatedRoute: ActivatedRoute) {
		super();
	}

	ngOnInit(): void {
		this.productService.category.pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.category = res;
		})
	}

	private getCategoriesById(id): void {
			this.globalService.getCategoriesById(id).pipe(takeUntil(this.destroy$)).subscribe((res: ApiResultModel<CategoriesContentItemModel>) => {
				this.showAllCategories = false;
				this.category = res.payload;
			})
	}

	getAllCategories(): void {
		this.showAllCategories = true;
		this.productService.changeCategoryId.next('');
		this.productService.onShowAllCategories.next(true);
		this.globalService.getCategories().pipe(takeUntil(this.destroy$)).subscribe((res: ApiResultModel<GetCategoriesResModel>) => {
			this.categories = res.payload.content;
		})
	}

	goToCategory(id): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(query => {
			query = {
				...query,
				categories:id
			}
			this.productService.goToCategory(query);
			this.productService.changeCategoryId.next(id);
		})
		this.getCategoriesById(id);
	}

}
