<div *ngIf="gallery" style="padding: 2rem;" class="product-gallery product-gallery--layout--product-sidebar">
<!--	<div class="product-gallery__featured">-->
<!--		<div class="image image&#45;&#45;type&#45;&#45;product">-->
<!--			<div *ngIf="gallery.length" class="image__body">-->
<!--				<img class="image__tag" width="100%" [src]="gallery.length ? (imagePath + gallery[0].filename) : ''">-->
<!--			</div>-->
<!--		</div>-->
<!--	</div>-->
</div>

<div style="padding: 15px;">
	<!--	<div>-->
	<!--		<div class="dropzone" (filesChangeEmiter)="onFileChange($event)">-->
	<!--			<div class="text-wrapper">-->
	<!--				<div class="centered">-->
	<!--					<input type="file" name="file" id="files" (change)="onFileChange($event.target.files)" multiple>-->
	<!--					<label for="files"><span class="textLink">Select your gallery</span> or Drop there here!</label>-->
	<!--				</div>-->
	<!--			</div>-->
	<!--		</div>-->
	<!--	</div>-->
	<!--	<hr>-->
	<h3 mat-subheader>{{ (label ? label : 'GALLERY') | translate }}</h3>
	<div
		class="example-container"
		cdkDropListGroup
	>
		<div
			cdkDropList
			(cdkDropListEntered)="onDropListEntered($event)"
			(cdkDropListDropped)="onDropListDropped()"
			class="d-flex"
		></div>
		<div *ngIf="gallery?.length" class="d-flex">
			<div
				cdkDropList
				(cdkDropListEntered)="onDropListEntered($event)"
				(cdkDropListDropped)="onDropListDropped()"
				*ngFor="let item of gallery; let ind = index"
			>
				<div cdkDrag
					 class="product-gallery__thumbnails-item image image--type--product example-box"
					 [class.product-gallery__thumbnails-item--active]="slide === currentItem"
				>
					<div class="delete_button" (click)="openConfirmDialog(item, ind);">
						<mat-icon>delete_forever</mat-icon>
					</div>
					<div class="image__body">
						<img class="image__tag" [src]="(imagePath) + item.filename" alt="">
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="imageUrl?.length">
			<div
				cdkDropList
				(cdkDropListEntered)="onDropListEntered($event)"
				(cdkDropListDropped)="onDropListDropped()"
				*ngFor="let url of imageUrl"
			>
				<div cdkDrag
					 class="product-gallery__thumbnails-item image image--type--product example-box"
					 [class.product-gallery__thumbnails-item--active]="slide === currentItem"
				>
					<div class="delete_button" (click)="openConfirmDialog(item, ind);">
						<mat-icon>delete_forever</mat-icon>
					</div>
					<div class="image__body">
						<img class="image__tag" [src]="url" >
					</div>
				</div>
			</div>
		</div>

		<div class="example-box">
			<div class="" (filesChangeEmiter)="onFileChange($event)">
				<input type="file" name="file" id="files" (change)="onFileChange($event.target.files)" multiple hidden>
				<label for="files" class="btn btn-primary btn-sm">{{ 'UPLOAD' | translate }}</label>
			</div>
		</div>
	</div>
</div>


