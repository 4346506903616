import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	Input, OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {OwlCarouselOConfig} from "ngx-owl-carousel-o/lib/carousel/owl-carousel-o-config";
import {
	SectionHeaderGroup,
	SectionHeaderLink
} from "../../../shared/components/section-header/section-header.component";
import {CarouselComponent} from "ngx-owl-carousel-o";
import {GlobalService} from "../../../core/services/global.service";
import {NavigationService} from "../../../core/services/navigation-service";

export type BlockProductsCarouselLayout = 'grid-4' | 'grid-4-sidebar' | 'grid-5' | 'grid-6' | 'horizontal' | 'horizontal-sidebar';

const carouselLayoutOptions = {
	'grid-4': {
		items: 4,
		responsive: {
			1110: { items: 4 },
			930: { items: 4, margin: 16 },
			690: { items: 3, margin: 16 },
			430: { items: 2, margin: 16 },
			0: { items: 1 },
		},
	},
	'grid-4-sidebar': {
		items: 4,
		responsive: {
			1040: { items: 4 },
			818: { items: 3 },
			638: { items: 3, margin: 16 },
			430: { items: 2, margin: 16 },
			0: { items: 1 },
		},
	},
	'grid-5': {
		items: 5,
		responsive: {
			1350: { items: 5 },
			1110: { items: 4 },
			930: { items: 4, margin: 16 },
			690: { items: 3, margin: 16 },
			430: { items: 2, margin: 16 },
			0: { items: 1 },
		},
	},
	'grid-6': {
		items: 6,
		margin: 16,
		responsive: {
			1350: { items: 6 },
			1110: { items: 6 },
			930: { items: 4, margin: 16 },
			690: { items: 3, margin: 16 },
			430: { items: 2, margin: 16 },
			0: { items: 1 },
		},
	},
	horizontal: {
		items: 4,
		responsive: {
			1350: { items: 4, margin: 14 },
			930: { items: 3, margin: 14 },
			690: { items: 2, margin: 14 },
			0: { items: 1, margin: 14 },
		},
	},
	'horizontal-sidebar': {
		items: 3,
		responsive: {
			1040: { items: 3, margin: 14 },
			638: { items: 2, margin: 14 },
			0: { items: 1, margin: 14 },
		},
	},
};

export type ProductCardLayout = 'grid' | 'list' | 'table' | 'horizontal';
export type ProductCardElement = 'actions' | 'status-badge' | 'meta' | 'features' | 'buttons' | 'list-buttons';

const productCardLayoutMap: {[K in BlockProductsCarouselLayout]: ProductCardLayout} = {
	'grid-4': 'grid',
	'grid-4-sidebar': 'grid',
	'grid-5': 'grid',
	'grid-6': 'grid',
	horizontal: 'horizontal',
	'horizontal-sidebar': 'horizontal',
};

const productCardExcludeMap: {[K in ProductCardLayout]: ProductCardElement[]} = {
	grid: ['features', 'list-buttons'],
	list: [],
	table: [],
	horizontal: ['actions', 'status-badge', 'features', 'buttons', 'meta'],
};

@Component({
	selector: 'app-block-products',
	templateUrl: './block-products.component.html'
})
export class BlockProductsComponent implements OnInit, OnChanges {

	showCarousel = true;

	columns = [];

	carouselOptions!: Partial<OwlCarouselOConfig>;

	@Input() featuredProducts: any = [];

	@Input() blockTitle: string = '';

	@Input() @HostBinding('attr.data-layout') layout: BlockProductsCarouselLayout = 'grid-4';

	@Input() rows = 1;

	@Input() groups: SectionHeaderGroup[] = [];

	@Input() currentGroup?: SectionHeaderGroup

	@Input() links: SectionHeaderLink[] = [];

	@Input() loading = false;

	@Output() changeGroup: EventEmitter<SectionHeaderGroup> = new EventEmitter<SectionHeaderGroup>();

	@HostBinding('class.block') classBlock = true;

	@HostBinding('class.block-products-carousel') classBlockProductsCarousel = true;

	@ViewChild(CarouselComponent) carousel!: CarouselComponent;

	get productCardLayout(): ProductCardLayout {
		return productCardLayoutMap[this.layout];
	}

	get productCardExclude(): ProductCardElement[] {
		return productCardExcludeMap[this.productCardLayout];
	}

	constructor(private cd: ChangeDetectorRef,
				private navigationService: NavigationService) {
	}

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		const properties = Object.keys(changes);

		if (properties.includes('products') || properties.includes('row')) {
			this.columns = [];

			if (this.featuredProducts && this.rows > 0) {
				const products = this.featuredProducts.slice();

				while (products.length > 0) {
					this.columns.push(products.splice(0, this.rows));
				}
			}
		}

		if (changes['products']) {
			setTimeout(() => {
				this.initOptions();

				this.showCarousel = false;
				this.cd.detectChanges();
				this.showCarousel = true;
			}, 0);
		}

		if (changes['layout']) {
			this.initOptions();
		}
	}

	initOptions(): void {
		this.carouselOptions = Object.assign({
			dots: false,
			margin: 20,
			loop: false,
			autoWidth: true
		}, carouselLayoutOptions[this.layout]);
	}

	goToProduct(id) {
		this.navigationService.goToProduct(id);
	}

}
