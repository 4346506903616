<div class="filter-range__slider" *ngIf="options.floor  && options.ceil">
	<div class="ngx-slider-custom">
		<ngx-slider
			[formControl]="control"
			[options]="{
                animate: false,
                mouseEventsInterval: 10,
                floor: options.floor,
                ceil: options.ceil,
                step: 1
            }"
					#slider></ngx-slider>
	</div>
	<div class="filter-range__title-button">
		<div class="filter-range__title">
			<span class="filter-range__min-value">{{ slider.value }}</span> –
			<span class="filter-range__max-value">{{ slider.highValue }} ₽</span>
		</div>
	</div>
</div>
