import {
	Component, EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {AuthService} from "../../../auth/auth.service";
import {AccountMenu} from "../../../../core/data/account-menu";
import {Base} from "../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {CustomerAuthService} from "../../../auth/customer-auth/customer-auth.service";
import {Observable} from "rxjs";

@Component({
	selector: 'app-dashboard-menu',
	templateUrl: './dashboard-menu.component.html'
})
export class DashboardMenuComponent extends Base implements OnInit {
	@Input() menuClass: string;
	@Input() showTitle: boolean;
	@Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();
	customerMenuItems = AccountMenu.customerMenuItems;
	edit: boolean;
	customerId: string;

	constructor(public authService: AuthService,
				public customerAuthService: CustomerAuthService) {
		super();
	}

	get isCreated() {
		return this.authService.isCreated();
	}

	ngOnInit(): void {
		this.authService.getAuth().pipe(takeUntil(this.destroy$)).subscribe({
			next: (res) => {
				this.customerId = res.payload?.customer?.id;
			}
		})
	}

	logout(): void {
		this.authService.logOut();
	}
}
