<div class="container">
	<app-section-header
		[sectionTitle]="label"
		[arrows]="true"
		[links]="links"
		(next)="carousel.next()"
		(prev)="carousel.prev()"
	></app-section-header>

	<div *ngIf="posts?.length < 1" class="products-view__empty">
		<div class="products-view__empty-title">
			{{ 'TEXT_NO_MATCHING_ITEMS_TITLE'|translate }}
		</div>
	</div>

	<div
		*ngIf="showCarousel"
		class="block-posts-carousel__carousel"
		[class.block-posts-carousel__carousel--has-items]="posts?.length"
	>
		<div class="block-posts-carousel__carousel-loader"></div>
		<owl-carousel-o [options]="carouselOptions" appOwlPreventClick>
			<ng-template *ngFor="let post of posts" carouselSlide>
				<div class="block-posts-carousel__item">
					<div class="post-card" (click)="goToPostPage(post)">
						<div class="post-card__image">
							<a routerLink="/blog/post-full-width">
								<img [src]="post?.mainImage ? (imagePath + post?.mainImage?.filename) : 'assets/images/posts/post-1.jpg'" alt="">
							</a>
						</div>
						<div class="post-card__content">
							<div class="post-card__category">
								<span>{{'₽' + post.price}}</span>
							</div>
							<div class="post-card__title">
								<h2><a routerLink="/blog/post-full-width">{{ post.name }}</a></h2>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</owl-carousel-o>
	</div>
</div>
