import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AppConfig} from "../../../core/config/appConfig";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable, Subject, timer} from "rxjs";
import {ApiResultModel} from "../../../core/interfaces/api-result.model";
import {
	CustomerCreateEditReqModel,
	CustomerCreateEditResModel, CustomerModel, CustomerSettingModel
} from "../../../core/interfaces/users/customer/customer.model";
import {GetPartnerShipsListModel} from "../../../core/interfaces/users/partnership.model";
import {CreateEditShopReqModel, ShopModel} from "../../../core/interfaces/shop.model";
import {map, takeUntil, tap} from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class CustomerDashboardService {
	private abortPrevious$: Subject<void> = new Subject<void>();
	private destroy$: Subject<void> = new Subject();
	private showPopupSubject$: Subject<any> = new Subject();

	showPartnershipPopupSubject$: Observable<any> = this.showPopupSubject$.pipe(takeUntil(this.destroy$));
	constructor(private http: HttpClient) {
	}

	customerPersonalDataForm(): FormGroup {
		return new FormGroup({
			firstName: new FormControl('', Validators.required),
			lastName: new FormControl('', Validators.required),
			fatherName: new FormControl(''),
			address: new FormGroup({
				country: new FormControl(),
				state: new FormControl(),
				city: new FormControl(''),
				address_1: new FormControl(''),
				address_2: new FormControl(''),
				postCode: new FormControl(''),
				phoneNumber1: new FormControl(''),
				phoneNumber2: new FormControl(''),
				gpsCoordinates: new FormGroup({
					latitude: new FormControl(''),
					longitude: new FormControl(''),
				})
			}),
			citizenship: new FormGroup({
				citizenship: new FormControl(''),
				passport: new FormControl(''),
				issueDate: new FormControl(''),
				issuedBy: new FormControl(''),
				passportPhoto: new FormControl([])
			}),
			inn: new FormControl(''),
			birthDate: new FormControl('', Validators.required),
			email: new FormControl('')
		})
	}

	createPersonalDataForm(): FormGroup {
		return new FormGroup({
			firstName: new FormControl('', Validators.required),
			lastName: new FormControl('', Validators.required),
			fatherName: new FormControl(''),
			address: new FormGroup({
				country: new FormControl('', Validators.required),
				state: new FormControl('', Validators.required),
				city: new FormControl('', Validators.required),
				address_1: new FormControl('', Validators.required),
				address_2: new FormControl(''),
				postCode: new FormControl(''),
				phoneNumber1: new FormControl(''),
				phoneNumber2: new FormControl(''),
				gpsCoordinates: new FormGroup({
					latitude: new FormControl(''),
					longitude: new FormControl(''),
				})
			})
		})
	}

	updateCustomerPersonalDataForm(form: FormGroup, data: any) {
		form.patchValue({
			firstName: data.payload.person.firstName,
			lastName: data.payload.person.lastName,
			fatherName: data.payload.person.fatherName,
			address: data.payload.person.address,
			citizenship: data.payload.person.citizenship,
			passport: data.payload.person.passport,
			issueDate: data.payload.person.issueDate,
			issuedBy: data.payload.person.issuedBy,
			inn: data.payload.person.inn,
			birthDate: data.payload.person.birthDate,
			email: data.payload.person.email
		})
	}

	settingsForm(): FormGroup {
		return new FormGroup({
			paymentMethod: new FormControl('', Validators.required),
			paymentMethodDetails: new FormControl('')
		})
	}

	updateSettingsForm(form: FormGroup, data: any): void {
		form.patchValue({
			paymentMethod: data.paymentMethod,
			paymentMethodDetails: data.paymentMethodDetails
		})
	}

	shopForm(): FormGroup {
		return new FormGroup({
			shopName: new FormControl('', Validators.required),
			companyName: new FormControl('', Validators.required),
			contactPersonName: new FormControl('', Validators.maxLength(150)),
			contactPersonPhone: new FormControl('', Validators.maxLength(15)),
			taxId: new FormControl('', Validators.required),
			legalAddress: new FormGroup({
				country: new FormControl(null),
				state: new FormControl(null),
				city: new FormControl(''),
				address_1: new FormControl(''),
				address_2: new FormControl(''),
				postCode: new FormControl(''),
				phoneNumber1: new FormControl(''),
				phoneNumber2: new FormControl(''),
				gpsCoordinates: new FormGroup({
					latitude: new FormControl(''),
					longitude: new FormControl(''),
				})
			}),
			deliveryAddress: new FormGroup({
				country: new FormControl('', Validators.required),
				state: new FormControl('', Validators.required),
				city: new FormControl(''),
				address_1: new FormControl(''),
				address_2: new FormControl(''),
				postCode: new FormControl(''),
				phoneNumber1: new FormControl(''),
				phoneNumber2: new FormControl(''),
				gpsCoordinates: new FormGroup({
					latitude: new FormControl(''),
					longitude: new FormControl(''),
				})
			})
		})
	}

	updateShopForm(form: FormGroup, data: any): void {
		form.patchValue({
			shopName: data.shopName,
			companyName: data.companyName,
			contactPersonName: data.contactPersonName,
			contactPersonPhone: data.contactPersonPhone,
			taxId: data.taxId,
			legalAddress: data.legalAddress,
			deliveryAddress: data.deliveryAddress
		})
	}

	createCustomerAccount(data: CustomerCreateEditReqModel | any): Observable<ApiResultModel<CustomerCreateEditResModel>> {
		return this.http.post<ApiResultModel<CustomerCreateEditResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.createAccount}`, data);
	}

	getCustomers(id: string): Observable<ApiResultModel<CustomerModel>> {
		return this.http.get<ApiResultModel<CustomerModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.getCustomer}${id}`);
	}

	updateCustomers(id: string, data: CustomerCreateEditReqModel): Observable<ApiResultModel<CustomerCreateEditResModel>> {
		return this.http.patch<ApiResultModel<CustomerCreateEditResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.editCustomer}${id}`, data);
	}

	deleteAccount() {
		return this.http.delete(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.deleteAccount}`);
	}

	sentRequests(shopId: any, page: number = 1, limit: number = 10, sort: string = 'p.createdAt', order = 'desc'): Observable<ApiResultModel<GetPartnerShipsListModel>> {
		let params = new HttpParams()
			.set("shopId", shopId)
			.set("page", page)
			.set("limit", limit)
			.set("orderBy", sort)
			.set("orderDirection", order);
		return this.http.get<ApiResultModel<GetPartnerShipsListModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.sendRequests}${params}`);
	}

	deleteShop(params: string): Observable<boolean> {
		return this.http.delete<boolean>(`${AppConfig.baseUrl}${AppConfig.endpoints.shops.deleteShop}${params}`);
	}

	getShopById(params: string): Observable<ApiResultModel<ShopModel>> {
		return this.http.get<ApiResultModel<ShopModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.shops.getShopsById}${params}`);
	}

	editShop(params: string, data: CreateEditShopReqModel): Observable<ApiResultModel<ShopModel>> {
		return this.http.patch<ApiResultModel<ShopModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.shops.editShop}${params}`, data);
	}

	updateCustomerSettings(data: CustomerSettingModel): Observable<ApiResultModel<CustomerSettingModel>> {
		return this.http.patch<ApiResultModel<CustomerSettingModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.updateSettings}`, data);
	}

	rewardSell(body: {reward: string, comment: string}): Observable<ApiResultModel<any>> {
		return this.http.post<ApiResultModel<any>>(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.rewardSell}`, body);
	}

	createShops(data: CreateEditShopReqModel): Observable<ApiResultModel<ShopModel>> {
		return this.http.post<any>(`${AppConfig.baseUrl}${AppConfig.endpoints.shops.createShops}`, data);
	}

	show(partnership: any): Observable<void> {
		this.abortPrevious$.next();
		return timer(350).pipe(
			tap(() => this.showPopupSubject$.next(partnership.id)),
			map(() => {}),
			takeUntil(this.abortPrevious$),
		);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
