<div class="section-header__body">
	<h2 *ngIf="sectionTitle" class="section-header__title">{{ sectionTitle }}</h2>

	<div class="section-header__spring"></div>

	<ul *ngIf="!groups.length && links.length" class="section-header__links">
		<li *ngFor="let link of links" class="section-header__links-item">
			<a [routerLink]="link.url" class="section-header__links-link">{{ link.label | translate }}</a>
		</li>
	</ul>

	<ul *ngIf="groups.length" class="section-header__groups">
		<li *ngFor="let group of groups" class="section-header__groups-item">
			<button
				type="button"
				class="section-header__groups-button"
				[ngClass]="{'section-header__groups-button--active': group === currentGroup}"
				(click)="onGroupClick(group)"
			>
				{{ group.label }}
			</button>
		</li>
	</ul>

	<div *ngIf="arrows" class="section-header__arrows">
		<button class="arrow__button" type="button" (click)="prev.emit()">
			<app-icons icon="arrow-rounded-left-7x11"></app-icons>
		</button>
		<button class="arrow__button" type="button" (click)="next.emit()">
			<app-icons icon="arrow-rounded-right-7x11"></app-icons>
		</button>
	</div>

	<div class="section-header__divider"></div>
</div>

