import {
	AfterContentInit,
	Component,
	forwardRef,
	HostBinding,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges
} from '@angular/core';
import {Options} from "@angular-slider/ngx-slider";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {debounceTime, filter, takeUntil, tap} from "rxjs/operators";
import {Base} from "../../../../../core/base";

@Component({
	selector: 'app-filter-range',
	templateUrl: './filter-range.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FilterRangeComponent),
			multi: true,
		},
	],
})
export class FilterRangeComponent extends Base implements OnInit, ControlValueAccessor, OnChanges, AfterContentInit {
	control: FormControl;
	value: [number, number];
	debouncedValue: [number, number] | null = null;
	@HostBinding('class.filter-range') classFilterPrice = true;
	changeFn: (_: [number, number]) => void = () => {
	};

	touchedFn: () => void = () => {
	};

	@Input() options: Options;

	constructor() {
		super();
	}

	ngOnInit(): void {
	}

	ngAfterContentInit() {
		this.changeValue();
	}

	ngOnChanges(changes: SimpleChanges) {

		this.value = [changes.options.currentValue.floor, changes.options.currentValue.ceil];
		this.changeValue()
		this.writeValue(this.value)
	}

	changeValue() {
		this.value = [this.options.floor, this.options.ceil];

		this.control = new FormControl(this.value);
		this.control.valueChanges.pipe(
			filter(value => value[0] !== this.value[0] || value[1] !== this.value[1]),
			tap(value => {
				return this.debouncedValue = value
			}),
			debounceTime(500),
			takeUntil(this.destroy$)
		).subscribe(value => {
			this.debouncedValue = null;
			this.changeFn(value);
			this.touchedFn();
		});
	}

	registerOnChange(fn: any): void {
		this.changeFn = fn;
	}

	registerOnTouched(fn: any): void {
		this.touchedFn = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		if (isDisabled) {
			this.control.disable({emitEvent: false});
		} else {
			this.control.enable({emitEvent: false});
		}
	}

	writeValue(value: any): void {
		if (this.debouncedValue !== null) {
			return;
		}

		this.value = value;
		this.control.setValue(this.value, {emitEvent: false});
	}
}
