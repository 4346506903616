import {NgModule} from '@angular/core';
import {CustomerAuthRoutingModule} from "./customer-auth-routing.module";
import {CustomerLoginComponent} from './customer-login/customer-login.component';
import {CustomerAuthComponent} from "./customer-auth.component";
import {CustomerAuthService} from "./customer-auth.service";
import {TranslateModule} from "@ngx-translate/core";
import {NgMaterialModule} from "../../../ng-material.module";
import {CountdownModule} from "ngx-countdown";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../../../shared/shared.module";
import {CustomerVerificationComponent} from './customer-verification/customer-verification.component';

@NgModule({
	imports: [
		CustomerAuthRoutingModule,
		TranslateModule,
		NgMaterialModule,
		CountdownModule,
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule
	],
	exports: [],
	declarations: [
		CustomerLoginComponent,
		CustomerAuthComponent,
		CustomerVerificationComponent
	],
	providers: [
		CustomerAuthService
	],
})
export class CustomerAuthModule {
}
