import {Component, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import {OwlCarouselOConfig} from "ngx-owl-carousel-o/lib/carousel/owl-carousel-o-config";
import {CarouselComponent} from "ngx-owl-carousel-o";

@Component({
	selector: 'app-block-sale',
	templateUrl: './block-sale.component.html'
})
export class BlockSaleComponent implements OnInit {

	showCarousel = true;

	carouselOptions!: Partial<OwlCarouselOConfig>;

	@Input() loading = false;

	@Input() saleProducts: [] = [];

	@HostBinding('class.block') classBlock = true;

	@HostBinding('class.block-sale') classBlockSale = true;

	@HostBinding('class.block-sale--loading') get classBlockSaleLoading() { return this.loading; }

	@ViewChild(CarouselComponent) carousel!: CarouselComponent;

	constructor() {
	}

	ngOnInit(): void {
		this.initOptions();
	}

	initOptions(): void {
		this.carouselOptions = {
			items: 5,
			dots: false,
			margin: 16,
			loop: false,
			responsive: {
				1350: { items: 6, margin: 16 },
				1110: { items: 5, margin: 16 },
				930: { items: 4, margin: 16 },
				690: { items: 3, margin: 16 },
				410: { items: 2, margin: 16 },
				0: { items: 1 },
			},
		};
	}

}
