import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppConfig} from "../../core/config/appConfig";
import {ProductCategoryListComponent} from "./components/product-category-list/product-category-list.component";
import {ProductItemComponent} from "./components/product-item/product-item.component";
import {CategoryListComponent} from "./components/category-list/category-list.component";

const routes: Routes = [
	{path: '', pathMatch: 'full', redirectTo: AppConfig.routes.product.childRouts.category},
	{path: AppConfig.routes.product.childRouts.category, component: CategoryListComponent},
	{path: AppConfig.routes.product.childRouts.productCategory, component: ProductCategoryListComponent},
	{path: AppConfig.routes.product.childRouts.productItem, component: ProductItemComponent}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ProductRoutingModule {
}
