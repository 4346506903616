import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator, MatPaginatorIntl, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {DashboardService} from "../../../dashboard.service";
import {AppConfig} from "../../../../../core/config/appConfig";
import {Base} from "../../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {ApiResultModel} from "../../../../../core/interfaces/api-result.model";
import {GetOrderResModel} from "../../../../../core/interfaces/order/order.model";

@Component({
	selector: 'app-order-list',
	templateUrl: './order-list.component.html'
})
export class OrderListComponent extends Base implements OnInit {
	@Input() orders: string;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	dataSource:  MatTableDataSource<any>;
	pagination: any;
	displayedColumns: string[] = ['number', 'status', 'date', 'total', 'reward', 'action'];
	isLoading: boolean;
	dateFormat = AppConfig.constants.longDate;
	query: string;
	localStorage = localStorage

	@Output() paginate: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
	pageEvent: PageEvent;

	constructor(private dialog: MatDialog,
				private dashboardService: DashboardService,
				private router: Router) {
		super();
	}

	ngOnInit(): void {
		this.getOrders();
	}

	applyFilter(query: string) {
		this.query = query;
		this.dashboardService.getOrders(this.paginator.pageIndex - 1, this.paginator.pageSize, 'o.id', 'asc', query).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<GetOrderResModel>) => {
				this.pagination = res.payload.pagination;
				this.dataSource = new MatTableDataSource(res.payload.content);
				this.isLoading = false;
			},
			error: () => {
				this.isLoading = false;
			}
		});
	}

	view(rowData: any) {
		this.router.navigate(['dashboard/order-view'], { queryParams: { id: rowData.id, status: rowData.status } });
	}

	edit(rowData: any) {
		this.router.navigate(['dashboard/order-edit'], { queryParams: { id: rowData.id } });
	}

	private getOrders(): void {
		this.isLoading = true;
		this.dashboardService.getOrders(AppConfig.constants.page, AppConfig.constants.limit).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<GetOrderResModel>) => {
					this.dataSource = new MatTableDataSource(res.payload.content);
					this.pagination = res.payload.pagination
					this.dataSource.sort = this.sort;
					this.isLoading = false;
			},
			error: () => {
				this.isLoading = false;
			}
		})
	}

	announceSortChange(event: { active: string, direction: string }): void {
		this.dashboardService.getOrders(this.paginator.pageIndex - 1, this.paginator.pageSize, event.active, event.direction, this.query).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<GetOrderResModel>) => {
				this.pagination = res.payload.pagination;
				this.dataSource = new MatTableDataSource(res.payload.content);
				this.isLoading = false;
			},
			error: () => {
				this.isLoading = false;
			}
		});
	}

	onPaginateChange(event: PageEvent): void {
		event.pageIndex = event.pageIndex + 1;
		this.paginate.emit(event);
		this.dashboardService.getOrders(event.pageIndex, event.pageSize).pipe(takeUntil(this.destroy$)).subscribe((res: ApiResultModel<GetOrderResModel>) => {
			this.pagination = res.payload.pagination
			this.dataSource = new MatTableDataSource(res.payload.content);
		})
	}

}
