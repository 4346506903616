<div class="block-sale__content">
	<div class="block-sale__header">
		<div class="block-sale__title">{{ 'HEADER_DEAL_ZONE_TITLE'|translate }}</div>
		<div class="block-sale__subtitle">{{ 'HEADER_DEAL_ZONE_SUBTITLE'|translate }}</div>

		<div class="block-sale__controls">
			<app-arrow class="block-sale__arrow block-sale__arrow--prev" direction="prev" (click)="carousel.prev()"></app-arrow>
			<div class="block-sale__link"><a routerLink="/">{{ '' }}</a></div>
			<app-arrow class="block-sale__arrow block-sale__arrow--next" direction="next" (click)="carousel.next()"></app-arrow>

			<div class="block-sale__body-decor" type="bottom">
				<div class="decor__body">
					<div class="decor__start"></div>
					<div class="decor__end"></div>
					<div class="decor__center"></div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="saleProducts?.length < 1" class="products-view__empty">
		<div class="products-view__empty-title">
			{{ 'TEXT_NO_MATCHING_ITEMS_TITLE'|translate }}
		</div>
	</div>
	<div class="block-sale__body">
		<div class="block-sale__body-decor" type="bottom">
			<div class="decor__body">
				<div class="decor__start"></div>
				<div class="decor__end"></div>
				<div class="decor__center"></div>
			</div>
		</div>
		<div
			class="block-sale__image"

		></div>

		<div class="block-sale__loader"></div>
		<div class="container">
			<div class="block-sale__carousel" *ngIf="showCarousel">
				<owl-carousel-o [options]="carouselOptions" appOwlPreventClick>
					<ng-template *ngFor="let product of saleProducts" carouselSlide>
						<div class="block-sale__item">
							<app-product-card class="block-products-carousel__cell"
								[exclude]="['features', 'list-buttons']"
								[product]="product"
								[blockSale]="true"
								[image]="product?.gallery[0]?.filename"
							></app-product-card>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
		</div>
	</div>
</div>

