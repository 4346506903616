import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DashboardService} from "../../dashboard.service";
import {Router} from "@angular/router";
import {AppConfig} from "../../../../core/config/appConfig";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith, takeUntil} from "rxjs/operators";
import {CustomerDashboardService} from "../../customer-dashboard/customer-dashboard.service";
import {PartnershipStatusEnum} from "../../../../core/enums/partnerships.enum";
import {PartnershipDialogComponent} from "../../../../shared/components/partnership-dialog/partnership-dialog.component";
import {Base} from "../../../../core/base";
import {ApiResultModel} from "../../../../core/interfaces/api-result.model";
import {GetShopResModel} from "../../../../core/interfaces/shop.model";
import {
	GetPartnerShipsListModel
} from "../../../../core/interfaces/users/partnership.model";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {TranslationKeys} from "../../../../core/data/translation-keys";
import {ShopStateEnum} from "../../../../core/enums/shop.enum";
import {GlobalService} from "../../../../core/services/global.service";

@Component({
	selector: 'app-partnerships-list',
	templateUrl: './partnerships-list.component.html'
})
export class PartnershipsListComponent extends Base implements OnInit {
	@Output() paginate: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	dialogRef: MatDialogRef<PartnershipDialogComponent>
	dataSource: MatTableDataSource<any>;
	requestsDataSource: MatTableDataSource<any>;
	pageEvent: PageEvent;
	partnershipStatuses = PartnershipStatusEnum;
	displayedColumns: string[] = ['shopName', 'supplierName', 'discount', 'action'];
	displayedRequestsColumns: string[] = ['shopName', 'supplierName', 'updatedAt', 'discount', 'action'];
	localStorage = localStorage;
	filteredOptions: Observable<string[]>;
	shopControl = new FormControl('');
	partners: any;
	partnersPagination: any;
	requestsPagination: any;
	shopId: any;
	showNoPartners: boolean;
	isLoading: boolean;
	showLoading: boolean;
	showPopup: boolean;
	shops = [];
	requests = [];
	shopQuery = {
		page: 1,
		size: 10,
		sort: 's.updatedAt',
		order: 'desc',
		search: '',
		state: ShopStateEnum.Active
	};

	constructor(private dashboardService: DashboardService,
				private router: Router,
				private dialog: MatDialog,
				private toastr: ToastrService,
				private translate: TranslateService,
				private customerDashboardService: CustomerDashboardService,
				private globalService: GlobalService) {
		super();
	}

	ngOnInit(): void {
		this.showLoading = true;
		this.getPartners();
		this.sentRequests();
		this.getShops();
	}

	private _filter(value: string): string[] {
		const filterValue = value?.toLowerCase();
		return this.shops.filter(option => option.shopName.toLowerCase().includes(filterValue));
	}

	private getPartners(id?): void {
		this.shopId = id;
		this.dashboardService.getPartners(id || '', 1, 6).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<GetPartnerShipsListModel>) => {
				this.showLoading = false;
				this.dataSource = new MatTableDataSource(res.payload.content);
				this.partnersPagination = res.payload.pagination;
			},
			error: () => {
				this.showLoading = false;
				this.showNoPartners = true;
			}
		})
	}

	private sentRequests(id?: number): void {
		this.customerDashboardService.sentRequests(id || '').pipe(takeUntil(this.destroy$)).subscribe((res: ApiResultModel<GetPartnerShipsListModel>) => {
			this.requestsDataSource = new MatTableDataSource(res.payload.content);
			this.requestsPagination = res.payload.pagination;
		})
	}

	private getShops(): void {
		this.dashboardService.getShops(this.shopQuery).pipe(takeUntil(this.destroy$)).pipe(takeUntil(this.destroy$)).subscribe((res: ApiResultModel<GetShopResModel>) => {
			this.shops = res.payload.content;
			this.filteredOptions = this.shopControl.valueChanges.pipe(
				startWith(''),
				map(value => this._filter(value || '')),
			);
		});
	}

	isOptionSelected(event: any): void {
		const selectedValue = event.target.value
		if (selectedValue) {
			this.clear(this.shopControl);
		}
	}

	goToAllSuppliers(): void {
		this.router.navigateByUrl(`${AppConfig.routes.pages.path}/${AppConfig.routes.pages.childRouts.allSuppliers}`);
	}

	selectShop(id: number): void {
		this.shopId = id;
		this.getPartners(id);
		this.sentRequests(id);
	}

	selectAll(): void {
		this.getPartners();
		this.sentRequests();
	}

	sendRequest(id: string): void {
		const reqBody = {
			status: this.partnershipStatuses.Accepted
		}
		this.globalService.respondRequest(id, reqBody).pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.getPartners();
		})
	}

	deleteReceivedRequest(id): void {
		this.globalService.deleteRequest(id).pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.getPartners();
			this.sentRequests();
		})
	}

	openPartnershipDetails(id): void {
		if (this.showPopup) {
			return;
		}

		this.showPopup = true;
		this.customerDashboardService.show({id: id}).subscribe({
			complete: () => {
				this.showPopup = false;
			},
		});
	}

	partnershipUpdate(id: string, dto): void {
		this.globalService.partnershipUpdate(id, dto).pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.getPartners();
		})
	}

	clear(shopControl: FormControl<string | null>): void {
		shopControl.reset();
	}

	onPartnersPaginateChange(event: PageEvent): void {
		event.pageIndex = event.pageIndex + 1;
		this.paginate.emit(event);
		this.dashboardService.getPartners(this.shopId || '', event.pageIndex, event.pageSize).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<GetPartnerShipsListModel>) => {
				this.showLoading = false;
				this.dataSource = new MatTableDataSource(res.payload.content);
				this.partnersPagination = res.payload.pagination;
			},
			error: () => {
				this.showLoading = false;
				this.showNoPartners = true;
			}
		})
	}

	onRequestsPaginateChange(event: PageEvent): void {
		event.pageIndex = event.pageIndex + 1;
		this.paginate.emit(event);
		this.customerDashboardService.sentRequests(this.shopId || '', event.pageIndex, event.pageSize).pipe(takeUntil(this.destroy$)).subscribe((res: ApiResultModel<GetPartnerShipsListModel>) => {
			this.requestsDataSource = new MatTableDataSource(res.payload.content);
			this.requestsPagination = res.payload.pagination;
		})
	}

	announceSortChange(event: { active: string, direction: string }): void {
		this.dashboardService.getPartners(this.shopId || '', 1, this.paginator.pageSize, event.active, event.direction).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<GetPartnerShipsListModel>) => {
				this.dataSource = new MatTableDataSource(res.payload.content);
				this.isLoading = false;
			},
			error: () => {
				this.isLoading = false;
			}
		});
	}

	announceRequestsSortChange(event: { active: string, direction: string }): void {
		this.customerDashboardService.sentRequests(this.shopId || '', 1, this.paginator.pageSize, event.active, event.direction).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<GetPartnerShipsListModel>) => {
				this.requestsDataSource = new MatTableDataSource(res.payload.content);
				this.isLoading = false;
			},
			error: () => {
				this.isLoading = false;
			}
		});
	}

	openRepeatRequest(id: string): void {
		this.toastr.info(this.translate.instant(TranslationKeys.DevelopmentStage));
	}
}
