import {environment} from "../../../environments/environment";

export const AppConfig = {
	routes: {
		authentication: {
			path: 'authentication',
			childRouts: {
				supplierAuth: {
					registration: 'registration',
					login: 'login',
					verificationStepOne: 'verificationStepOne',
					verificationStepTwo: 'verificationStepTwo',
					createResetPassword: 'createResetPassword'
				},
				customerAuth: {
					login: 'login',
					forgotPassword: 'forgot-password',
					registration: 'registration'
				}
			}
		},
		dashboard: {
			path: 'dashboard',
			childRouts: {
				customerDashboard: {
					editCustomerProfile: 'customer/edit-profile',
					createCustomerProfile: 'customer/create-profile',
					customerOrderHistory: 'customer/order-history',
					customerPaymentTransactions: 'customer/payment-transactions',
					customerPersonalData: 'customer/personal-data',
					customerAccount: 'customer/account',
					customerPartnership: "customer/partner-ship",
					shops: 'customer/shops',
					createEditShops: 'customer/create-edit-shop',
					settings: "customer/settings"
				},
				supplierDashboard: {
					editSupplierProfile: 'supplier/edit-profile',
					supplierOrderHistory: 'supplier/order-history',
					supplierPaymentTransactions: 'supplier/payment-transactions',
					supplierPersonalData: 'supplier/personal-data',
					supplierAccount: 'supplier/account',
					supplierPartnership: "supplier/partner-ship",
					settings: 'supplier/settings',
					products: {
						childRouts: {
							productsList: 'supplier/products-list',
							editProduct: 'supplier/edit-product',
							createProduct: 'supplier/create-product'
						}
					},
					users: {
						childRouts: {
							usersList: 'supplier/users-list',
							addUSer: 'supplier/add-user',
							editUser: 'supplier/edit-user'
						}
					},
				},
				changePassword: 'change-password',
				orders: {
					childRouts: {
						orderList: 'order-list',
						orderView: 'order-view',
						orderEdit: 'order-edit'
					}
				},
				payments: {
					childRouts: {
						paymentView: 'payment-view'
					}
				},
			}
		},
		order: {
			path: 'order',
			childRouts: {
				shoppingCart: 'shopping-cart',
				orderReview: 'order-review',
				createOrder: 'create-order',
				wishList: 'wish-list',
				mobileShoppingCart: 'mobile-shopping-cart'
			}
		},
		product: {
			path: 'product',
			childRouts: {
				category: 'category-list',
				productCategory: 'product-category-list',
				productItem: 'product-item'
			}
		},
		pages: {
			path: 'pages',
			childRouts: {
				aboutUs: 'about-us',
				askedQuestions: 'asked-questions',
				contactUs: 'contact-us',
				termsConditions: 'terms-conditions',
				blockPostPage: 'block-post',
				supplierBusinessPage: 'supplier-business-page',
				allSuppliers: 'all-suppliers-page',
				notFound: 'not-found',
				privacyPolicy: 'single-page'
			}
		}
	},
	baseUrl: environment.baseUrl,
	endpoints: {
		auth: 'auth',
		locations: {
			getCountries: 'settings/get-countries',
			getRegions: 'settings/get-regions-by-country',
		},
		supplier: {
			registration: "auth/supplier-registration",
			login: "auth/supplier-login",
			verification: "auth/confirm-email-token",
			create: "users/create-supplier-user",
			resendVerifyCode: "auth/resend-email-confirmation",
			forgotPassword: "auth/supplier-forgot-password",
			resetPassword: "auth/supplier-reset-password",
			createAccount: "suppliers/create-supplier-account",
			getSupplier: "suppliers/get-supplier/",
			getSuppliers: "suppliers/get-suppliers?",
			getUsers: "users/get-users?",
			editSupplier: "suppliers/update-supplier/",
			products: {
				products: "products/get-products?",
				productsSearch: "products/products-search",
				productsById: "products/get-products/",
				editProduct: "products/update-products/",
				createProduct: "products/create-products",
				deleteProducts: "products/delete-products/",
				uploadImage: "products/upload-image/",
				uploadLogo: "suppliers/upload-logo",
				uploadGallery: "products/upload-gallery/",
				updateGallery: "products/update-gallery/",
				deleteGalleryImage: "products/delete-image/",
				getImage: "local-files/get-image/",
				getUnits: "settings/get-units",
				updateProductVisibility: "products/update-product-visibility"
			},
			uploadLogo: "suppliers/upload-logo",
			getCategories: "products-categories/get-categories",
			getCategoriesById: "products-categories/get-category/",
			users: {
				addUser: "users/add-supplier-user",
				editUser: "users/update/",
				getUsers: "users/"
			},
			getRoles: "access/roles/get-roles",
			getRole: "access/roles/get-role/",
			receivedRequests: "partnerships/received-requests",
			respondRequest: "partnerships/request-respond",
			partnershipUpdate: "partnerships/update",
			deleteRequest: "partnerships/delete-partnership",
			updateSupplierSettings: "suppliers/update-supplier-settings",
			getApiKeys: "suppliers/get-api-keys",
			generateApiKeys: "suppliers/generate-api-key",
			deleteApiKey: "suppliers/delete-api-key"
		},
		customer: {
			registration: "auth/customer-registration",
			login: "auth/customer-login",
			verification: "auth/confirm-mobile-token",
			create: "users/create-customer-user",
			resendVerifyCode: "auth/resend-mobile-confirmation",
			forgotPassword: "auth/customer-forgot-password",
			resetPassword: "auth/customer-reset-password",
			createAccount: "customers/create-customer-account",
			getCustomer: "customers/get-customer/",
			editCustomer: "customers/update-customer/",
			deleteAccount: "users/delete-account/",
			sendRequests: "partnerships/send-requests?",
			sendReqToSupplier: "partnerships/send-request",
			updateSettings: "customers/update-customer-settings",
			rewardSell: "payments/reward-sell",
			uploadAvatar: "users/avatar",
			uploadPhoto: "customers/upload-photo"
		},
		changePassword: "users/change-password",
		getPartners: "partnerships/get-partnerships?",
		payments: "payments/get-payments?",
		orders: {
			getOrders: "orders/get-orders?",
			getOrdersById: "orders/get-order/",
			editOrder: "orders/update-order/",
			editOrderStatus: "orders/update-status-by-id/",
			createOrder: "orders/create-order"
		},
		shops: {
			getShops: "shops/get-shops",
			getShopsById: "shops/get-shops/",
			createShops: "shops/create-shops",
			deleteShop: "shops/delete-shops/",
			editShop: "shops/update-shops/",
			archive: "shops/shop-archive/"
		},
		favorite: {
			getList: "favorite-products-lists/get-favorite-lists",
			getListById: "favorite-products-lists/get-favorite-list/",
			createList: "favorite-products-lists/create-favorite-list",
			deleteList: "favorite-products-lists/delete-favorite-list/",
			addToList: "favorite-products-lists/add-favorite-product?",
			removeFromList: "favorite-products-lists/remove-favorite-product?",
			deleteFavoriteList: "favorite-products-lists/delete-favorite-list/",
			updateFavoriteList: "favorite-products-lists/update-favorite-list"
		},
		shoppingCart: {
			addToCard: "shopping-cart/add-to-cart",
			getCards: "shopping-cart/get-shopping-carts",
			getCardById: "shopping-cart/shopping-cart",
			removeCard: "shopping-cart/delete-from-cart",
			removeAllCard: "shopping-cart/delete-shopping-cart",
			updateCard: "shopping-cart/update-shopping-cart"
		},
		getAccount: "account/get-account/",
		posts: {
			getPosts: "posts/get-posts",
			getPostsById: "posts/get-post/"
		},
		getHomePageAllProducts: "analytics/get-products?",
		getPartnerShipById: 'partnerships/get-partnership',
	},
	constants: {
		page: 1,
		limit: 10,
		shortDate: 'dd/MMM/yyyy',
		longDate: 'dd/MMM/yyyy HH:mm'
	},
	imagePath: environment.imagePath,
	galleryPath: environment.galleryPath,
	yandexMapKey: '4b98f660-d808-4956-8341-4b636406ffd8',
	yandexGeoCodeKey: '5cbec7aa-058a-4a2e-a15c-89bc8bef496c'
}
