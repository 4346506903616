<div class="container">

	<div class="row">
		<div *ngIf="columns?.length < 1" class="products-view__empty">
			<div class="products-view__empty-title">
				{{ 'TEXT_NO_MATCHING_ITEMS_TITLE'|translate }}
			</div>
		</div>
		<div *ngFor="let column of columns" class="col-4">
			<div class="block-products-columns__title">{{ column.title }}</div>
			<div class="block-products-columns__list">
				<div *ngFor="let product of column.products" class="block-products-columns__list-item">
					<app-product-card
						[product]="product"
						[image]="product?.gallery[0]?.filename"
						[exclude]="['actions', 'status-badge', 'features', 'buttons', 'meta']"
					></app-product-card>
				</div>
			</div>
		</div>
	</div>
</div>

