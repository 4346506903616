<a class="mobile-menu__indicator" routerLink="/shop/wishlist" (click)="goToWishList()">
    <span class="mobile-menu__indicator-icon">
        <app-icons icon="heart-20"></app-icons>
        <span class="mobile-menu__indicator-counter">{{(wishListCount | async) || 0}}</span>
    </span>
	<span class="mobile-menu__indicator-title">{{ 'TEXT_MOBILE_INDICATOR_WISHLIST'|translate }}</span>
</a>
<a class="mobile-menu__indicator" routerLink="/account" (click)="goTo()">
    <span class="mobile-menu__indicator-icon">
        <app-icons icon="person-20"></app-icons>
    </span>
	<span class="mobile-menu__indicator-title">{{ 'TEXT_MOBILE_INDICATOR_ACCOUNT'|translate }}</span>
</a>
<a class="mobile-menu__indicator" routerLink="/shop/cart" (click)="goToCard()">
    <span class="mobile-menu__indicator-icon">
        <app-icons icon="cart-20"></app-icons>
        <span class="mobile-menu__indicator-counter">{{(cardListCount | async) || 0}}</span>
    </span>
	<span class="mobile-menu__indicator-title">{{ 'TEXT_MOBILE_INDICATOR_CART'|translate }}</span>
</a>

