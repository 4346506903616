import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppConfig} from "../../core/config/appConfig";
import {ShoppingCartPageComponent} from "./shopping-cart/shopping-cart-page/shopping-cart-page.component";
import {OrderReviewComponent} from "./order/order-review/order-review.component";
import {WishListPageComponent} from "./wish-list/wish-list-page/wish-list-page.component";
import {CreateOrderComponent} from "./order/create-order/create-order.component";
import {AuthGuard} from "../../core/guards/auth.guard";

const routes: Routes = [
	{path: '', pathMatch: 'full', redirectTo: AppConfig.routes.order.childRouts.shoppingCart},
	{path: AppConfig.routes.order.childRouts.shoppingCart, component: ShoppingCartPageComponent, canActivate: [AuthGuard]},
	{path: AppConfig.routes.order.childRouts.orderReview, component: OrderReviewComponent, canActivate: [AuthGuard]},
	{path: AppConfig.routes.order.childRouts.wishList, component: WishListPageComponent, canActivate: [AuthGuard]},
	{path: AppConfig.routes.order.childRouts.createOrder, component: CreateOrderComponent, canActivate: [AuthGuard]}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class OrderRoutingModule {
}
