import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-filter-vehicle',
	templateUrl: './filter-vehicle.component.html'
})
export class FilterVehicleComponent implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}

}
