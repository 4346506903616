import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, ReplaySubject, Subject, tap} from "rxjs";
import {Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {AppConfig} from "../../core/config/appConfig";
import {CustomerUserModel} from "../../core/interfaces/users/customer/customer.model";

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	isLogOut: Subject<any> = new Subject<void>();
	isLogIn: Subject<any> = new Subject<void>();
	loggedInCustomer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	changeShopAddress: ReplaySubject<any> = new ReplaySubject<any>(1);

	constructor(private http: HttpClient,
				private router: Router,
				private jwtHelper: JwtHelperService) {
	}

	isAuthenticated() {
		const accessToken = localStorage.getItem('access_token');
		return accessToken && !this.jwtHelper.isTokenExpired(accessToken);
	}

	isCreated() {
		const customerId = localStorage.getItem('customerId');
		return customerId;
	}

	logOut() {
		Object.keys(localStorage).forEach(el => {
			if (el !== 'locale') {
				localStorage.removeItem(el);
			}
		})
		this.router.navigateByUrl('authentication');
		this.isLogOut.next(true);
		this.loggedInCustomer.next(false);
		this.changeShopAddress.next('');
	}


	getAuth(): Observable<any> {
		return this.http.get(`${AppConfig.baseUrl}${AppConfig.endpoints.auth}`);
	}

}
