<div class="modal fade" tabindex="-1" role="dialog" bsModal #modal="bs-modal">

	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable quickview">

		<div class="modal-content">
			<div class="modal-header">
				<h2 class="modal-title">{{'PARTNERSHIPS' | translate}}</h2>
				<button type="button" class="quickview__close" (click)="modal.hide()">
					<app-icons icon="cross-12"></app-icons>
				</button>
			</div>
			<div class="modal-body">
				<div class="p-3">
					<h2>{{"SUPPLIER_DETAILS" | translate}}</h2>
					<h3>{{"INPUT_COMPANY_LABEL" | translate}}
						<b>{{': ' + (data?.supplier?.companyName || '') }}</b>
					</h3>

					<h2>{{"SHOP_DETAILS" | translate}}</h2>

					<h3>{{"SHOP_NAME" | translate}}
						<b>{{': ' + (data?.shop?.shopName || '')}}</b>
					</h3>

					<h3>{{"SHOP_ADDRESS" | translate}}
						<b>:
							<span *ngIf="data?.shop.deliveryAddress.country">{{data?.shop.deliveryAddress.country?.countryName[translateService.currentLang] + ','}}</span>
							<span *ngIf="data?.shop.deliveryAddress.city">{{data?.shop.deliveryAddress.city + ','}}</span>
							{{data?.shop.deliveryAddress.address_1 || ''}}
						</b>
					</h3>

				</div>
			</div>
		</div>
	</div>
</div>
