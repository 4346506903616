import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {IconsComponent} from "./components/icons/icons.component";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {MapComponent} from "./components/map/map.component";
import {BlockSpaceComponent} from "./components/block-space/block-space.component";
import {MapService} from "./components/map/map.service";
import {NgMaterialModule} from "../ng-material.module";
import {MatDialogModule} from "@angular/material/dialog";
import {SectionHeaderComponent} from './components/section-header/section-header.component';
import {TimerComponent} from './components/timer/timer.component';
import {ProductCardComponent} from "./components/product-card/product-card.component";
import {ArrowComponent} from './components/arrow/arrow.component';
import {CarouselModule} from "ngx-owl-carousel-o";
import {UploadFileComponent} from './components/upload-file/upload-file.component';
import {FileDragNDropDirective} from "./directives/file-drag-n-drop.directive";
import {DialogConfirmComponent} from './components/dialog-confirm/dialog-confirm.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {AgreementComponent} from './components/agreement/agreement.component';
import {LoadingComponent} from "./components/loading/loading.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {OwlPreventClickDirective} from "./directives/owl-prevent-click.directive";
import {BlockHeaderComponent} from './components/block-header/block-header.component';
import {OnlyNumberDirective} from "./directives/only-number.directive";
import {ShopAddressesComponent} from "./components/shop-addresses/shop-addresses.component";
import {ProductGalleryComponent} from './components/product-gallery/product-gallery.component';
import {PartnershipDialogComponent} from './components/partnership-dialog/partnership-dialog.component';
import {LoadingBarComponent} from './components/loading-bar/loading-bar.component';
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {UploadAvatarComponent} from './components/upload-avatar/upload-avatar.component';
import {RatingComponent} from "./components/rating/rating.component";
import {CategoryActivePipe} from "../core/pipes/category-active.pipe";
import {CurrencyInputDirective} from "./directives/currency-input.directive";
import {InputFocusDirective} from "./directives/input-focus.directive";
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {TextTruncatePipe} from "../core/pipes/truncate.pipe";
import {DeleteDialogComponent} from "./components/delete-dialog/delete-dialog.component";
import {SupplierCardComponent} from "./components/supplier-card/supplier-card.component";

@NgModule({
	declarations: [
		IconsComponent,
		MapComponent,
		BlockSpaceComponent,
		SectionHeaderComponent,
		TimerComponent,
		ProductCardComponent,
		ArrowComponent,
		UploadFileComponent,
		FileDragNDropDirective,
		DialogConfirmComponent,
		AgreementComponent,
		LoadingComponent,
		OwlPreventClickDirective,
		BlockHeaderComponent,
		OnlyNumberDirective,
		ShopAddressesComponent,
		ProductGalleryComponent,
		PartnershipDialogComponent,
		LoadingBarComponent,
		UploadAvatarComponent,
		RatingComponent,
		CategoryActivePipe,
		TextTruncatePipe,
		CurrencyInputDirective,
		InputFocusDirective,
		LoadingSpinnerComponent,
		DeleteDialogComponent,
		SupplierCardComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
		CarouselModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		NgMaterialModule,
		MatDialogModule,
		MatSnackBarModule,
		CarouselModule,
		MatProgressSpinnerModule,
		MatIconModule,
		MatListModule,
		DragDropModule
	],
	exports: [
		IconsComponent,
		MapComponent,
		BlockSpaceComponent,
		SectionHeaderComponent,
		TimerComponent,
		ProductCardComponent,
		ArrowComponent,
		UploadFileComponent,
		LoadingComponent,
		OwlPreventClickDirective,
		BlockHeaderComponent,
		OnlyNumberDirective,
		ShopAddressesComponent,
		ProductGalleryComponent,
		LoadingBarComponent,
		RatingComponent,
		CategoryActivePipe,
		CurrencyInputDirective,
		InputFocusDirective,
		LoadingSpinnerComponent,
		PartnershipDialogComponent,
		DeleteDialogComponent,
		SupplierCardComponent
	],
	entryComponents: [
		DialogConfirmComponent,
		AgreementComponent,
		ShopAddressesComponent,
		UploadAvatarComponent
	],
	providers: [MapService, CurrencyPipe]
})
export class SharedModule {
}
