export const AccountMenu = {
	customerMenuItems : [
		{title: 'LINK_ACCOUNT_DASHBOARD', url: '/dashboard/customer-dashboard/customer/account'},
		{title: 'ORDER_HISTORY', url: '/dashboard/customer-dashboard/customer/order-history'},
		{title: 'SHOPS', url: '/dashboard/customer-dashboard/customer/shops'},
		{title: 'PARTNERSHIPS', url: '/dashboard/customer-dashboard/customer/partner-ship'},
		{title: 'PAYMENT_TRANSACTIONS', url: '/dashboard/customer-dashboard/customer/payment-transactions'},
		// {title: 'SETTINGS', url: '/dashboard/customer-dashboard/customer/settings'},
		// {title: 'HEADER_EDIT_PROFILE', url: '/dashboard/customer-dashboard/customer/edit-profile'},
		// {title: 'CHANGE_PASSWORD', url: '/dashboard/change-password'}
	],
	// supplierMenuItems: [
	// 	{title: 'LINK_ACCOUNT_DASHBOARD', url: '/dashboard/supplier-dashboard/supplier/account'},
	// 	{title: 'PRODUCTS', url: '/dashboard/supplier-dashboard/supplier/products-list'},
	// 	{title: 'ORDER_HISTORY', url: '/dashboard/supplier-dashboard/supplier/order-history'},
	// 	{title: 'PARTNERSHIPS', url: '/dashboard/supplier-dashboard/supplier/partner-ship'},
	// 	{title: 'PAYMENT_TRANSACTIONS', url: '/dashboard/supplier-dashboard/supplier/payment-transactions'},
	// 	{title: 'USERS', url: '/dashboard/supplier-dashboard/supplier/users-list'},
	// 	{title: 'SETTINGS', url: '/dashboard/supplier-dashboard/supplier/settings'},
	// 	{title: 'CHANGE_PASSWORD', url: '/dashboard/change-password'}
	// ],
}





