import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ProductService} from "../../../../product/services/product.service";

@Component({
    selector: 'app-megamenu',
    templateUrl: './megamenu.component.html',
    styleUrls: ['./megamenu.component.scss'],
})
export class MegamenuComponent {
    @Input() menu!: any;

    @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();

    @HostBinding('class.megamenu') classMegamenu = true;

    constructor(public translate: TranslateService,
				public productService: ProductService) {}
}
