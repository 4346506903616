export type RgbColor = [number, number, number];
export type ColorType = 'white' | 'light' | 'dark' | 'black';

export function hexToRgb(hex: string): RgbColor | null {
	if (!/^#(([A-Fa-f0-9]{3}){1,2})$/.test(hex)) {
		return null;
	}

	hex = hex.substr(1);
	let rgb;

	if (hex.length === 3) {
		const matches = hex.match(/./g);

		if (matches === null) {
			return null;
		}

		rgb = matches.map(x => x + x);
	} else {
		rgb = hex.match(/.{2}/g);

		if (rgb === null) {
			return null;
		}
	}

	return rgb.map(x => parseInt(x, 16)) as RgbColor;
}

export function luminance(color: string): number | null {
	let rgb = hexToRgb(color);

	if (!rgb) {
		return null;
	}

	rgb = rgb.map(x => x / 255).map(x => {
		if (x <= 0.03928) {
			return x / 12.92;
		} else {
			return Math.pow((x + 0.055) / 1.055, 2.4);
		}
	}) as RgbColor;

	return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
}

export function contrast(color1: string, color2: string): number | null {
	const l1 = luminance(color1);
	const l2 = luminance(color2);

	if (l1 === null || l2 === null) {
		return null;
	}

	return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
}

const cache: { [color: string]: ColorType } = {};

export function colorType(color: string): ColorType {
	if (color in cache) {
		return cache[color];
	}

	const whiteContras = contrast(color, '#fff');
	const blackContras = contrast(color, '#000');
	let result: ColorType;

	if (whiteContras === null || blackContras === null) {
		result = 'white';
	} else if (whiteContras === 1 && blackContras === 21) {
		result = 'white';
	} else if (whiteContras === 21 && blackContras === 1) {
		result = 'black';
	} else if (whiteContras >= 3 && blackContras < 10) {
		result = 'dark';
	} else {
		result = 'light';
	}

	return cache[color] = result;
}

export const FilterColor = [
	{slug: 'white', name: 'White', color: '#fff', count: 1},
	{slug: 'silver', name: 'Silver', color: '#d9d9d9', count: 1},
	{slug: 'light-gray', name: 'Light Gray', color: '#b3b3b3', count: 1},
	{slug: 'gray', name: 'Gray', color: '#808080', count: 1},
	{slug: 'dark-gray', name: 'Dark Gray', color: '#666', count: 1},
	{slug: 'coal', name: 'Coal', color: '#4d4d4d', count: 1},
	{slug: 'black', name: 'Black', color: '#262626', count: 1},
	{slug: 'red', name: 'Red', color: '#ff4040', count: 1},
	{slug: 'orange', name: 'Orange', color: '#ff8126', count: 2},
	{slug: 'yellow', name: 'Yellow', color: '#ffd333', count: 1},
	{slug: 'pear-green', name: 'Pear Green', color: '#becc1f', count: 1},
	{slug: 'green', name: 'Green', color: '#8fcc14', count: 2},
	{slug: 'emerald', name: 'Emerald', color: '#47cc5e', count: 1},
	{slug: 'shamrock', name: 'Shamrock', color: '#47cca0', count: 2},
	{slug: 'shakespeare', name: 'Shakespeare', color: '#47cccc', count: 1},
	{slug: 'blue', name: 'Blue', color: '#40bfff', count: 1},
	{slug: 'dark-blue', name: 'Dark Blue', color: '#3d6dcc', count: 2},
	{slug: 'violet', name: 'Violet', color: '#7766cc', count: 1},
	{slug: 'purple', name: 'Purple', color: '#b852cc', count: 1},
	{slug: 'cerise', name: 'Cerise', color: '#e53981', count: 1}
]
