import {Component, HostBinding} from '@angular/core';
import {MobileMenuService} from "../../../../core/services/mobile-menu.service";
import {AuthService} from "../../../auth/auth.service";
import {Router} from "@angular/router";
import {ReplaySubject} from "rxjs";
import {CardService} from "../../../order/shopping-cart/card.service";
import {WishListService} from "../../../order/wish-list/wish-list.service";
import {Base} from "../../../../core/base";
import {takeUntil} from "rxjs/operators";

@Component({
	selector: 'app-mobile-menu-indicators',
	templateUrl: './mobile-menu-indicators.component.html'
})
export class MobileMenuIndicatorsComponent extends Base {
	@HostBinding('class.mobile-menu__indicators') classMobileMenuIndicators = true;
	wishListCount: ReplaySubject<number> = this.wishListService.wishListChange;
	cardListCount: ReplaySubject<number> = this.cardService.cardListChange;

	constructor(
		public menu: MobileMenuService,
		private authService: AuthService,
		private router: Router,
		public cardService: CardService,
		public wishListService: WishListService,
	) {
		super();
	}

	ngOnInit(): void {
	}

	goTo(): void {
		if (!this.authService.isAuthenticated()) {
			this.router.navigateByUrl('authentication');
		} else {
			this.router.navigateByUrl('dashboard/customer-dashboard/customer/account')
		}
		this.menu.close();
	}

	goToWishList(): void {
		this.router.navigateByUrl('order/wish-list');
		this.menu.close();
	}

	goToCard(): void {
		this.router.navigateByUrl('order/shopping-cart');
		this.menu.close();
	}
}
