export const FilterRows =
	[
		{name: 'SHOP_ADDRESS', type: 'address'},
		{name: 'HEADER_CATEGORIES', type: 'category'},
		{name: 'TABLE_PRICE', type: 'range'}
	]

export const FilterCheck = [
	{name: 'Brandix'},
	{name: 'RedGate'},
	{name: 'Sunset'},
	{name: 'No Name'},
	{name: 'TurboElectric'},
	{name: 'Specter'}
]

export const FilterRadio = [
	{name: 'Any'},
	{name: 'No'},
	{name: 'Yes'}
]

export const FilterRating = [
	{rating: 5},
	{rating: 4},
	{rating: 3},
	{rating: 2},
	{rating: 1}
]
