import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from "@angular/material/tabs";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {NgxMatIntlTelInputComponent} from "ngx-mat-intl-tel-input";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTreeModule} from "@angular/material/tree";
import {MatCardModule} from "@angular/material/card";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatListModule} from "@angular/material/list";
import {MatMenu, MatMenuModule} from "@angular/material/menu";
import {ModalModule} from "ngx-bootstrap/modal";

@NgModule({
	declarations: [],
	imports: [
		MatListModule,
		MatCheckboxModule,
		MatTabsModule,
		CommonModule,
		MatTableModule,
		MatPaginatorModule,
		MatTableModule,
		MatSortModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatMenuModule,
		MatIconModule,
		MatSelectModule,
		MatRadioModule,
		MatButtonModule,
		MatAutocompleteModule,
		MatTreeModule,
		MatCardModule,
		MatSlideToggleModule,
		MatFormFieldModule,
		ModalModule,
		NgxMatIntlTelInputComponent
	],
	exports: [
		MatFormFieldModule,
		NgxMatIntlTelInputComponent,
		MatCheckboxModule,
		MatAutocompleteModule,
		MatTabsModule,
		MatPaginatorModule,
		MatTableModule,
		CommonModule,
		MatTableModule,
		MatListModule,
		MatSortModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatIconModule,
		MatSelectModule,
		MatRadioModule,
		MatButtonModule,
		MatTreeModule,
		ModalModule,
		MatCardModule,
		MatSlideToggleModule
	]
})
export class NgMaterialModule {
}
