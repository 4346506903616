import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {DashboardComponent} from "./dashboard.component";
import {AppConfig} from "../../core/config/appConfig";
import {AuthGuard} from "../../core/guards/auth.guard";
import {ChangePasswordComponent} from "./components/change-password/change-password.component";
import {OrdersViewComponent} from "./components/orders/orders-view/orders-view.component";
import {OrdersEditComponent} from "./components/orders/orders-edit/orders-edit.component";
import {OrderListComponent} from "./components/orders/order-list/order-list.component";
import {OnlyCustomersGuard} from "../../core/guards/only-customers.guard";

const routes: Routes = [
	{path: '', component: DashboardComponent,
  children: [
	  {path: AppConfig.routes.dashboard.childRouts.changePassword, component: ChangePasswordComponent, canActivate: [AuthGuard]},
	  {path: AppConfig.routes.dashboard.childRouts.orders.childRouts.orderView, component: OrdersViewComponent, canActivate: [AuthGuard]},
	  {path: AppConfig.routes.dashboard.childRouts.orders.childRouts.orderEdit, component: OrdersEditComponent, canActivate: [AuthGuard]},
	  {path: AppConfig.routes.dashboard.childRouts.orders.childRouts.orderList, component: OrderListComponent, canActivate: [AuthGuard]},
	  {
		  path: 'customer-dashboard',
		  loadChildren: () => import('./customer-dashboard/customer-dashboard.module').then(m => m.CustomerDashboardModule),
		  canActivate: [OnlyCustomersGuard, AuthGuard]
	  }
  ],
  canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
