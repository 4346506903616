import {Component, ElementRef, HostBinding, OnInit, ViewChild} from '@angular/core';
import {MobileMenuService} from "../../../../core/services/mobile-menu.service";
import {Observable, ReplaySubject} from "rxjs";
import {AuthService} from "../../../auth/auth.service";
import {CardService} from "../../../order/shopping-cart/card.service";
import {Router} from "@angular/router";
import {AppConfig} from "../../../../core/config/appConfig";
import {ProductService} from "../../../product/services/product.service";
import {WishListService} from "../../../order/wish-list/wish-list.service";
import {NavigationService} from "../../../../core/services/navigation-service";
import {Base} from "../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
	selector: 'app-mobile-header',
	templateUrl: './mobile-header.component.html'
})
export class MobileHeaderComponent extends Base implements OnInit {
	vehiclePickerIsOpen = false;
	isCustomer: boolean;
	searchIsOpen = false;
	wishListCount: ReplaySubject<number> = this.wishListService.wishListChange;
	cardListCount: ReplaySubject<number> = this.cardService.cardListChange;
	suggestionsIsOpen: boolean;
	products = [];
	imagePath = AppConfig.galleryPath;
	searchForm: FormGroup = new FormGroup({
		text: new FormControl(),
	});

	@HostBinding('class.mobile-header') classMobileHeader = true;

	// @ViewChild('searchForm') searchForm!: ElementRef<HTMLElement>;

	@ViewChild('searchInput') searchInput!: ElementRef<HTMLElement>;

	@ViewChild('searchIndicator') searchIndicator!: ElementRef<HTMLElement>;

	constructor(public menu: MobileMenuService,
				private authService: AuthService,
				private navigationService: NavigationService,
				private cardService: CardService,
				private router: Router,
				private productService: ProductService,
				private wishListService: WishListService) {
		super();
	}

	ngOnInit(): void {
	}

	closeSearch(): void {
		this.searchIsOpen = false;
	}

	openSearch(): void {
		this.searchIsOpen = true;

		if (this.searchInput.nativeElement) {
			this.searchInput.nativeElement.focus();
		}
	}

	goToWishList(): void {
		this.router.navigateByUrl('order/wish-list');
	}

	goToCard(): void {
		this.router.navigateByUrl('order/shopping-cart');
	}

	goToProduct(product): void {
		this.navigationService.goToProduct(product.id);
	}

	goToHome(): void {
		this.router.navigateByUrl('/');
	}

	onInput(event): void {
		this.toggleSuggestions(true);

		this.getProducts({text: event.target.value});
	}

	toggleSuggestions(force?: boolean): void {
		this.suggestionsIsOpen = force !== undefined ? force : !this.suggestionsIsOpen;

		if (this.suggestionsIsOpen) {
			this.toggleVehiclePicker(false);
		}
	}

	toggleVehiclePicker(force?: boolean): void {
		this.vehiclePickerIsOpen = force !== undefined ? force : !this.vehiclePickerIsOpen;

		if (this.vehiclePickerIsOpen) {
			this.toggleSuggestions(false);
		}
	}

	onInputFocus(event): void {
		// this.getProducts({text: event.target.value});
		// this.toggleSuggestions(true);
	}

	private getProducts(data): void {
		this.productService.getProducts(data).pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.products = res.payload.content.products;
		})
	}

	goTo(): void {
		if (!this.authService.isAuthenticated()) {
			this.router.navigateByUrl('authentication');
		} else {
			this.router.navigateByUrl('dashboard/customer-dashboard/customer/account')
		}
		this.menu.close();
	}

	search() {
		let text = this.searchForm.controls['text'].value
		this.navigationService.goToSearch(text);
	}
}
