import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MobileHeaderComponent} from './components/mobile-header/mobile-header.component';
import {SharedModule} from "../../shared/shared.module";
import {MobileLogoComponent} from './components/mobile-logo/mobile-logo.component';
import {TranslateModule} from "@ngx-translate/core";
import {MobileMenuComponent} from './components/mobile-menu/mobile-menu.component';
import {MobileMenuIndicatorsComponent} from './components/mobile-menu-indicators/mobile-menu-indicators.component';
import {MobileMenuLinksComponent} from './components/mobile-menu-links/mobile-menu-links.component';
import {MobileMenuPanelComponent} from './components/mobile-menu-panel/mobile-menu-panel.component';
import {MobileMenuSettingsComponent} from './components/mobile-menu-settings/mobile-menu-settings.component';
import {ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatPaginatorModule} from "@angular/material/paginator";


@NgModule({
	declarations: [
		MobileHeaderComponent,
		MobileLogoComponent,
		MobileMenuComponent,
		MobileMenuIndicatorsComponent,
		MobileMenuLinksComponent,
		MobileMenuPanelComponent,
		MobileMenuSettingsComponent
	],
	exports: [
		MobileHeaderComponent,
		MobileMenuComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		TranslateModule,
		ReactiveFormsModule,
		MatPaginatorModule,
		MatIconModule
	]
})
export class MobileModule {
}
