import {Injectable} from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
	providedIn: 'root'
})
export class NavigationService {

	constructor(private router: Router) {
	}

	goToProduct(id: string): void {
		this.router.navigate(['product/product-item'], {queryParams: {id: id}})
	}

	goToSupplier(id: number): void {
		this.router.navigate(['product/product-category-list'], {queryParams: {supplier: id}})
	}

	goToCategory(id: number): void {
		this.router.navigate(['product/product-category-list'], {queryParams: {categories: id}})
	}

	goToSearch(text: string): void {
		this.router.navigate(['product/product-category-list'], {queryParams: {text: text}})
	}

	navigateTo(url: string): void {
		this.router.navigateByUrl(url);
	}
}
