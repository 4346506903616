import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {GlobalService} from "../../../core/services/global.service";
import {NavigationService} from "../../../core/services/navigation-service";

export interface BlockProductsColumnsItem {
	title: string;
	products: any[];
}

@Component({
	selector: 'app-block-products-column',
	templateUrl: './block-products-column.component.html'
})
export class BlockProductsColumnComponent implements OnInit {

	@Input() columns: BlockProductsColumnsItem[] = [];

	@HostBinding('class.block') classBlock = true;

	@HostBinding('class.block-products-columns') classBlockProductsColumns = true;

	constructor(private navigationService: NavigationService) {
	}

	ngOnInit(): void {
	}

	goToProduct(id): void {
		this.navigationService.goToProduct(id);
	}

}
