import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductRoutingModule} from './product-routing.module';
import {ProductCategoryListComponent} from './components/product-category-list/product-category-list.component';
import {ProductItemComponent} from './components/product-item/product-item.component';
import {HttpClientModule} from "@angular/common/http";
import {TranslateModule} from "@ngx-translate/core";
import {WidgetCategoryListComponent} from './components/widgets/widget-category-list/widget-category-list.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FilterCategoryComponent} from './components/filters/filter-category/filter-category.component';
import {FilterCheckComponent} from './components/filters/filter-check/filter-check.component';
import {FilterColorComponent} from './components/filters/filter-color/filter-color.component';
import {FilterRadioComponent} from './components/filters/filter-radio/filter-radio.component';
import {FilterRangeComponent} from './components/filters/filter-range/filter-range.component';
import {FilterRatingComponent} from './components/filters/filter-rating/filter-rating.component';
import {FilterVehicleComponent} from './components/filters/filter-vehicle/filter-vehicle.component';
import {CollapseItemDirective} from "./directives/collapse-item.directive";
import {CollapseContentDirective} from "./directives/collapse-content.directive";
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import {ProductsViewComponent} from './components/products-view/products-view.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {CategoryListComponent} from "./components/category-list/category-list.component";
import {WidgetFiltersComponent} from './components/widgets/widget-filters/widget-filters.component';
import {ShopSidebarService} from "./services/sidebar.service";
import {ProductQuickViewComponent} from '../../shared/components/product-quick-view/product-quick-view.component';
import {NgMaterialModule} from "../../ng-material.module";
import {NgxPaginationModule} from "ngx-pagination";
import {DashboardModule} from "../dashboard/dashboard.module";
import {FilterShopAddressComponent} from './components/filters/filter-shop-address/filter-shop-address.component';
import {SharedModule} from "../../shared/shared.module";
import {ProductTabsComponent} from "./components/product-tabs/product-tabs.component";
import {ProductTabComponent} from "./components/product-tab/product-tab.component";
import {ModalModule} from "ngx-bootstrap/modal";

@NgModule({
	declarations: [
		ProductCategoryListComponent,
		CategoryListComponent,
		ProductItemComponent,
		WidgetCategoryListComponent,
		FilterCategoryComponent,
		FilterCheckComponent,
		FilterColorComponent,
		FilterRadioComponent,
		FilterRangeComponent,
		FilterRatingComponent,
		FilterVehicleComponent,
		CollapseItemDirective,
		CollapseContentDirective,
		ProductsViewComponent,
		PaginationComponent,
		SidebarComponent,
		WidgetFiltersComponent,
		ProductQuickViewComponent,
		FilterShopAddressComponent,
		ProductTabsComponent,
		ProductTabComponent
	],
	imports: [
		CommonModule,
		ProductRoutingModule,
		HttpClientModule,
		FormsModule,
		NgxSliderModule,
		NgxPaginationModule,
		ReactiveFormsModule,
		NgMaterialModule,
		TranslateModule,
		DashboardModule,
		ModalModule.forChild(),
		SharedModule,
		CommonModule
	],
	providers: [
		ShopSidebarService
	],
	exports: [
		CollapseItemDirective,
		CollapseContentDirective,
		ProductsViewComponent,
		ProductQuickViewComponent,
		SidebarComponent
	],
	entryComponents: [
		ProductQuickViewComponent
	]
})
export class ProductModule {
}
