<div class="block-header__breadcrumb breadcrumb ng-star-inserted"></div>
<div *ngIf="category && !supplier" class="container">
	<div class="block-header__body">
		<h1 class="block-header__title"
			style="margin: 0 auto; font-size: 2.25rem;font-weight: 700;padding-top: 10px;padding-bottom: 40px">{{category.name[translate.currentLang]}}</h1>
	</div>
</div>

<div class="container">
	<div class="block-header__body">
		<ng-container  *ngIf="supplier">
			<div class="profile-container">
				<img class="profile-image" src='assets/images/categories/category-1.jpg' alt="Background Image">
				<img *ngIf="supplier.logoType" class="account-image" [src]="imagePath + supplier?.logoType?.thumbnail?.url" alt="">
				<div *ngIf="!supplier.logoType" class="account-image">{{supplier.companyName.charAt(0)}}</div>
			</div>
			<div class="name-container d-flex justify-content-between">
				<div class="m-5">
					<div><h1>{{supplier.companyName}}</h1></div>
					<div><h3>{{'ON_REDRO_FROM' | translate}}<b>{{supplier.status.createdAt | date: dateFormat : '' : localStorage.getItem('locale')}}</b></h3></div>
				</div>
				<div class="m-5 d-flex justify-content-around">
					<div class="m-2">
						<div><b>{{supplier.supplierSettings?.maxDeliveryDays || 0}}</b></div>
						<div>{{'MAX_DELIVERY_DAYS' | translate}}</div>
					</div>
					<div class="m-2">
						<div><b>{{supplier.supplierSettings?.minOrderSum || 0}}</b></div>
						<div>{{'ORDER_MIN_SUM' | translate}}</div>
					</div>
					<div class="m-2" *ngIf="supplier.partnershipStatus === partnershipStatus.NotSend">
						<button (click)="sendRequest(supplier.id)" class="btn btn-primary">{{'SEND_REQUEST' | translate}}</button>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>

<ng-template #sidebar>
	<app-sidebar
		[offcanvas]="offCanvasSidebar"
		[form]="form"
		[options]="options"
		[hasLoadedProducts]="hasLoadedProducts"
	></app-sidebar>
</ng-template>

<div class="block-split" [class.block-split--has-sidebar]="hasSidebar">
	<ng-container *ngIf="offCanvasSidebar === 'always'">
		<ng-container *ngTemplateOutlet="sidebar"></ng-container>
	</ng-container>
	<div class="container">
		<div class="block-split__row row no-gutters">
			<ng-container *ngIf="sidebarPosition === 'start' && hasSidebar">
				<div class="block-split__item block-split__item-sidebar col-auto">
					<ng-container *ngTemplateOutlet="sidebar"></ng-container>
				</div>
			</ng-container>
			<div class="block-split__item block-split__item-content col-auto">

				<div class="block">

					<app-products-view
						[layout]="layout"
						[gridLayout]="gridLayout"
						[products]="products"
					></app-products-view>
				</div>
			</div>
			<ng-container *ngIf="sidebarPosition === 'end' && hasSidebar">
				<div class="block-split__item block-split__item-sidebar col-auto">
					<ng-container *ngTemplateOutlet="sidebar"></ng-container>
				</div>
			</ng-container>
		</div>
	</div>
</div>

<div class="block-space block-space--layout--before-footer"></div>

