import {
	Component,
	ElementRef,
	HostBinding,
	Inject,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {TranslateService} from "@ngx-translate/core";
import {ProductService} from "../../../../product/services/product.service";
import {GlobalService} from "../../../../../core/services/global.service";
import {Base} from "../../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {
	CategoriesContentItemModel,
	GetCategoriesResModel
} from "../../../../../core/interfaces/categories/categories.model";
import {ApiResultModel} from "../../../../../core/interfaces/api-result.model";

@Component({
	selector: 'app-categories-menu',
	templateUrl: './categories.component.html'
})
export class CategoriesComponent extends Base implements OnInit, OnDestroy {

	isOpen = false;

	categories: CategoriesContentItemModel[] = [];

	currentItem = null;

	@Input() label: string = '';

	@HostBinding('class.departments') classDepartments = true;
	isLoading: boolean;
	categoriesInProgress: boolean;

	@HostBinding('class.departments--open') get classDepartmentsOpen() {
		return this.isOpen;
	}

	constructor(
		@Inject(PLATFORM_ID) private platformId: any,
		private elementRef: ElementRef<HTMLElement>,
		private zone: NgZone,
		public translate: TranslateService,
		public productService: ProductService,
		private globalService: GlobalService
	) {
		super();
	}

	ngOnInit(): void {
		if (!isPlatformBrowser(this.platformId)) {
			return;
		}

		this.globalService.onHideCategoriesMenu.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.isOpen = false;
		})
	}

	private getCategories(): void {
		this.isLoading = true;
		this.globalService.getCategories().pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<GetCategoriesResModel>) => {
				this.categories = res.payload.content;
				this.isLoading = false;
				this.categoriesInProgress = false;
			},
			error: () => {
				this.isLoading = false;
				this.categoriesInProgress = false;
			}
		})
	}

	onClick(): void {
		this.categoriesInProgress = true;
		this.isOpen = !this.isOpen;
		this.getCategories();
	}

	onMouseenter(item: any): void {
		this.currentItem = item;
	}

	onMouseleave(): void {
		// this.currentItem = null;
	}

	onItemClick(): void {
		this.isOpen = false;
		this.currentItem = null;
	}

	goToCategory(id): void {
		let query = {
			categories: id
		}

		this.productService.goToCategory(query);
	}
}
