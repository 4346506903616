import { NgModule} from "@angular/core";
import {DashboardComponent} from "./dashboard.component";
import {DashboardRoutingModule} from "./dashboard-routing.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {TranslateModule} from "@ngx-translate/core";
import {DashboardService} from "./dashboard.service";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgMaterialModule} from "../../ng-material.module";
import {JwtInterceptor} from "../../core/interceptor/interceptor";
import {MatDialogModule} from "@angular/material/dialog";
import {PartnershipsListComponent} from "./components/partnerships-list/partnerships-list.component";
import {ChangePasswordComponent} from "./components/change-password/change-password.component";
import {PaymentsListComponent} from "./components/payment-transactions/payments-list/payments-list.component";
import {DashboardMenuComponent} from "./components/menu/dashboard-menu.component";
import {OrderListComponent} from "./components/orders/order-list/order-list.component";
import {ChangeOrderStatusComponent} from "./components/dialogs/change-order-status/change-order-status.component";
import {OrdersEditComponent} from "./components/orders/orders-edit/orders-edit.component";
import {OrdersViewComponent} from "./components/orders/orders-view/orders-view.component";
import {AddressFormComponent} from "./components/address-form/address-form.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {DeleteDialogComponent} from "../../shared/components/delete-dialog/delete-dialog.component";
import {SharedModule} from "../../shared/shared.module";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {LocalizedDatePipe} from "../../core/pipes/localized-date.pipe";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatOptionModule} from "@angular/material/core";

@NgModule({
	declarations: [
		LocalizedDatePipe,
		DashboardComponent,
		PartnershipsListComponent,
		ChangePasswordComponent,
		PaymentsListComponent,
		DashboardMenuComponent,
		OrderListComponent,
		OrdersViewComponent,
		OrdersEditComponent,
		ChangeOrderStatusComponent,
		AddressFormComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgMaterialModule,
		MatDialogModule,
		MatProgressSpinnerModule,
		MatAutocompleteModule,
		MatOptionModule,
		DashboardRoutingModule,
		HttpClientModule,
		TranslateModule,
		SharedModule,
		MatProgressBarModule,
		FormsModule,
		ReactiveFormsModule,
		FormsModule
	],
	providers: [
		DashboardService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		}
	],
	exports: [
		DashboardMenuComponent,
		PaymentsListComponent,
		PartnershipsListComponent,
		OrderListComponent,
		OrdersEditComponent,
		OrdersViewComponent,
		AddressFormComponent
	],
	entryComponents: [
		ChangeOrderStatusComponent,
		DeleteDialogComponent]
})

export class DashboardModule {
}
