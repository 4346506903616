<div class="card">

	<div class="d-flex justify-content-between">
		<div class="card-header">
			<h2>{{'PARTNERS' | translate}}</h2>
		</div>
		<div class="card-header">
			<button class="btn btn-primary btn-sm" (click)="goToAllSuppliers()">{{'FIND_PARTNER' | translate}}</button>
		</div>
	</div>
	<div class="card-divider ng-star-inserted"></div>

	<div class="d-flex justify-content-between">
		<div></div>
		<div class="card-header">
			<div class="form-group">
				<form class="example-form">
					<mat-form-field class="example-full-width" appearance="fill">
						<mat-label>{{'SELECT_SHOP' | translate}}</mat-label>
						<input type="text" matInput [formControl]="shopControl" [matAutocomplete]="auto"
							   (click)="isOptionSelected($event)">
						<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
							<mat-option [value]="'All'" (click)="selectAll()">
								{{'ALL' | translate}}
							</mat-option>
							<mat-option *ngFor="let option of filteredOptions | async" [value]="option.shopName"
										(click)="selectShop(option.id)">
								{{option.shopName}}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</form>
			</div>
		</div>
	</div>


	<div class="table-responsive-sm" style="margin-left: 2em">
		<table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

			<ng-container matColumnDef="shopName">
				<th mat-header-cell *matHeaderCellDef mat-sort-header="p.shopName"
					style="color: black; font-weight: bold"> {{'SHOP_NAME' | translate}} </th>
				<td mat-cell *matCellDef="let row"> {{row.shop.shopName}} </td>
			</ng-container>

			<ng-container matColumnDef="supplierName">
				<th mat-header-cell *matHeaderCellDef mat-sort-header="p.supplierName"
					style="color: black; font-weight: bold"> {{'SUPPLIER_NAME' | translate}} </th>
				<td mat-cell *matCellDef="let row"> {{row.supplier.companyName}} </td>
			</ng-container>

			<ng-container matColumnDef="discount">
				<th mat-header-cell *matHeaderCellDef mat-sort-header="p.discount"
					style="color: black; font-weight: bold"> {{'INPUT_DISCOUNT_LABEL' | translate}} </th>
				<td mat-cell *matCellDef="let row"> {{row.discount || 0}} %</td>
			</ng-container>

			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let row">
					<button (click)="openPartnershipDetails(row.id)" style="border: none">
						<mat-icon>wysiwyg</mat-icon>
					</button>
					<button (click)="deleteReceivedRequest(row.id)" style="border: none">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

			<tr class="mat-row" *matNoDataRow>
				<td class="mat-cell" colspan="4"><h2>{{ 'TEXT_NO_MATCHING_PARTNERS_TITLE'|translate }}</h2>
				</td>
			</tr>
		</table>
		<mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]" [length]="partnersPagination?.totalRecords"
			(page)="pageEvent = $event; onPartnersPaginateChange($event)" showFirstLastButtons>
		</mat-paginator>
	</div>
			<div class="card-header"><h2>{{'REQUESTS' | translate}}</h2></div>
			<div class="card-body card-body--padding--2">
				<table mat-table [dataSource]="requestsDataSource" matSort (matSortChange)="announceRequestsSortChange($event)">

					<ng-container matColumnDef="shopName">
						<th mat-header-cell *matHeaderCellDef mat-sort-header="p.shopName"
							style="color: black; font-weight: bold"> {{'SHOP_NAME' | translate}} </th>
						<td mat-cell *matCellDef="let row"> {{row.shop.shopName}} </td>
					</ng-container>

					<ng-container matColumnDef="supplierName">
						<th mat-header-cell *matHeaderCellDef mat-sort-header="p.supplierName"
							style="color: black; font-weight: bold"> {{'SUPPLIER_NAME' | translate}} </th>
						<td mat-cell *matCellDef="let row"> {{row.supplier.companyName | translate}} </td>
					</ng-container>
					<ng-container matColumnDef="updatedAt">
						<th mat-header-cell *matHeaderCellDef mat-sort-header="p.updatedAt"
							style="color: black; font-weight: bold"> {{'TABLE_UPDATED_DATE' | translate}} </th>
						<td mat-cell *matCellDef="let row"> {{row.updatedAt | localizedDate: dateFormat}} </td>
					</ng-container>
					<ng-container matColumnDef="discount">
						<th mat-header-cell *matHeaderCellDef mat-sort-header="p.discount"
							style="color: black; font-weight: bold"> {{'INPUT_DISCOUNT_LABEL' | translate}} </th>
						<td mat-cell *matCellDef="let row"> {{row.discount || 0}} %</td>
					</ng-container>
					<ng-container matColumnDef="action">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let row">
							<button (click)="openPartnershipDetails(row.id)" style="border: none">
								<mat-icon>wysiwyg</mat-icon>
							</button>
							<button (click)="openRepeatRequest(row.id)" style="border: none">
								<mat-icon>group_add</mat-icon>
							</button>
							<button (click)="deleteReceivedRequest(row.id)" style="border: none">
								<mat-icon>delete</mat-icon>
							</button>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedRequestsColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedRequestsColumns;"></tr>

					<tr class="mat-row" *matNoDataRow>
						<td class="mat-cell" colspan="4"><h2>{{ 'NO_REQUESTS'|translate }}</h2>
						</td>
					</tr>
				</table>
			</div>
			<mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]" [length]="requestsPagination?.totalRecords"
						   (page)="pageEvent = $event; onRequestsPaginateChange($event)" showFirstLastButtons></mat-paginator>
</div>
