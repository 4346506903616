import {catchError, Observable, tap, throwError} from "rxjs";
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from "@angular/common/http";
import {Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {AppConfig} from "../config/appConfig";
import {AuthService} from "../../modules/auth/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(private router: Router,
				private authService: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
				}
			}),
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401) {
					this.authService.logOut();
				}
				return throwError(error);
			})
		);
	}
}
