import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
	selector: 'app-agreement',
	templateUrl: './agreement.component.html'
})
export class AgreementComponent implements OnInit {

	constructor(private dialogRef: MatDialogRef<AgreementComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	ngOnInit(): void {
	}

	accept(): void {
		this.dialogRef.close(true);
	}

	decline(): void {
		this.dialogRef.close(false);
	}

	close(): void {
		this.dialogRef.close(false);
	}
}
