import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HomeService} from "../../home/home.service";
import {Base} from "../../../core/base";
import {takeUntil} from "rxjs/operators";

@Component({
	selector: 'app-block-post-page',
	templateUrl: './block-post-page.component.html'
})
export class BlockPostPageComponent extends Base implements OnInit {

	postId: string;
	postName: string;

	constructor(private activatedRoute: ActivatedRoute,
				private homeService: HomeService) {
		super();
	}

	ngOnInit(): void {
		this.postId = this.activatedRoute.snapshot.queryParams.id;
		this.getPostById();
	}

	private getPostById(): void {
		this.homeService.getPostsById(this.postId).pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.postName = res.payload.title;
		})
	}

}
