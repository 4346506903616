<div *ngIf="isShops" class="m-2">
	<mat-form-field appearance="outline">
		<input matInput placeholder="{{'SEARCH' | translate}}" (keyup)="applyFilter($event.target.value)" #input>
	</mat-form-field>
</div>

<ul>
	<li *ngFor="let item of items">
		<ng-template #content>
			{{ (!item.title ? (item.shopName || item) : item.title) | translate }}
			<app-icons *ngIf="item.submenu" icon="arrow-rounded-right-7x11"></app-icons>
		</ng-template>
		<ng-container *ngIf="item.url && (item.external || item?.submenu?.length)">
			<a
				[class.highlight]="item.customFields && item.customFields['highlight']"
				(click)="onItemClick($event, item, panel)"
			>
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</a>
		</ng-container>
		<ng-container *ngIf="item.url && !(item.external || item?.submenu?.length)">
			<a
				[class.highlight]="item.customFields && item.customFields['highlight']"
				(click)="onItemClick($event, item, panel)"
			>
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</a>
		</ng-container>
		<ng-container *ngIf="!item.url">
			<button
				type="button"
				[class.highlight]="true"
				(click)="onItemClick($event, item, panel)"
			>
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</button>
		</ng-container>

		<ng-template #panel>
			<app-mobile-menu-links *ngIf="item.submenu" [items]="item.submenu" (itemClick)="itemClick.emit($event)"></app-mobile-menu-links>
		</ng-template>
	</li>
	<li><mat-paginator *ngIf="isShops" [pageSizeOptions]="[10, 25, 100]"  [length]="pagination?.totalRecords || items.length"
					   (page)="pageEvent = $event; onPaginateChange($event)" showFirstLastButtons></mat-paginator></li>
</ul>
