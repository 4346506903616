<div class="filter-list__list">
	<label
		*ngFor="let item of filters"
		class="filter-list__item"
	>
		<div class="filter-list__input">
			<span class="input-radio__body">
    <input
		class="input-radio__input"
		type="radio"
	>
    <span class="input-radio__circle"></span>
</span>
		</div>


		<span class="filter-list__title">{{ item.name }}</span>
		<span *ngIf="item.count" class="filter-list__counter">{{ item.count }}</span>
	</label>
</div>

