<div *ngIf="menu.image" class="megamenu__image">
    <img class="reflect-rtl" [src]="menu.image" alt="">
</div>
<div class="row">
	<div *ngFor="let link of menu.children | categoryActive" class="col-4">
		<ul *ngIf="menu.children?.length" class="megamenu__links megamenu-links megamenu-links--root">
			<li
				class="megamenu-links__item mt-2"
				[class.megamenu-links__item--has-submenu]="link.links"
			>
				<a (click)="productService.goToCategory({ categories: link.id })" class="megamenu-links__item-link" (click)="itemClick.emit(link)">
					{{ link.name[translate.currentLang] }}
				</a>

				<ul *ngIf="link.children?.length" class="megamenu-links m-1">
					<li *ngFor="let sublink of link.children | categoryActive" class="megamenu-links__item">
						<a (click)="productService.goToCategory({ categories: sublink.id })" class="megamenu-links__item-link" (click)="itemClick.emit(sublink)">
							{{ sublink.name[translate.currentLang] }}
						</a>
					</li>
				</ul>
			</li>
		</ul>
	</div>

</div>
