import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {AppConfig} from "../../../core/config/appConfig";
import {CustomerLoginComponent} from "./customer-login/customer-login.component";
import {CustomerVerificationComponent} from "./customer-verification/customer-verification.component";

const routes: Routes = [
	{path: AppConfig.routes.authentication.childRouts.customerAuth.login, component: CustomerLoginComponent},
	{path: AppConfig.routes.authentication.childRouts.customerAuth.forgotPassword, component: CustomerVerificationComponent},
	{path: AppConfig.routes.authentication.childRouts.customerAuth.registration, component: CustomerVerificationComponent}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CustomerAuthRoutingModule {
}
