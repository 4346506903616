import {NgModule} from "@angular/core";
import {AuthRoutingModule} from "./auth-routing.module";
import {AuthComponent} from "./auth.component";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpLoaderFactory} from "../../app.module";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {CountdownModule} from "ngx-countdown";
import {HasErrorPipe} from "../../core/pipes/has-error.pipe";
import {IsInvalidPipe} from "../../core/pipes/is-invalid.pipe";
import {NgMaterialModule} from "../../ng-material.module";
import {SharedModule} from "../../shared/shared.module";
import {CustomerAuthModule} from "./customer-auth/customer-auth.module";
import {RouterModule} from "@angular/router";

@NgModule({
	declarations: [
		AuthComponent,
		HasErrorPipe,
		IsInvalidPipe
	],
    imports: [
        CountdownModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AuthRoutingModule,
        NgMaterialModule,
        HttpClientModule,
        CustomerAuthModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedModule,
        RouterModule,
        CommonModule
    ],
	providers: [],
	exports: [
		HasErrorPipe,
		IsInvalidPipe
	]
})

export class AuthModule {
}
