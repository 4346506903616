<div style="overflow: hidden; padding: 10px">
	<h1 mat-dialog-title>{{'DELETE_CONFIRMATION' | translate}}</h1>
	<div mat-dialog-content>
		<p><strong>{{fName}}</strong> will be deleted. Do you confirm?</p>
	</div>
	<div mat-dialog-actions>
		<button mat-button
				(click)="cancel()">Cancel</button>
		<button mat-button cdkFocusInitial
				(click)="confirm()">Confirm</button>
	</div>
</div>
