import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {Base} from "../../../core/base";
import {SupplierModel} from "../../../core/interfaces/users/supplier/supplier.model";
import {Router} from "@angular/router";
import {ProductCardElement, ProductCardLayout} from "../product-card/product-card.component";
import {ProductService} from "../../../modules/product/services/product.service";
import {PartnershipStatusEnum} from "../../../core/enums/partnerships.enum";
import {takeUntil} from "rxjs/operators";
import {GlobalService} from "../../../core/services/global.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-supplier-card',
  templateUrl: './supplier-card.component.html',
  styleUrls: ['./supplier-card.component.scss']
})
export class SupplierCardComponent extends Base implements OnInit{
	imagePath = environment.imagePath;
	@Input() supplier: SupplierModel;
	@Input() shopId: string;
	@Input() exclude: ProductCardElement[] = [];
	@Output() onSendRequest = new EventEmitter;
	@HostBinding('class.product-card') classProductCard = true;
	layout?: ProductCardLayout;
	partnershipStatus = PartnershipStatusEnum;
	@HostBinding('class.product-card--layout--grid') get classProductCardLayoutGrid(): boolean {
		return this.layout === 'grid';
	}

	@HostBinding('class.product-card--layout--list') get classProductCardLayoutList(): boolean {
		return this.layout === 'list';
	}

	@HostBinding('class.product-card--layout--table') get classProductCardLayoutTable(): boolean {
		return this.layout === 'table';
	}

	@HostBinding('class.product-card--layout--horizontal') get classProductCardLayoutHorizontal(): boolean {
		return this.layout === 'horizontal';
	}
	cartInProgress = false;

	constructor(private router: Router,
				private globalService: GlobalService,
				private productService: ProductService) {
		super();
	}

	ngOnInit() {
	}

	goToSupplierBusinessPage(id): void {
		this.productService.goToCategory({supplier: id});
	}

	sendRequest(id: string): void {
		this.globalService.sendReqToSupplier(id, this.shopId).pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.onSendRequest.emit();
		});
	}

}
