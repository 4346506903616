import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject, timer} from "rxjs";
import {ApiResultModel} from "../../../core/interfaces/api-result.model";
import {WishListsModel} from "../../../core/interfaces/wish-list/wish-lists.model";
import {AppConfig} from "../../../core/config/appConfig";
import {map, takeUntil, tap} from "rxjs/operators";
import {WishListDataModel} from "../../../core/interfaces/wish-list/wish-list-data.model";
import {HttpClient, HttpParams} from "@angular/common/http";
import {TranslationKeys} from "../../../core/data/translation-keys";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
	providedIn: 'root'
})
export class WishListService {

	wishListChange: ReplaySubject<number> = new ReplaySubject<number>(1);
	wishListCount: number;
	alertMessage : string;

	constructor(private http: HttpClient,
				private toastr: ToastrService,
				private translate: TranslateService) {
	}

	getWishList(size: number=  100): Observable<ApiResultModel<WishListsModel>> {
		return this.http.get<ApiResultModel<WishListsModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.favorite.getList}`, {params: {limit: size}}).pipe(tap((res) => {
			this.wishListCount = res.payload.content.length;
			this.wishListChange.next(this.wishListCount);
		}))
	}

	createWishList(body: {name: string}): Observable<ApiResultModel<WishListDataModel>> {
		return this.http.post<ApiResultModel<WishListDataModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.favorite.createList}`, body).pipe(tap( {
			next: () => {
				this.wishListChange.next(this.wishListCount);
				this.toastr.success(this.translate.instant(TranslationKeys.WishListCreate));
			},
			error: (error) => {
				this.alertMessage = error.error.message;
				this.toastr.error(this.translate.instant(this.alertMessage));
			}
		}))
	}

	deleteList(id: string): Observable<ApiResultModel<number>> {
		return this.http.delete<ApiResultModel<number>>(`${AppConfig.baseUrl}${AppConfig.endpoints.favorite.deleteList}${id}`).pipe(tap( {
			next: () => {
				this.wishListChange.next(this.wishListCount);
				this.toastr.success(this.translate.instant(TranslationKeys.RemoveWishList));
			},
			error: (error) => {
				this.alertMessage = error.error.message;
				this.toastr.error(this.translate.instant(this.alertMessage));
			}
		}))
	}

	getWishListById(id: string, shopId: string): Observable<ApiResultModel<WishListDataModel>> {
		return this.http.get<ApiResultModel<WishListDataModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.favorite.getListById}${id}`, { params: {shopId: shopId}})
	}

	addToWishList(id: string, productId: string): Observable<ApiResultModel<WishListDataModel>> {
		let params = new HttpParams()
			.set("id", id)
			.set('productId', productId)
		return this.http.put<ApiResultModel<WishListDataModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.favorite.addToList}${params}`, {}).pipe(tap( {
			next: () => {
				this.toastr.success(this.translate.instant(TranslationKeys.AddToWishList));
			},
			error: (error) => {
				this.alertMessage = error.error.message;
				this.toastr.error(this.translate.instant(this.alertMessage));
			}
		}))
	}

	removeFromFavorite(id: string, productId: string): Observable<ApiResultModel<WishListDataModel>> {
		let params = new HttpParams()
			.set("id", id)
			.set('productId', productId)
		return this.http.put<ApiResultModel<WishListDataModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.favorite.removeFromList}${params}`, {}).pipe(tap({
			next: () => {
				this.toastr.success(this.translate.instant(TranslationKeys.RemoveFromWishList));
			},
			error: (error) => {
				this.alertMessage = error.error.message;
				this.toastr.error(this.translate.instant(this.alertMessage, false));
			}
		}))
	}

	updateFavoriteList(id: string, data: {name: string}): Observable<ApiResultModel<WishListDataModel>> {
		return this.http.put<ApiResultModel<WishListDataModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.favorite.updateFavoriteList}/${id}`, data);
	}

	private destroy$: Subject<void> = new Subject();
	private abortPrevious$: Subject<void> = new Subject<void>();
	private showWishListSubject$: Subject<any> = new Subject();
	private showWishListEditNameSubject$: Subject<any> = new Subject();
	changeWishListName$: Subject<any> = new Subject();

	showWishListPopup$: Observable<any> = this.showWishListSubject$.pipe(takeUntil(this.destroy$));
	showWishListEditPopup$: Observable<any> = this.showWishListEditNameSubject$.pipe(takeUntil(this.destroy$));

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	show(product: any): Observable<void> {
		this.abortPrevious$.next();

		return timer(350).pipe(
			tap(() => this.showWishListSubject$.next(product)),
			map(() => {}),
			takeUntil(this.abortPrevious$),
		);
	}

	showEditWishListName(list: any): Observable<void> {
		this.abortPrevious$.next();

		return timer(350).pipe(
			tap(() => this.showWishListEditNameSubject$.next(list)),
			map(() => {}),
			takeUntil(this.abortPrevious$),
		);
	}
}
