import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {GlobalService} from "../../../core/services/global.service";
import {PartnershipsContentItemModel} from "../../../core/interfaces/users/partnership.model";
import {PartnershipStatusEnum} from "../../../core/enums/partnerships.enum";
import {ModalDirective} from "ngx-bootstrap/modal";
import {switchMap, takeUntil} from "rxjs/operators";
import {timer} from "rxjs";
import {CustomerDashboardService} from "../../../modules/dashboard/customer-dashboard/customer-dashboard.service";
import {Base} from "../../../core/base";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-partnership-dialog',
	templateUrl: './partnership-dialog.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnershipDialogComponent extends Base implements OnInit, AfterViewInit {
	@ViewChild('modal') modal!: ModalDirective;
	data: PartnershipsContentItemModel;
	status = PartnershipStatusEnum;
	minDate = new Date()

	constructor(
				private globalService: GlobalService,
				private cdRef: ChangeDetectorRef,
				public translateService: TranslateService,
				private customerDashboardService: CustomerDashboardService) {
		super();
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		this.customerDashboardService.showPartnershipPopupSubject$.pipe(
			switchMap(partnership => {
				this.modal.show();
				this.getPartnerShipById(partnership);
				return timer(150);
			}),
			takeUntil(this.destroy$),
		).subscribe(() => {
		});

		this.modal.onHidden.pipe(takeUntil(this.destroy$)).pipe(takeUntil(this.destroy$)).subscribe(() => {
			// this.shops = null;
		});
	}

	private getPartnerShipById(partnerShipId: string): void {
		this.globalService.getPartnerShipById(partnerShipId).subscribe(res => {
			this.data = res.payload;
			this.cdRef.markForCheck();
		})
	}
}
