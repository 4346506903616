<button class="departments__button" type="button" (click)="onClick()"
		[class.vehicles-list__item-remove--loading]="categoriesInProgress">
	<span class="departments__button-icon"><app-icons icon="menu-16x12"></app-icons></span>
	<span class="departments__button-title">{{ "BUTTON_DEPARTMENTS" | translate }}</span>
	<span class="departments__button-arrow"><app-icons icon="arrow-rounded-down-9x6"></app-icons></span>
</button>
<div class="departments__menu">
    <div class="departments__arrow"></div>
    <div class="departments__body" (mouseleave)="onMouseleave()">
		<ul class="departments__list" *ngIf="!isLoading && categories.length > 0">
            <li class="departments__list-padding" role="presentation" (mouseenter)="onMouseleave()"></li>
            <li
                *ngFor="let item of categories | categoryActive"
                [class.departments__item--has-submenu]="item.children?.length"
                [class.departments__item--submenu--megamenu]="item.children?.length"
                [class.departments__item--hover]="item === currentItem"
                (mouseenter)="onMouseenter(item)"
            >
                <ng-template #linkContent>
                    <div class="d-flex align-items-center">
						<span>{{ item.name[translate.currentLang] }}</span>
						<span *ngIf="item.children?.length" class="departments__item-arrow">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </span>
					</div>
                </ng-template>

                <a (click)="productService.goToCategory({categories: item.id})" class="departments__item-link" (click)="onItemClick()">
                    <ng-container *ngTemplateOutlet="linkContent"></ng-container>
                </a>
            </li>
            <li class="departments__list-padding" role="presentation" (mouseenter)="onMouseleave()"></li>
        </ul>
        <div class="departments__menu-container" *ngIf="!isLoading && categories.length > 0">
            <ng-container *ngFor="let item of categories" | categoryActive>
                <ng-container *ngIf="item.children?.length">
                    <app-megamenu
                        class="departments__megamenu departments__megamenu--size--md"
                        [class.departments__megamenu--open]="item === currentItem"
                        [menu]="item"
                        (itemClick)="onItemClick()"
                    ></app-megamenu>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
