<div class="block-space block-space--layout--after-header"></div>
<div class="block">
	<div class="container">
		<div class="row">
			<div class="col-12 col-lg-3 d-flex">
				<app-dashboard-menu class="account-nav flex-grow-1"
									[menuClass]="'account-nav__item--active'"
									[showTitle]="true"
				></app-dashboard-menu>
			</div>
			<div class="col-12 col-lg-9 mt-4 mt-lg-0">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</div>

<div class="block-space block-space--layout--before-footer"></div>

