import {Component, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {CustomerAuthService} from "../customer-auth.service";
import {Router} from "@angular/router";
import {ApiResultModel} from "../../../../core/interfaces/api-result.model";
import {CustomerLoginResModel} from "../../../../core/interfaces/users/customer/customer.model";
import {AppConfig} from "../../../../core/config/appConfig";
import {Base} from "../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {AuthService} from "../../auth.service";
import {GetShopResModel} from "../../../../core/interfaces/shop.model";
import {MatTableDataSource} from "@angular/material/table";
import {ShopStateEnum} from "../../../../core/enums/shop.enum";
import {DashboardService} from "../../../dashboard/dashboard.service";

@Component({
	selector: 'app-customer-login',
	templateUrl: './customer-login.component.html'
})
export class CustomerLoginComponent extends Base implements OnInit {
	loginForm: FormGroup = this.customerAuthService.initLoginForm();
	hide: boolean = true;
	notExist: boolean = false;
	errorMessage: string;
	shopQuery = {
		page: 1,
		size: 10,
		sort: 's.updatedAt',
		order: 'desc',
		search: '',
		state: ShopStateEnum.Active
	};
	shops = [];

	constructor(private customerAuthService: CustomerAuthService,
				private authService: AuthService,
				private dashboardService: DashboardService,
				private router: Router) {
		super();
	}

	ngOnInit(): void {
	}

	login(): void {
		if (this.loginForm.valid) {
			this.customerAuthService.customerLogin(this.loginForm.getRawValue()).pipe(takeUntil(this.destroy$)).subscribe({
				next: (res: ApiResultModel<CustomerLoginResModel>) => {
					if (res.payload.user.customer) {
						setTimeout(() => {
							this.getShops();
						}, 200)
						this.router.navigateByUrl('dashboard/customer-dashboard/customer/account');
						localStorage.setItem('customerId', res.payload.user.id);
					} else {
						this.router.navigateByUrl('dashboard/customer-dashboard/customer/create-profile').then(() => {
						});
					}
				},
				error: (error: any) => {
					this.notExist = true;
					this.errorMessage = error.error.message;
				}
			})
		} else {
			this.notExist = false;
		}
	}

	goToReg(): void {
		this.router.navigate([`authentication/customer/${AppConfig.routes.authentication.childRouts.customerAuth.registration}`]);
	}

	forgotPassword(): void {
		this.router.navigate([`authentication/customer/${AppConfig.routes.authentication.childRouts.customerAuth.forgotPassword}`]);
	}

	private getShops(): void {
		this.dashboardService.getShops(this.shopQuery).pipe(takeUntil(this.destroy$)).subscribe((res: ApiResultModel<GetShopResModel>) => {
			this.shops = res.payload.content;
			this.authService.changeShopAddress.next(this.shops[0]);
			localStorage.setItem('shop', JSON.stringify(this.shops[0]));
		})
	}

}
