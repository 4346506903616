import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import {GlobalService} from "../../../core/services/global.service";
import {MatDialog} from "@angular/material/dialog";
import {
	CdkDragEnter,
	CdkDropList,
	DragRef,
	moveItemInArray
} from "@angular/cdk/drag-drop";
import {AppConfig} from "../../../core/config/appConfig";
import {Base} from "../../../core/base";
import {takeUntil} from "rxjs/operators";
import {DeleteDialogComponent} from "../delete-dialog/delete-dialog.component";
import {TranslationKeys} from "../../../core/data/translation-keys";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-upload-file',
	templateUrl: './upload-file.component.html',
	styleUrls: ['./upload-file.component.scss']
})

export class UploadFileComponent extends Base implements OnInit, AfterViewInit {
	images = 5
	@Input() productId: string;
	@Input() editImage: boolean;
	@Input() imgSrc: string;
	@Input() gallery: any;
	@Input() label: string;
	@Output() uploadImage: EventEmitter<any> = new EventEmitter<any>();
	@Output() uploadGallery: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild(CdkDropList) placeholder: CdkDropList;

	private target: CdkDropList = null;
	private targetIndex: number;
	private source: CdkDropList = null;
	private sourceIndex: number;
	private dragRef: DragRef = null;

	image: File = null;
	file: File = null;
	uploadImages: any
	imagePath = AppConfig.galleryPath;
	imageUrl: any[] = [];

	constructor(private globalService: GlobalService,
				private toastr: ToastrService,
				private translate: TranslateService,
				public dialog: MatDialog) {
		super();
		this.target = null;
		this.source = null;
	}

	ngOnInit(): void {
	}

	onFileChange(pFileList: File[] | any): void {

		this.uploadImages = []

		Object.keys(pFileList).map(key => {
			let existFile

			if (this.gallery) {
				existFile = this.gallery.find(item => item.originalName === pFileList[key].name);
			} else {
				this.gallery = []
			}

			if (!existFile) {

				this.uploadImages.push(pFileList[key])

				let formData = new FormData();

				this.uploadImages.forEach((file, index) => {
					formData.append('files', file);
					if (!this.productId) {
						let reader = new FileReader();
						reader.onload = (e: any) => {
							this.imageUrl[index] = e.target.result;
						};
						reader.readAsDataURL(file);
					}
				})

				if (!this.productId) {
					this.uploadGallery.emit(formData)
				} else {
					this.globalService.uploadGallery(this.productId, formData).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
						this.gallery.push(res.payload);
						this.uploadGallery.emit(this.gallery);
						this.toastr.success(this.translate.instant(TranslationKeys.UploadSuccess));

					}, error => {
						console.log(error)
					})
				}

			}
		})
	}

	ngAfterViewInit(): void {
		if (this.placeholder) {
			const placeholderElement = this.placeholder.element.nativeElement;

			placeholderElement.style.display = 'none';
			placeholderElement.parentNode.removeChild(placeholderElement);
		}
	}

	openConfirmDialog(file): void {
		const dialogRef = this.dialog.open(DeleteDialogComponent, {
			panelClass: 'modal-xs'
		});

		dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
			if (result !== undefined) {
				this.deleteFromArray(file, result);
			}
		});

	}

	deleteFromArray(file, index): void {
		this.gallery.splice(index, 1);
		this.globalService.deleteGalleryImage(file.id).pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.toastr.success(this.translate.instant(TranslationKeys.ImageDelete));
		})
	}

	onChange(event): void {
		this.file = event.target.files[0];
		if (this.file) {
			const imageFormData = new FormData();
			imageFormData.append('file', this.file, this.file.name);
			this.uploadImage.emit(imageFormData);
		}
	}

	onDropListDropped() {
		if (!this.target) {
			return;
		}

		const placeholderElement: HTMLElement =
			this.placeholder.element.nativeElement;
		const placeholderParentElement: HTMLElement =
			placeholderElement.parentElement;

		placeholderElement.style.display = 'none';

		placeholderParentElement.removeChild(placeholderElement);
		placeholderParentElement.appendChild(placeholderElement);
		placeholderParentElement.insertBefore(
			this.source.element.nativeElement,
			placeholderParentElement.children[this.sourceIndex]
		);

		if (this.placeholder._dropListRef.isDragging()) {
			this.placeholder._dropListRef.exit(this.dragRef);
		}

		this.target = null;
		this.source = null;
		this.dragRef = null;

		if (this.sourceIndex !== this.targetIndex) {
			moveItemInArray(this.gallery, this.sourceIndex, this.targetIndex);
			this.globalService.updateGallery(this.gallery).pipe(takeUntil(this.destroy$)).subscribe(() => {
				// this._snackBar.open("Successfully updated!", 'Close', {
				// 	duration: 2000,
				// });
			})
		}
	}

	onDropListEntered({ item, container }: CdkDragEnter) {
		if (container == this.placeholder) {
			return;
		}

		const placeholderElement: HTMLElement =
			this.placeholder.element.nativeElement;
		const sourceElement: HTMLElement = item.dropContainer.element.nativeElement;
		const dropElement: HTMLElement = container.element.nativeElement;
		const dragIndex: number = Array.prototype.indexOf.call(
			dropElement.parentElement.children,
			this.source ? placeholderElement : sourceElement
		);
		const dropIndex: number = Array.prototype.indexOf.call(
			dropElement.parentElement.children,
			dropElement
		);

		if (!this.source) {
			this.sourceIndex = dragIndex;
			this.source = item.dropContainer;

			sourceElement.parentElement.removeChild(sourceElement);
		}

		this.targetIndex = dropIndex;
		this.target = container;
		this.dragRef = item._dragRef;

		placeholderElement.style.display = '';

		dropElement.parentElement.insertBefore(
			placeholderElement,
			dropIndex > dragIndex ? dropElement.nextSibling : dropElement
		);

		this.placeholder._dropListRef.enter(
			item._dragRef,
			item.element.nativeElement.offsetLeft,
			item.element.nativeElement.offsetTop
		);
	}


}
