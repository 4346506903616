<div class="card">
	<div class="card-header">
		<h2>{{ 'HEADER_CHANGE_PASSWORD'|translate }}</h2>
	</div>
	<div class="card-divider"></div>
	<div class="card-body card-body--padding--2">
		<div class="row no-gutters">
			<form [formGroup]="changePassForm" class="col-12 col-lg-7 col-xl-6">
				<div class="d-flex flex-column form-group">
					<mat-form-field appearance="outline" class="mat-form-field">
						<mat-label>{{ 'INPUT_PASSWORD_CURRENT_LABEL'|translate }}</mat-label>
						<input matInput [type]="hide? 'password': 'text'" placeholder="{{'TYPE_PASSWORD' | translate}}"
							   formControlName="currentPassword">
						<button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
							<mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
						</button>
						<mat-error *ngIf="changePassForm?.controls['currentPassword'].value">{{'VALIDATION_ERROR_PASSWORD' | translate}}</mat-error>
					</mat-form-field>
				</div>
				<div class="d-flex flex-column form-group mt-4">
					<mat-form-field appearance="outline" class="mat-form-field">
						<mat-label>{{ 'INPUT_PASSWORD_NEW_LABEL'|translate }}</mat-label>
						<input matInput [type]="hide? 'password': 'text'" placeholder="{{'TYPE_CREATE_PASSWORD' | translate}}"
							   formControlName="newPassword">
						<mat-error *ngIf="changePassForm?.controls['newPassword'].value">{{'VALIDATION_ERROR_PASSWORD' | translate}}</mat-error>
					</mat-form-field>
				</div>
				<div class="d-flex flex-column form-group mt-3">
					<mat-form-field appearance="outline" class="mat-form-field">
						<mat-label>{{ 'INPUT_PASSWORD_REPEAT_LABEL'|translate }}</mat-label>
						<input matInput [type]="hide? 'password': 'text'" placeholder="{{'TYPE_REPEAT_PASSWORD' | translate}}"
							   formControlName="repeatPassword">
					</mat-form-field>
				</div>
				<ul>
					<li *ngIf="changePassForm.controls['repeatPassword'].value && (changePassForm.controls['newPassword'].value !== changePassForm.controls['repeatPassword'].value)">
						<mat-error>{{'VALIDATION_ERROR_CONFIRM' | translate}}</mat-error>
					</li>
				</ul>

				<div class="d-flex flex-row justify-content-end">
					<mat-error *ngIf="showErrorMessage">{{ errorMessage | translate }}</mat-error>
					<button class="btn btn-primary mt-3" (click)="change()">
						{{'BUTTON_CHANGE' | translate}}
					</button>
				</div>
			</form>
		</div>
	</div>
</div>

