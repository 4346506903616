import {Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable, ReplaySubject} from "rxjs";
import {AppConfig} from "../../../core/config/appConfig";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {AuthService} from "../auth.service";
import {
	CustomerAuthReqModel,
	CustomerLoginReqModel,
	CustomerLoginResModel,
	VerifyStepsModel
} from "../../../core/interfaces/users/customer/customer.model";
import {ApiResultModel} from "../../../core/interfaces/api-result.model";

@Injectable()
export class CustomerAuthService {
	onPassData: ReplaySubject<VerifyStepsModel> = new ReplaySubject();
	isCreated: boolean;

	constructor(private http: HttpClient,
				private router: Router,
				private authService: AuthService) {
	}

	customerLogin(params: CustomerLoginReqModel): Observable<ApiResultModel<CustomerLoginResModel>> {
		return this.http.post<ApiResultModel<CustomerLoginResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.login}`, params)
			.pipe(
				map(res => {
					localStorage.setItem('access_token', res.payload.token.accessToken);
					this.authService.loggedInCustomer.next(true);
					this.authService.isLogIn.next(true);
					return res;
				})
			);
	}

	customerForgotPassword(params: any): Observable<ApiResultModel<{message: string, duration: number}>> {
		return this.http.post<ApiResultModel<{message: string, duration: number}>>(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.forgotPassword}`, params)
	}

	customerVerifyAccount(params: CustomerAuthReqModel): Observable<ApiResultModel<any>> {
		return this.http.post<ApiResultModel<any>>(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.verification}`, params)
	}

	resendCustomerVerifyCode(params: CustomerAuthReqModel): Observable<ApiResultModel<any>> {
		return this.http.post<ApiResultModel<any>>(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.resendVerifyCode}`, params)
	}

	resetCustomerPassword(params: CustomerAuthReqModel): Observable<any> {
		return this.http.post(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.resetPassword}`, params)
	}

	createPassword(params: CustomerAuthReqModel): Observable<ApiResultModel<any>> {
		return this.http.post<ApiResultModel<any>>(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.create}`, params)
	}

	customerRegistration(params: CustomerAuthReqModel): Observable<ApiResultModel<any>> {
		return this.http.post<ApiResultModel<any>>(`${AppConfig.baseUrl}${AppConfig.endpoints.customer.registration}`, params)
	}

	initLoginForm(): FormGroup {
		return new FormGroup({
			login: new FormControl('', [Validators.required, Validators.pattern(/^\+[1-9]\d{1,14}$/), Validators.minLength(8)]),
			password: new FormControl('', Validators.required),
		})
	}
}
