import {Component, OnInit} from "@angular/core";
import {AppConfig} from "../../core/config/appConfig";
import {NavigationService} from "../../core/services/navigation-service";
import {Location} from "@angular/common";
import {NavigationEnd, Router} from "@angular/router";
import {Base} from "../../core/base";
import {takeUntil} from "rxjs/operators";

@Component({
	selector: 'app-auth',
	templateUrl: './auth.component.html'
})

export class AuthComponent extends Base implements OnInit {
	isActiveCustomer: boolean;
	customerPath = '/customer/login';

	constructor(private navigationService: NavigationService,
				private location: Location,
				private router: Router) {
		super();
	}

	ngOnInit() {
		this.isActiveCustomer = this.router.url.includes('customer');
		this.setActiveClassToTab();
	}

	setActiveClassToTab(): void {
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe(res => {
			if (res instanceof NavigationEnd) {
				this.isActiveCustomer = res.url.includes('customer');
			}
		})
	}

	onActiveCustomerTab(): void {
		const path = `authentication/customer/${AppConfig.routes.authentication.childRouts.customerAuth.login}`;
		if (this.isActiveCustomer) {
			return;
		}
		this.navigationService.navigateTo(path);
	}
}
