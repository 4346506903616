<div class="site-footer">
	<div class="site-footer">
		<div class="site-footer__decor decor decor--type--bottom">
			<div class="decor__body">
				<div class="decor__start"></div>
				<div class="decor__end"></div>
				<div class="decor__center"></div>
			</div>
		</div>
		<div class="site-footer__widgets">
			<div class="container">
				<div class="row">
					<!--					<div class="col-12 col-xl-4">-->
					<!--						<div class="site-footer__widget footer-contacts">-->
					<!--							<h5 class="footer-contacts__title">{{"HEADER_CONTACT_US" | translate}}</h5>-->
					<!--							<div class="footer-contacts__text">{{"TEXT_CONTACT_US_MESSAGE" | translate}}</div>-->
					<!--							<div class="footer-contacts__contacts">-->
					<!--								<dl>-->
					<!--									<dt>{{"TEXT_PHONE_NUMBER" | translate}}</dt>-->
					<!--									<dd>+1 (800) 060-07-30</dd>-->
					<!--								</dl>-->
					<!--								<dl>-->
					<!--									<dt>{{"TEXT_EMAIL_ADDRESS" | translate}}</dt>-->
					<!--									<dd>red-parts@example.com</dd>-->
					<!--								</dl>-->
					<!--								<dl>-->
					<!--									<dt>{{"TEXT_OUR_LOCATION" | translate}}</dt>-->
					<!--									<dd>715 Fake Street, New York 10021 USA</dd>-->
					<!--								</dl>-->
					<!--								<dl>-->
					<!--									<dt>{{"TEXT_WORKING_HOURS" | translate}}</dt>-->
					<!--									<dd>Mon-Sat 10:00pm - 7:00pm</dd>-->
					<!--								</dl>-->
					<!--							</div>-->
					<!--						</div>-->
					<!--					</div>-->
					<!--					<div class="col-6 col-md-3 col-xl-2">-->
					<!--						<div class="site-footer__widget footer-links">-->
					<!--							<h5 class="footer-links__title">{{"HEADER_INFORMATION" | translate}}</h5>-->
					<!--							<ul class="footer-links__list">-->
					<!--								<li class="footer-links__list__item">-->
					<!--									<a href="" class="footer-links__link">-->
					<!--										{{"LINK_ABOUT_US" | translate}}-->
					<!--									</a>-->
					<!--								</li>-->
					<!--								<li class="footer-links__list__item">-->
					<!--									<a href="" class="footer-links__link">-->
					<!--										{{"LINK_DELIVERY_INFORMATION" | translate}}-->
					<!--									</a>-->
					<!--								</li>-->
					<!--								<li class="footer-links__list__item">-->
					<!--									<a href="" class="footer-links__link">-->
					<!--										{{"LINK_PRIVACY_POLICY" | translate}}-->
					<!--									</a>-->
					<!--								</li>-->
					<!--								<li class="footer-links__list__item">-->
					<!--									<a href="" class="footer-links__link">-->
					<!--										{{"LINK_BRANDS" | translate}}-->
					<!--									</a>-->
					<!--								</li>-->
					<!--								<li class="footer-links__list__item">-->
					<!--									<a href="" class="footer-links__link">-->
					<!--										{{"LINK_CONTACT_US" | translate}}-->
					<!--									</a>-->
					<!--								</li>-->
					<!--								<li class="footer-links__list__item">-->
					<!--									<a href="" class="footer-links__link">-->
					<!--										{{"LINK_RETURNS" | translate}}-->
					<!--									</a>-->
					<!--								</li>-->
					<!--								<li class="footer-links__list__item">-->
					<!--									<a href="" class="footer-links__link">-->
					<!--										{{"LINK_SITE_MAP" | translate}}-->
					<!--									</a>-->
					<!--								</li>-->
					<!--							</ul>-->
					<!--						</div>-->
					<!--					</div>-->
					<!--					<div class="col-6 col-md-3 col-xl-2">-->
					<!--						<div class="site-footer__widget footer-links">-->
					<!--							<h5 class="footer-links__title">{{"HEADER_MY_ACCOUNT" | translate}}</h5>-->
					<!--							<ul class="footer-links__list">-->
					<!--								<li class="footer-links__list__item">-->
					<!--									<a href="" class="footer-links__link">-->
					<!--										{{"LINK_STORE_LOCATION" | translate}}-->
					<!--									</a>-->
					<!--								</li>-->
					<!--								<li class="footer-links__list__item">-->
					<!--									<a href="" class="footer-links__link">-->
					<!--										{{"HEADER_ORDER_HISTORY" | translate}}-->
					<!--									</a>-->
					<!--								</li>-->
					<!--								<li class="footer-links__list__item">-->
					<!--									<a href="" class="footer-links__link">-->
					<!--										{{"HEADER_WISHLIST" | translate}}-->
					<!--									</a>-->
					<!--								</li>-->
					<!--								<li class="footer-links__list__item">-->
					<!--									<a href="" class="footer-links__link">-->
					<!--										{{"HEADER_NEWSLETTER" | translate}}-->
					<!--									</a>-->
					<!--								</li>-->
					<!--								<li class="footer-links__list__item">-->
					<!--									<a href="" class="footer-links__link">-->
					<!--										{{"HEADER_SPECIAL_OFFERS" | translate}}-->
					<!--									</a>-->
					<!--								</li>-->
					<!--								<li class="footer-links__list__item">-->
					<!--									<a href="" class="footer-links__link">-->
					<!--										{{"LINK_GIFT_CERTIFICATES" | translate}}-->
					<!--									</a>-->
					<!--								</li>-->
					<!--								<li class="footer-links__list__item">-->
					<!--									<a href="" class="footer-links__link">-->
					<!--										{{"LINK_AFFILIATE" | translate}}-->
					<!--									</a>-->
					<!--								</li>-->
					<!--							</ul>-->
					<!--						</div>-->
					<!--					</div>-->
					<!--					<div class="col-12 col-md-6 col-xl-4">-->
					<!--						<div class="site-footer__widget footer-newsletter">-->
					<!--							<h5 class="footer-newsletter__title">{{"HEADER_NEWSLETTER" | translate}}</h5>-->
					<!--							<div class="footer-newsletter__text">{{"TEXT_NEWSLETTER_MESSAGE" | translate}}</div>-->
					<!--							<form action="" class="footer-newsletter__form">-->
					<!--								<label class="sr-only"></label>-->
					<!--								<input class="footer-newsletter__form-input" placeholder="user@example.com">-->
					<!--								<button-->
					<!--									class="footer-newsletter__form-button">{{"BUTTON_SUBSCRIBE" | translate}}</button>-->
					<!--							</form>-->
					<!--							<div-->
					<!--								class="footer-newsletter__text footer-newsletter__text&#45;&#45;social">{{"TEXT_SOCIAL_LINKS_MESSAGE" | translate}}</div>-->
					<!--							<div class="footer-newsletter__social-links social-links">-->
					<!--								<ul class="social-links__list">-->
					<!--									<li class="social-links__item social-links__item&#45;&#45;twitter">-->
					<!--										<a href="">-->
					<!--									<li class="fab fa-twitter"></li>-->
					<!--									</a>-->
					<!--									</li>-->
					<!--									<li class="social-links__item social-links__item&#45;&#45;youtube">-->
					<!--										<a href="">-->
					<!--									<li class="fab fa-youtube"></li>-->
					<!--									</a>-->
					<!--									</li>-->
					<!--									<li class="social-links__item social-links__item&#45;&#45;instagram">-->
					<!--										<a href="">-->
					<!--									<li class="fab fa-instagramm"></li>-->
					<!--									</a>-->
					<!--									</li>-->
					<!--									<li class="social-links__item social-links__item&#45;&#45;rss">-->
					<!--										<a href="">-->
					<!--									<li class="fab fa-rss"></li>-->
					<!--									</a>-->
					<!--									</li>-->
					<!--								</ul>-->
					<!--							</div>-->
					<!--						</div>-->
					<!--					</div>-->
				</div>
			</div>
		</div>
		<div class="site-footer__bottom">
			<div class="container">
				<div class="site-footer__bottom-row">
					<div class="site-footer__copyright">
						<div>
							Developed by
							<a href="https://itflame.am" target="_blank">It Flame</a>
						</div>
						<div><a href="/pages/privacy-policy">{{"LINK_PRIVACY_POLICY" | translate}}</a>
						</div>
					</div>
					<!--					<div class="site-footer__payments">-->
					<!--						<img src="../../../../assets/images/payments.png" alt="">-->
					<!--					</div>-->
				</div>
			</div>
		</div>
	</div>
</div>
