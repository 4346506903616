import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-customer-auth',
	templateUrl: './customer-auth.component.html'
})
export class CustomerAuthComponent implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}

}
