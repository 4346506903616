export const TranslationKeys = {
	AddToCart: "ADD_TO_CART_SUCCESS",
	NeedAccount: "NEED_ACCOUNT",
	DeleteFromCart: "DELETE_FROM_CART_SUCCESS",
	DeleteCart: "DELETE_CART_SUCCESS",
	WishListCreate: "CREATE_WISH_LIST_SUCCESS",
	AddToWishList: "ADD_TO_WISH_LIST_SUCCESS",
	RemoveFromWishList: "REMOVE_FROM_WISH_LIST_SUCCESS",
	RemoveWishList: "REMOVE_WISH_LIST_SUCCESS",
	UploadSuccess: "UPLOAD_SUCCESS",
	UpdateAvatar: "AVATAR_UPDATE_SUCCESS",
	CreateCustomer: "CUSTOMER_CREATE_SUCCESS",
	UpdateCustomer: "CUSTOMER_UPDATE_SUCCESS",
	CreateSupplier: "SUPPLIER_CREATE_SUCCESS",
	UpdateSupplier: "SUPPLIER_UPDATE_SUCCESS",
	CreateShop: "SHOP_CREATE_SUCCESS",
	UpdateShop: "SHOP_UPDATE_SUCCESS",
	DeleteShop: "SHOP_DELETE_SUCCESS",
	InternalError: "Internal server error",
	RequestSent: "SEND_REQUEST_SUCCESS",
	DeleteRequest: "DELETE_REQUEST_SUCCESS",
	UpdateSettings: "SETTINGS_UPDATE_SUCCESS",
	ChangePassword: "PASSWORD_CHANGE_SUCCESS",
	CreateOrder: "ORDER_CREATE_SUCCESS",
	EditOrder: "ORDER_EDIT_SUCCESS",
	DeleteOrder: "ORDER_DELETE_SUCCESS",
	UpdateOrderStatus: "ORDER_STATUS_UPDATE_SUCCESS",
	ProductCreate: "CREATE_PRODUCT_SUCCESS",
	ProductEdit: "EDIT_PRODUCT_SUCCESS",
	ProductDelete: "DELETE_PRODUCT_SUCCESS",
	UserCreate: "USER_CREATE_SUCCESS",
	UserEdit: "USER_EDIT_SUCCESS",
	ApiKeyGenerate: "GENERATE_KEY_SUCCESS",
	ApiKeyDelete: "DELETE_KEY_SUCCESS",
	ProductVisibility: "CHANGE_PRODUCT_VISIBILITY_SUCCESS",
	ImageDelete: "IMAGE_DELETE_SUCCESS",
	DevelopmentStage: "IN_DEVELOPMENT_STAGE",
	ShopArchivedUnarchived: "SHOP_STATE_SUCCESS",
	DeleteAccountSuccess: "DELETE_ACCOUNT_SUCCESS"
}
