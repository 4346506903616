import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrderRoutingModule} from './order-routing.module';
import {ShoppingCartDropDownComponent} from './shopping-cart/shopping-cart-drop-down/shopping-cart-drop-down.component';
import {ShoppingCartPageComponent} from './shopping-cart/shopping-cart-page/shopping-cart-page.component';
import {OrderReviewComponent} from './order/order-review/order-review.component';
import {TranslateModule} from "@ngx-translate/core";
import {HttpClientModule} from "@angular/common/http";
import {DashboardModule} from "../dashboard/dashboard.module";
import {SharedModule} from "../../shared/shared.module";
import {WishListPageComponent} from './wish-list/wish-list-page/wish-list-page.component';
import {NgMaterialModule} from "../../ng-material.module";
import {WishListPopUpComponent} from './wish-list/wish-list-pop-up/wish-list-pop-up.component';
import {ReactiveFormsModule} from "@angular/forms";
import {CreateOrderComponent} from './order/create-order/create-order.component';
import {MatMenuModule} from "@angular/material/menu";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ChangeWishListNameComponent} from './wish-list/change-wish-list-name/change-wish-list-name.component';


@NgModule({
	declarations: [
		ShoppingCartDropDownComponent,
		ShoppingCartPageComponent,
		OrderReviewComponent,
		WishListPageComponent,
		WishListPopUpComponent,
		CreateOrderComponent,
		ChangeWishListNameComponent
	],
    exports: [
        ShoppingCartDropDownComponent,
        ShoppingCartPageComponent,
        OrderReviewComponent,
        WishListPopUpComponent,
        ChangeWishListNameComponent
    ],
	imports: [
		ReactiveFormsModule,
		NgMaterialModule,
		CommonModule,
		OrderRoutingModule,
		HttpClientModule,
		TranslateModule,
		DashboardModule,
		MatMenuModule,
		SharedModule,
		CommonModule,
		CommonModule,
		MatCheckboxModule
	],
	entryComponents: [
		WishListPopUpComponent
	]
})
export class OrderModule {
}
