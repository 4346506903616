import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-timer',
	templateUrl: './timer.component.html'
})
export class TimerComponent implements OnInit {
	@Input() discountEnd;
	endDate: any; // Specify end date here
	days = 0;
	hours = 0;
	minutes = 0;
	seconds = 0;
	ngOnInit() {
		this.endDate = new Date(this.discountEnd);
		this.calculateCountdown();
		setInterval(() => this.calculateCountdown(), 1000);
	}
	calculateCountdown() {
		const now = new Date();
		const distance = this.endDate.getTime() - now.getTime();
		if (distance < 0) {
			this.days = this.hours = this.minutes = this.seconds = 0;
			return;
		}
		this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
		this.hours = Math.floor(
			(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
		);
		this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
	}
}
