<app-block-space layout="spaceship-ledge-height"></app-block-space>

<div class="block order-success">
	<div class="container">
		<div class="order-success__body mt-5">
			<div class="order-success__header">
				<app-icons class="order-success__icon" icon="check-100"></app-icons>
				<h1 class="order-success__title"><b>{{ 'HEADER_ORDER_SUCCESS_TITLE'|translate }}</b></h1>
				<div class="order-success__subtitle">{{ 'HEADER_ORDER_SUCCESS_SUBTITLE'|translate }}. {{ 'TEXT_ORDER_NUMBER'|translate }}: {{order?.orderNumber }} </div>
				<div class="order-success__actions">
					<a routerLink="/" class="btn btn-sm btn-secondary">{{ 'BUTTON_GO_TO_HOMEPAGE'|translate }}</a>
					<a routerLink="/dashboard/customer-dashboard/customer/order-history" class="btn btn-sm btn-secondary m-1">{{ 'HEADER_ORDER_HISTORY'|translate }}</a>
				</div>
			</div>
		</div>

	</div>
</div>

<app-block-space layout="before-footer"></app-block-space>

