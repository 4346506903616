import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {ProductService} from "../../services/product.service";
import {FormControl, FormGroup} from "@angular/forms";
import {AuthService} from "../../../auth/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {AppConfig} from "../../../../core/config/appConfig";
import {Options} from "@angular-slider/ngx-slider";
import {ShopCategory} from "../../../../core/interfaces/products/category";
import {Base} from "../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {ApiResultModel} from "../../../../core/interfaces/api-result.model";
import {GetSupplierResModel} from "../../../../core/interfaces/users/supplier/supplier.model";
import {
	CategoriesContentItemModel
} from "../../../../core/interfaces/categories/categories.model";
import {PartnershipStatusEnum} from "../../../../core/enums/partnerships.enum";
import {GlobalService} from "../../../../core/services/global.service";
import {environment} from "../../../../../environments/environment";

export type PageShopOffCanvasSidebar = 'always' | 'mobile';

export type PageShopLayout =
	'grid' |
	'grid-with-features' |
	'list' |
	'table';

export type PageShopGridLayout =
	'grid-3-sidebar' |
	'grid-4-sidebar' |
	'grid-4-full' |
	'grid-5-full' |
	'grid-6-full';

export type PageShopSidebarPosition = 'start' | 'end' | false;

export interface PageShopData {
	layout: PageShopLayout;
	gridLayout: PageShopGridLayout;
	sidebarPosition: PageShopSidebarPosition;
	category: ShopCategory;
	productsList: any;
}

@Component({
	selector: 'app-products-category-list',
	templateUrl: './product-category-list.component.html'
})
export class ProductCategoryListComponent extends Base implements OnInit {
	minMaxPrice: any;
	layout = 'grid';
	gridLayout: PageShopGridLayout = 'grid-4-sidebar';
	sidebarPosition: PageShopSidebarPosition = 'start';
	products: [];
	range: number;
	showLoading: boolean;
	hasLoadedProducts: boolean;
	category: CategoriesContentItemModel;
	categoryId: any;
	supplier: GetSupplierResModel;
	currentCategory: string;
	shopId: string;
	form: FormGroup = new FormGroup({
		range: new FormControl(),
		address: new FormControl()
	});
	supplierId: string;
	minPrice: number;
	maxPrice = null;
	categories: any[] = [];
	query: any = {};
	options: Options;
	partnershipStatus = PartnershipStatusEnum;
	imagePath = environment.imagePath;
	shop: any;

	get offCanvasSidebar(): PageShopOffCanvasSidebar {
		return ['grid-4-full', 'grid-5-full', 'grid-6-full'].includes(this.gridLayout) ? 'always' : 'mobile';
	}

	get hasSidebar(): boolean {
		return this.sidebarPosition && ['grid-3-sidebar', 'grid-4-sidebar'].includes(this.gridLayout);
	}

	productName: Observable<string>;
	isLoading: boolean;
	localStorage = localStorage;
	dateFormat = AppConfig.constants.shortDate

	constructor(private activateRouter: ActivatedRoute,
				private productService: ProductService,
				private authService: AuthService,
				private globalService: GlobalService,
				private router: Router,
				public activatedRoute: ActivatedRoute,
				public translate: TranslateService) {
		super();
	}

	ngOnInit(): void {
		this.minPrice = 0;
		this.query = this.activateRouter.snapshot.queryParams;
		this.shop = localStorage.getItem('shop') != 'undefined' ? JSON.parse(localStorage.getItem('shop')) : {};


		this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.query = res;
			this.query = {
				...res,
				shopId: this.shop?.id
			}

			if (!this.currentCategory) {
				this.currentCategory = this.query.categories;
			}

			this.getProducts(this.query);

			if (this.query.categories?.length) {
				this.getCategoryById(this.query.categories);
			}

			if (res.supplier) {
				this.supplierId = res.supplier;
			}

			this.authService.changeShopAddress.pipe(takeUntil(this.destroy$)).subscribe(res => {
				this.shopId = res?.id;
				this.getProducts(this.query);
				if (this.supplierId) {
					this.getSupplier(this.supplierId);
				}
			})
		})

		this.productService.onShowAllCategories.pipe(takeUntil(this.destroy$)).subscribe(res => {
			if (res) {
				this.hasLoadedProducts = false;
			}
		})

		this.productService.resetProductFilter.pipe(takeUntil(this.destroy$)).subscribe(res => {
			if (res) {
				this.options = {
					floor: this.options.floor,
					ceil: this.options.ceil
				};
				this.getProducts({categories: this.currentCategory});
				this.router.navigate([], {queryParams: {categories: this.currentCategory}});
			}
		})

		this.getProductsByFormChange();
	}

	public getSupplier(id: string): void {
		this.isLoading = true;
		this.globalService.getSupplier(id, this.shopId).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<GetSupplierResModel>) => {
				this.supplier = res.payload;
				this.isLoading = false;
			}
		})
	}

	private getCategoryById(categoryId?: string): void {
		this.globalService.getCategoriesById(categoryId).pipe(takeUntil(this.destroy$)).subscribe((res: ApiResultModel<CategoriesContentItemModel>) => {
			this.category = res.payload;
			this.productService.category.next(this.category);
		})
	}

	private getProducts(data): void {
		this.isLoading = true;
		this.productService.getProducts(data).pipe(takeUntil(this.destroy$)).subscribe({
			next: res => {
				this.products = res.payload.content.products;

				if (!this.maxPrice) {
					this.maxPrice = res.payload.content?.aggregations?.max_price.value;

					this.options = {
						floor: res.payload.content?.aggregations?.min_price.value,
						ceil: res.payload.content?.aggregations?.max_price.value
					};

				} else if (this.currentCategory !== data.categories) {

					this.currentCategory = data.categories;
					this.query = {
						categories: data.categories
					}
					this.maxPrice = res.payload.content.aggregations.max_price.value;
					this.options = {
						floor: res.payload.content.aggregations.min_price.value,
						ceil: res.payload.content.aggregations.max_price.value
					};
					this.router.navigate([], {queryParams: this.query});
				}
				this.hasLoadedProducts = true;
				this.isLoading = false;
			}
		})

	}

	getProductsByFormChange(): void {
		this.form.valueChanges.pipe().pipe(takeUntil(this.destroy$)).subscribe((res) => {
			if (res.range) {

				this.query = {
					...this.query,
					minPrice: res.range[0],
					maxPrice: res.range[1]
				}
			}
			if (res.address?.length) {
				let country;
				let region;
				let city;
				res.address.forEach(el => {
					switch (el.types[0]) {
						case 'locality':
							city = el.long_name;
							break;
						case 'administrative_area_level_1':
							region = el.long_name;
							break;
						case 'country':
							country = el.long_name;
					}
				})
				this.query = {
					...this.query,
					city: city,
					region: region,
					country: country
				}
			}
			this.router.navigate([], {queryParams: this.query});
		})
	}

	sendRequest(id: string): void {
		this.globalService.sendReqToSupplier(id, this.shopId).pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.getProducts(this.query);
			this.getSupplier(id);
		});
	}

}
