import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FilterRows} from "../../../../../core/data/filter.data";
import {ActivatedRoute} from "@angular/router";
import {FormGroup} from "@angular/forms";
import {ProductService} from "../../../services/product.service";
import {PageEvent} from "@angular/material/paginator";
import {Options} from "@angular-slider/ngx-slider";

@Component({
	selector: 'app-widget-filters',
	templateUrl: './widget-filters.component.html'
})
export class WidgetFiltersComponent implements OnInit {

	@Input() form: FormGroup;
	@Input() offcanvasSidebar: 'always' | 'mobile' = 'always';
	productName: string;
	@Input() category: any;
	filters = FilterRows;
	@Input() hasLoadedProducts: boolean;
	@Input() options: Options;

	categoryId: number;

	constructor(private activateRouter: ActivatedRoute,
				private productService: ProductService) {
	}

	ngOnInit(): void {

		if (this.activateRouter.snapshot.queryParams) {
			const data = JSON.parse(this.activateRouter.snapshot.queryParams.data || null);
			if (data) {
				this.productName = data.name;
			}
		}
	}

	ngOnChanges(simpleChanges: SimpleChanges) {
		this.category = simpleChanges?.category?.currentValue;
	}

	trackBySlug(index: number, filter): string {
		return filter.slug;
	}

	reset() {
		// window.location.reload();
		// this.form.reset();
		this.productService.resetProductFilter.next(true);
	}
}
