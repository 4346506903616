import {Injectable} from '@angular/core';
import {AppConfig} from "../../../core/config/appConfig";
import {HttpClient} from "@angular/common/http";
import {ReplaySubject, Subject} from "rxjs";
import {Router} from "@angular/router";

@Injectable({providedIn: "root"})
export class ProductService {

	changeCategoryId: Subject<any> = new Subject();
	onShowAllCategories: ReplaySubject<any> = new ReplaySubject<any>(1);
	minMaxPrice: ReplaySubject<any> = new ReplaySubject<any>(1);
	resetProductFilter: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
	minMaxReward: ReplaySubject<any> = new ReplaySubject<any>(1);
	category: ReplaySubject<any> = new ReplaySubject<any>(1);

	layoutButtons = [
		{ layout: 'grid', icon: 'layout-grid-16' },
		// { layout: 'grid-with-features', icon: 'layout-grid-with-details-16' },
		{ layout: 'list', icon: 'layout-list-16' },
		{ layout: 'table', icon: 'layout-table-16' },
	];

	readonly defaultOptions: Required<any> = {
		page: 1,
		limit: 16,
		sort: 'default',
		filters: {},
	};

	constructor(private http: HttpClient,
				private router: Router) {
	}

	getProducts(query) {
		return this.http.post<any>(`${AppConfig.baseUrl}${AppConfig.endpoints.supplier.products.productsSearch}`, {}, {params: query});
	}

	goToCategory(query) {
		this.router.navigate(['product/product-category-list'], {queryParams: query} )
	}

}
