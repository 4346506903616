<form [formGroup]="mapForm">
	<mat-form-field appearance="outline" class="mat-form-field d-flex flex-column form-group">
		<mat-label>{{ (label ? label : 'INPUT_FIRST_ADDRESS_LABEL') | translate }}</mat-label>
		<input matInput style="width: 100%"
			   autocorrect="off"
			   autocapitalize="off"
			   spellcheck="off"
			   type="text"
			   formControlName="mapAddress"
			   placeholder="{{'ENTER_LOCATION' | translate}}"
			   #search required>
		<mat-error *ngIf="showError">{{ 'ERROR_FORM_REQUIRED' | translate }}</mat-error>
	</mat-form-field>
</form>

<div *ngIf="showMap" class="map" #map></div>

