import {
	ChangeDetectorRef,
	Component,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit, SimpleChanges,
	ViewChild,
} from '@angular/core';
import { CarouselComponent } from 'ngx-owl-carousel-o';
import { Subject } from 'rxjs';
import { OwlCarouselOConfig } from 'ngx-owl-carousel-o/lib/carousel/owl-carousel-o-config';
import {SectionHeaderLink} from "../../../shared/components/section-header/section-header.component";
import {Router} from "@angular/router";
import {AppConfig} from "../../../core/config/appConfig";

const carouselLayoutOptions = {
	grid: {
		items: 4,
		responsive: {
			1350: { items: 4, margin: 20 },
			1110: { items: 3, margin: 20 },
			930: { items: 3, margin: 16 },
			690: { items: 2, margin: 16 },
			0: { items: 1, margin: 16 },
		},
	},
	list: {
		items: 2,
		responsive: {
			1350: { items: 2, margin: 20 },
			930: { items: 2, margin: 16 },
			0: { items: 1, margin: 16 },
		},
	},
};

export type PostCardLayout = 'list' | 'grid' | 'grid-sm';

@Component({
	selector: 'app-block-posts',
	templateUrl: './block-posts.component.html'
})
export class BlockPostsComponent implements OnChanges, OnInit, OnDestroy {
	private destroy$: Subject<void> = new Subject<void>();

	showCarousel = true;
	imagePath = AppConfig.imagePath;
	carouselOptions!: Partial<OwlCarouselOConfig>;

	@Input() post;

	@Input() layout?: PostCardLayout;

	@HostBinding('class.post-card') classPostCard = true;

	@HostBinding('class.post-card--layout--list') get classPostCardLayoutList(): boolean {
		return this.layout === 'list';
	}

	@HostBinding('class.post-card--layout--grid') get classPostCardLayoutGrid(): boolean {
		return this.layout === 'grid';
	}

	@HostBinding('class.post-card--layout--grid-sm') get classPostCardLayoutGridSm(): boolean {
		return this.layout === 'grid-sm';
	}

	@Input() label: string = '';

	@Input() loading = false;

	@Input() links: SectionHeaderLink[] = [];

	@Input() posts;

	@HostBinding('class.block') classBlock = true;

	@HostBinding('class.block-posts-carousel') classBlockPostsCarousel = true;

	@HostBinding('class.block-posts-carousel--loading') get classBlockPostsCarouselLoading(): boolean {
		return this.loading;
	}

	@HostBinding('class.block-posts-carousel--layout--grid') get classBlockPostsCarouselLayoutGrid(): boolean {
		return this.layout === 'grid';
	}

	@HostBinding('class.block-posts-carousel--layout--list') get classBlockPostsCarouselLayoutList(): boolean {
		return this.layout === 'list';
	}

	@ViewChild(CarouselComponent) carousel!: CarouselComponent;

	constructor(
		private cd: ChangeDetectorRef,
		private router: Router
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['posts']) {
			setTimeout(() => {
				this.initOptions();

				this.showCarousel = false;
				this.cd.detectChanges();
				this.showCarousel = true;
			}, 0);
		}
	}

	ngOnInit(): void {
		this.initOptions();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	initOptions(): void {
		this.carouselOptions = Object.assign({
			dots: false,
			margin: 20,
			loop: true,
		}, carouselLayoutOptions[this.layout]);
	}

	goToPostPage(post): void {
		this.router.navigate(['pages/block-post'], {queryParams: {id: post.id}});
	}

}
