<div class="d-flex justify-content-center">
	<div class="timer__part">
		<div class="timer__part-value timer__part-value--days">{{days}}</div>
		<div class="timer__part-label">{{ 'TEXT_TIMER_DAYS'|translate }}</div>
	</div>
	<div class="timer__dots"></div>
	<div class="timer__part">
		<div class="timer__part-value timer__part-value--hours">{{hours}}</div>
		<div class="timer__part-label">{{ 'TEXT_TIMER_HOURS'|translate }}</div>
	</div>
	<div class="timer__dots"></div>
	<div class="timer__part">
		<div class="timer__part-value timer__part-value--minutes">{{minutes}}</div>
		<div class="timer__part-label">{{ 'TEXT_TIMER_MINUTES'|translate }}</div>
	</div>
	<div class="timer__dots"></div>
	<div class="timer__part">
		<div class="timer__part-value timer__part-value--seconds">{{seconds}}</div>
		<div class="timer__part-label">{{ 'TEXT_TIMER_SECONDS'|translate }}</div>
	</div>
</div>

