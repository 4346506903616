<app-block-header></app-block-header>

<div class="block-split" *ngIf="!isLoading">
	<div class="container mt-5">
		<div class="block-split__row row no-gutters">
			<div>
				<div class="product product--layout--full">
					<div class="product__body">
						<div class="product__card product__card--one"></div>
						<div class="product__card product__card--two"></div>
						<app-product-gallery *ngIf="product?.gallery" class="product__gallery" [layout]="galleryLayout"
											 [image]="product?.mainImage?.filename"
											 [images]="product?.gallery"
						></app-product-gallery>

						<div class="product__header">
							<h1 class="product__title">{{product?.name}}</h1>
							<div class="product__subtitle"></div>
							<a *ngIf="product?.supplier"
							   (click)="goToSupplier(product?.supplier.id)">{{product?.supplier.companyName}}</a>
						</div>
						<div class="product__main"></div>
						<div class="product__info">
							<form [formGroup]="addToCardForm">
								<div class="product__info-card">
									<div class="product__info-body">
										<div class="product__badge tag-badge tag-badge--sale"
											 *ngIf="product?.discount > 0">{{ 'TEXT_BADGE_SALE'|translate }}</div>
										<div class="product__prices-stock">
											<div *ngIf="product?.price" class="product__prices">
												<ng-container *ngIf="product?.discount > 0">
													<div
														class="product__price product__price--old">{{product?.price + ' ₽'}}</div>
													<div class="product__price product__price--new">
														{{price | currency: [''] }}
														₽
													</div>
												</ng-container>
												<ng-container *ngIf="!product?.discount || product?.discount === 0">
													<div
														class="product__price product__price--current">{{price}}
														₽
													</div>
												</ng-container>
											</div>
										</div>
										<div class="product__meta">
											<table>
												<tbody>
												<tr>
													<th>{{ 'INPUT_REWARD_LABEL'|translate }}</th>
													<td>{{ product?.reward}}<span style="color: red"> B</span></td>
												</tr>
												<tr>
													<th>{{ 'TABLE_SKU'|translate }}</th>
													<td>{{ product?.sku }}</td>
												</tr>
<!--												<ng-container>-->
<!--													<tr>-->
<!--														<th>{{ 'TABLE_COUNTRY'|translate }}</th>-->
<!--														<td>{{product?.supplier.__address__.country }}</td>-->
<!--													</tr>-->
<!--												</ng-container>-->
												<tr>
													<th>{{'PRODUCT_COLOR' | translate}}</th>
													<td>{{product?.color}}</td>
												</tr>
												<tr>
													<th>{{'PRODUCT_WIDTH' | translate}}</th>
													<td>{{product?.width}}</td>
												</tr>
												<tr>
													<th>{{'PRODUCT_HEIGHT' | translate}}</th>
													<td>{{product?.height}}</td>
												</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div class="product__form">
										<div class="product-form__body">
											<div class="product-form__row">
												<div class="product-form__title">{{'INPUT_UNIT_LABEL' | translate}}</div>
												<div class="product-form__control">
													<div class="input-radio-label">
														<div class="input-radio-label__list">
															<label  *ngFor="let unit of product?.properties?.unit; let i = index"
																   class="input-radio-label__item">
																<input
																	type="radio"
																	name="material"
																	class="input-radio-label__input"
																	[checked]="product?.unit?.unitCode && unit?.unitCode === product?.unit?.unitCode"
																	(change)="setUnit(unit)"
																>
																<span
																	class="input-radio-label__title">{{ unit.name }}</span>
															</label>
														</div>
													</div>
												</div>
											</div>
												</div>
											</div>
											<div *ngIf="product?.price" class="product__actions">
												<div class="product__actions-item product__actions-item--quantity">
													<div class="input-number">
														<input
															onlyNumber
															(input)="steQuantityByKeyDown($event.target.value)"
															oninput="this.value = Math.abs(this.value)"
															min=1
															class="input-number__input form-control"
															type="number"
															formControlName="quantity">
														<div class="input-number__add" (mousedown)="plus()"></div>
														<div class="input-number__sub" (mousedown)="minus()"></div>
													</div>
												</div>
												<div class="product__actions-item product__actions-item--addtocart">
													<button
														[disabled]="!addToCardForm.get('properties').get('unit').get('unitId').value"
														class="btn btn-primary btn-block"
														style="width: 100%;"
														(click)="addToCard()"
													>
														{{ 'BUTTON_ADD_TO_CART'|translate }}
													</button>
												</div>
												<button
													type="button"
													class="product__actions-item product__actions-item--wishlist"
													(click)="addToWishList(product)"
												>
													<app-icons icon="wishlist-16"></app-icons>
													<span>{{ 'BUTTON_ADD_TO_WISHLIST'|translate }}</span>
												</button>
											</div>
										</div>
							</form>
						</div>

						<app-product-tabs class="product__tabs" #tabs>
							<app-product-tab id="product-tab-description" [label]="'TEXT_TAB_DESCRIPTION'|translate">
								<div class="typography" [innerHTML]="product?.description"></div>
							</app-product-tab>
						</app-product-tabs>
					</div>
				</div>
			</div>
		</div>
		<div class="block-space block-space--layout--before-footer"></div>
	</div>
</div>
<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
