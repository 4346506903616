import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {GlobalService} from "../../../../core/services/global.service";
import {TranslateService} from "@ngx-translate/core";
import {Base} from "../../../../core/base";

@Component({
	selector: 'app-address-form',
	templateUrl: './address-form.component.html'
})
export class AddressFormComponent extends Base implements OnInit {
	@Input() deliveryAddressForm: FormGroup;
	@Input() legalAddressForm: FormGroup;
	@Input() customerAddressForm: FormGroup;
	@Input() useMap: boolean;
	@Input() isShopAddresses: boolean;
	@Input() isCustomerAddresses: boolean;
	@Output() addressFromMap = new EventEmitter;
	@Input() showError: boolean;
	@Input() showSecondNumber = true;
	@Input() mapForm: FormGroup;
	suggestions: string[] = [];
	countries: any[] = [];
	provinces: any[] = [];
	cities: any[] = [];
	selectedCountry: string;
	selectedProvince: string;

	constructor(private globalService: GlobalService,
				public translate: TranslateService) {
		super();
	}

	ngOnInit(): void {
		// this.getYandexAddresses();
		// this.getCities();
		// this.getCountries();
		// this.listenToCountryChange();
	}

	private getYandexAddresses(): void {
		// this.form.get('address_1').valueChanges
		// 	.pipe(
		// 		switchMap((res: string) => {
		// 			const selectedCity = this.form.get('city').value;
		// 			return this.globalService.getYandexAddresses(
		// 				`${this.selectedCountry + ',' + this.selectedProvince + ',' + selectedCity},${res}`);
		// 		})
		// 	)
		// 	.subscribe((response) => {
		// 		this.suggestions = response.data.results;
		// 	});
	}

	private getCountries(): void {
		// this.globalService.getCountries().pipe(takeUntil(this.destroy$)).subscribe({
		// 	next: (res) => {
		// 		this.countries = res.payload;
		// 	}
		// })
	}

	private getRegionsByCountry(id: number): void {
		// this.globalService.getRegionsByCountry(id).pipe(takeUntil(this.destroy$))
		// 	.subscribe({
		// 		next: (res) => {
		// 			this.provinces = res.payload;
		// 		}
		// 	})
	}

	private getCities(): void {
		// this.form.get('city').valueChanges
		// 	.pipe(
		// 		switchMap((res: string) => {
		// 			return this.globalService.getYandexCities(
		// 				`${this.selectedCountry + ',' + this.selectedProvince},${res}`);
		// 		})
		// 	)
		// 	.subscribe((response) => {
		// 		this.cities = response.data.results;
		// 	});
	}

	private listenToCountryChange(): void {
		// this.form.get('country').get('id').valueChanges.pipe(takeUntil(this.destroy$))
		// 	.subscribe({
		// 		next: (res: number) => {
		// 			this.getRegionsByCountry(res);
		// 		}
		// 	})
	}

	onChangeProvince(event: string): void {
		// this.selectedProvince = event;
	}

	onChangeCountry(event: string): void {
		// this.selectedCountry = event;
	}

	private getGeoCode(params): void {
		// this.globalService.getGeoCode(params).then(
		// 	res => {
		// 		this.form.get('gpsCoordinates').get('longitude').setValue(res.data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(' ')[0]);
		// 		this.form.get('gpsCoordinates').get('latitude').setValue(res.data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(' ')[1]);
		// 	}
		// )
	}

	onChangeAddress(event: string): void {
		// this.getGeoCode(this.selectedProvince + ',' + this.form.get('city').value + ',' + event)
	}
}
