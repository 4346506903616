<div class="card">
	<div class="d-flex justify-content-between">
		<div class="card-header">
			<h2>{{orders | translate}}</h2>
		</div>
		<div class="m-lg-2">
			<mat-form-field appearance="outline">
				<mat-label>{{'SEARCH' | translate}}</mat-label>
				<input matInput (keyup)="applyFilter($event.target.value)" #input>
			</mat-form-field>
		</div>
	</div>
	<div class="card-divider"></div>
	<div class="card-table">

		<div class="table-responsive-sm">

			<table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

				<ng-container matColumnDef="number">
					<th mat-header-cell *matHeaderCellDef mat-sort-header="o.id"
						style="color: black; font-weight: bold"> {{'TABLE_NUMBER' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.orderNumber}} </td>
				</ng-container>

				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef mat-sort-header="o.status"
						style="color: black; font-weight: bold"> {{'TABLE_STATUS' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.status | translate}} </td>
				</ng-container>

				<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef mat-sort-header="o.createdAt"
						style="color: black; font-weight: bold"> {{'TABLE_DATE' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.createdAt | date: dateFormat : '' : localStorage.getItem('locale')}} </td>
				</ng-container>

				<ng-container matColumnDef="total">
					<th mat-header-cell *matHeaderCellDef mat-sort-header="o.orderTotal"
						style="color: black; font-weight: bold"> {{'TABLE_TOTAL' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.orderTotal | number: '1.0-2'}} ₽</td>
				</ng-container>

				<ng-container matColumnDef="reward">
					<th mat-header-cell *matHeaderCellDef mat-sort-header="o.rewardTotal"
						style="color: black; font-weight: bold"> {{'TABLE_REWARD' | translate}} </th>
					<td mat-cell *matCellDef="let row"> {{row.rewardTotal | number: '1.0-2'}} <span style="color: red"> B</span></td>
				</ng-container>

				<ng-container matColumnDef="action">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let row">
						<button (click)="view(row)" style="border: none">
							<mat-icon>wysiwyg</mat-icon>
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell" colspan="4"><h2>{{ 'TEXT_NO_MATCHING_ITEMS_TITLE'|translate }}{{input.value}}</h2></td>
				</tr>
			</table>
			<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
			<mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]" [length]="pagination?.totalRecords"
						   (page)="pageEvent = $event; onPaginateChange($event)" showFirstLastButtons></mat-paginator>
		</div>
	</div>
</div>





