<div class="block-space block-space--layout--after-header"></div>

<div class="block">
	<div class="container container--max--lg">
		<div class="d-flex flex-row justify-content-center">
			<div class="col-md-6">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</div>

<div class="block-space block-space--layout--before-footer"></div>
