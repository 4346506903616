import {Component, OnInit} from '@angular/core';
import {ColorType, colorType, FilterColor} from "../../../../../core/data/color";

@Component({
	selector: 'app-filter-color',
	templateUrl: './filter-color.component.html'
})
export class FilterColorComponent implements OnInit {

	value: string[] = [];
	filter = FilterColor;

	changeFn: (_: string[]) => void = () => {};

	constructor() {
	}

	ngOnInit(): void {
	}

	colorType(color: string): ColorType {
		return colorType(color);
	}

	isItemChecked(item): boolean {
		return this.value.includes(item.slug);
	}

	onItemChange(item, event: Event): void {
		const checked = (event.target as HTMLInputElement).checked;

		if (checked && !this.isItemChecked(item)) {
			this.value = [...this.value, item.slug];
			this.changeFn(this.value);
		}
		if (!checked && this.isItemChecked(item)) {
			this.value = this.value.filter(x => x !== item.slug);
			this.changeFn(this.value);
		}
	}

}
