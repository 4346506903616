import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {DashboardService} from "../../../dashboard.service";
import {AppConfig} from "../../../../../core/config/appConfig";
import {Base} from "../../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {ApiResultModel} from "../../../../../core/interfaces/api-result.model";
import {PaginationModel} from "../../../../../core/interfaces/pagination/pagination.model";
import {ProductsDataModel} from "../../../../../core/interfaces/products/products-data.model";

@Component({
	selector: 'app-payments-list',
	templateUrl: './payments-list.component.html'
})
export class PaymentsListComponent extends Base implements OnInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	isLoading: boolean;
	@Input()pagination: PaginationModel;
	@Output() paginate: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
	pageEvent: PageEvent;
	dateFormat = AppConfig.constants.longDate;
	query: string;
	@Input()dataSource: MatTableDataSource<any>;
	displayedColumns: string[] = [ 'order', 'deposit', 'reward', 'createdAt', 'total-deposit', 'total-reward', 'type'];

	constructor(private dashboardService: DashboardService) {
		super();
	}

	ngOnInit(): void {
	}

	applyFilter(query: string): void {
		this.query = query;
		this.dashboardService.getPayments(this.paginator.pageIndex - 1, this.paginator.pageSize, 'p.createdAt', 'desc', query)
			.pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<any>) => {
				this.pagination = res.payload.pagination;
				this.dataSource = new MatTableDataSource(res.payload.content);
				this.isLoading = false;
			},
			error: () => {
				this.isLoading = false;
			}
		})
	}

	onPaginateChange(event: PageEvent): void {
		event.pageIndex = event.pageIndex + 1;
		this.paginate.emit(event);
		this.dashboardService.getPayments(event.pageIndex, event.pageSize).pipe(takeUntil(this.destroy$)).subscribe((res: ApiResultModel<any>) => {
			this.pagination = res.payload.pagination;
			this.dataSource = new MatTableDataSource(res.payload.content);
		})
	}

	announceSortChange(event: { active: string, direction: string }): void {
		this.dashboardService.getPayments(this.paginator.pageIndex - 1, this.paginator.pageSize, event.active, event.direction, this.query).pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<ProductsDataModel>) => {
				this.pagination = res.payload.pagination;
				this.dataSource = new MatTableDataSource(res.payload.content);
				this.isLoading = false;
			},
			error: () => {
				this.isLoading = false;
			}
		});
	}
}
