import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {CardService} from "../../../modules/order/shopping-cart/card.service";
import {AuthService} from "../../../modules/auth/auth.service";
import {NavigationService} from "../../../core/services/navigation-service";
import {ShoppingCartReqModel} from "../../../core/interfaces/shopping-cart/shopping-cart.model";
import {ProductsContentItemModel} from "../../../core/interfaces/products/products-data.model";
import {ShopModel} from "../../../core/interfaces/shop.model";
import {Base} from "../../../core/base";
import {takeUntil} from "rxjs/operators";
import {QuickviewService} from "../../../core/services/quick-view.service";
import {WishListService} from "../../../modules/order/wish-list/wish-list.service";
import {GlobalService} from "../../../core/services/global.service";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import {ProductService} from "../../../modules/product/services/product.service";
export type ProductCardElement = 'actions' | 'status-badge' | 'meta' | 'features' | 'buttons' | 'list-buttons';

export type ProductCardLayout = 'grid' | 'list' | 'table' | 'horizontal';

@Component({
	selector: 'app-product-card',
	templateUrl: './product-card.component.html'
})

export class ProductCardComponent extends Base implements OnInit {

	@Input() product: ProductsContentItemModel;
	@Input() image: string;
	@Input() blockSale: boolean;
	@Input() layout?: ProductCardLayout;
	@Input() exclude: ProductCardElement[] = [];
	imagePath = environment.imagePath;
	notShipped: boolean;
	shop: ShopModel;
	shopId: string;

	@HostBinding('class.product-card') classProductCard = true;

	@HostBinding('class.product-card--layout--grid') get classProductCardLayoutGrid(): boolean {
		return this.layout === 'grid';
	}

	@HostBinding('class.product-card--layout--list') get classProductCardLayoutList(): boolean {
		return this.layout === 'list';
	}

	@HostBinding('class.product-card--layout--table') get classProductCardLayoutTable(): boolean {
		return this.layout === 'table';
	}

	@HostBinding('class.product-card--layout--horizontal') get classProductCardLayoutHorizontal(): boolean {
		return this.layout === 'horizontal';
	}

	showingQuickview = false;
	cartInProgress = false;
	wishlistInProgress = false;
	showWishListPopup: boolean;

	constructor(public dialog: MatDialog,
				public cardService: CardService,
				private quickview: QuickviewService,
				private globalService: GlobalService,
				private productService: ProductService,
				private authService: AuthService,
				private wishListService: WishListService,
				private navigationService: NavigationService
	) {
		super();
	}

	ngOnInit(): void {
		if (localStorage.getItem('shop') !== 'undefined') {
			this.shop = JSON.parse(localStorage.getItem('shop'));
		}

		this.authService.changeShopAddress.pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.shopId = res?.id;
		})
	}

	showQuickView(e: Event): void {
		e.stopPropagation();

		if (this.showingQuickview) {
			return;
		}

		this.showingQuickview = true;
		this.quickview.show(this.product).subscribe({
			complete: () => {
				this.showingQuickview = false;
			},
		});
	}

	addToCard(e: Event, id: string): void {
		const body: ShoppingCartReqModel = {
			shop: this.shopId,
			productId: id,
			quantity: 1,
			properties: {
				unit: {
					unitId: this.product?.properties?.unit[0]?.unitId,
					name: this.product?.properties?.unit[0]?.name,
					refId: this.product?.properties?.unit[0]?.refId,
					contents: this.product?.properties?.unit[0]?.contents
				}
			}
		}
		e.stopPropagation();
		if (this.product?.properties?.unit?.length > 1) {
			if (this.showingQuickview) {
				return;
			}

			this.showingQuickview = true;
			this.quickview.show(this.product).subscribe({
				complete: () => {
					this.showingQuickview = false;
				},
			});
		} else {
			this.cardService.addToCard(body).pipe(takeUntil(this.destroy$)).subscribe(() => {
				this.getCards();
			});
		}
	}

	private getCards(): void {
		this.cardService.getCards({shopId: this.shopId}).pipe(takeUntil(this.destroy$)).subscribe(() => {})
	}

	addToWishList(e: Event, product: any) : void {
		e.stopPropagation();
		if (this.showWishListPopup) {
			return;
		}

		this.showWishListPopup = true;
		this.wishListService.show(product).subscribe({
			complete: () => {
				this.showWishListPopup = false;
			},
		});
	}

	goToProduct(id: string): void {
		this.navigationService.goToProduct(id);
	}

    goToSupplier(id: number): void {
		this.productService.goToCategory({supplier: id});
	}
}
