import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ProductService} from "../../../services/product.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-widget-category-list',
	templateUrl: './widget-category-list.component.html'
})
export class WidgetCategoryListComponent implements OnInit {

	@Input() categories;

	constructor(public router: Router,
				private productService: ProductService,
				public translate: TranslateService) {
	}

	ngOnInit(): void {
	}

	getChildren(category) {
		return category.children || [];
	}

	goToCategory(id) {
		this.productService.goToCategory({categories: id});
	}

}
