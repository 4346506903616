import {Component, forwardRef, OnInit} from '@angular/core';
import {FormControl, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Base} from "../../../../../core/base";
import {takeUntil} from "rxjs/operators";

@Component({
	selector: 'app-filter-shop-address',
	templateUrl: './filter-shop-address.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FilterShopAddressComponent),
			multi: true,
		},
	],
})

export class FilterShopAddressComponent extends Base implements OnInit {

	control: FormControl;
	shopAddress: string[] = [];

	mapForm: FormGroup = new FormGroup({
		mapAddress: new FormControl()
	})

	changeFn: (_: string[]) => void = () => {
	};

	touchedFn: () => void = () => {
	};

	constructor() {
		super();
	}

	ngOnInit(): void {
		this.control = new FormControl();

		this.control.valueChanges.pipe(
			takeUntil(this.destroy$)
		).subscribe(value => {
			this.changeFn(value);
			this.touchedFn();
		});
	}

	registerOnChange(fn: any): void {
		this.changeFn = fn;
	}

	registerOnTouched(fn: any): void {
		this.touchedFn = fn;
	}

	writeValue(value: any): void {
		// this.control.setValue(this.shopAddress, { emitEvent: false });
	}

	getShopAddressFromMap(event): void {
		this.control.setValue(event.address);
		this.shopAddress = [];
	}

}
