import {Component, OnInit} from '@angular/core';
import {ProductService} from "../../services/product.service";
import {TranslateService} from "@ngx-translate/core";
import {Base} from "../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {ApiResultModel} from "../../../../core/interfaces/api-result.model";
import {
	CategoriesContentItemModel,
	GetCategoriesResModel
} from "../../../../core/interfaces/categories/categories.model";
import {GlobalService} from "../../../../core/services/global.service";

@Component({
	selector: 'app-category-list',
	templateUrl: './category-list.component.html'
})
export class CategoryListComponent extends Base implements OnInit {
	categoryData: CategoriesContentItemModel[] = [];
	isLoading: boolean;

	constructor(private globalService: GlobalService,
				private productService: ProductService,
				public translate: TranslateService) {
		super();
	}

	ngOnInit(): void {
		this.getCategories();
	}

	private getCategories(): void {
		this.isLoading = true;
		this.globalService.getCategories().pipe(takeUntil(this.destroy$)).subscribe({
			next: (res: ApiResultModel<GetCategoriesResModel>) => {
				this.categoryData = res.payload.content;
				this.isLoading = false;
			}
		})
	}

	goToCategory(id): void {
		this.productService.goToCategory({categories: id});
		this.productService.changeCategoryId.next(id);
	}
}
