import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ShopAddressesService implements OnDestroy {
	private destroy$: Subject<void> = new Subject();
	private abortPrevious$: Subject<void> = new Subject<void>();
	private showShopPopupSubject$: Subject<any> = new Subject();

	showShopsPopup$: Observable<any> = this.showShopPopupSubject$.pipe(takeUntil(this.destroy$));

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	show(shop?): Observable<void> {
		this.abortPrevious$.next();

		return timer(350).pipe(
			tap(() => this.showShopPopupSubject$.next(shop)),
			map(() => {}),
			takeUntil(this.abortPrevious$),
		);
	}
}
