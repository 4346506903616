<ng-container *ngIf="showGallery">
	<div class="product-gallery__featured">
		<div class="image image--type--product">
			<a class="image__body" target="_blank">
				<img class="image__tag" [src]="image ? (imagePath + image) : images?.length ? (imagePath + images[0]?.medium?.url)  : 'assets/images/categories/category-1.jpg'">
			</a>
		</div>
	</div>
	<div *ngIf="images?.length > 1" class="product-gallery__thumbnails">
		<owl-carousel-o [options]="carouselOptions" appOwlPreventClick>
			<ng-template *ngFor="let slide of images" carouselSlide>
				<div
					class="product-gallery__thumbnails-item image image--type--product"
					[class.product-gallery__thumbnails-item--active]="slide === currentItem"
					(click)="onThumbnailImageClick(slide.medium?.url)"
				>
					<div class="image__body">
						<img class="image__tag" [src]="(imagePath) + slide.thumbnail?.url" alt="">
					</div>
				</div>
			</ng-template>
		</owl-carousel-o>
	</div>
</ng-container>

