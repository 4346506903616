import {Component, HostBinding, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Observable} from "rxjs";
import {PageShopGridLayout} from "../product-category-list/product-category-list.component";
import {ShopSidebarService} from "../../services/sidebar.service";
import {ProductService} from "../../services/product.service";
import {FormControl} from "@angular/forms";
import {NavigationService} from "../../../../core/services/navigation-service";

@Component({
	selector: 'app-products-view',
	templateUrl: './products-view.component.html'
})
export class ProductsViewComponent implements OnInit {

	@Input() offCanvasSidebar: 'always' | 'mobile' = 'mobile';
	@Input() layout = 'grid';
	@Input() gridLayout: PageShopGridLayout = 'grid-4-sidebar';
	@Input() productId;
	@Input() products;
	@Input() totalPages;

	@HostBinding('class.products-view') classProductsView = true;

	shownProducts;
	layoutButtons;

	sortControl: FormControl;
	limitControl: FormControl;
	pageControl: FormControl;
	listState;
	pages: any;
	p: number = 1;
	hasActiveFilters$!: Observable<boolean>;


	constructor(public sidebar: ShopSidebarService,
				private productService: ProductService,
				private navigationService: NavigationService) {
	}

	ngOnInit(): void {
		this.layoutButtons = this.productService.layoutButtons;
		this.sortControl = new FormControl(this.productService.defaultOptions.sort);
		this.limitControl = new FormControl(this.productService.defaultOptions.limit);
		this.pageControl = new FormControl(this.productService.defaultOptions.page);
	}

	ngOnChanges(simpleChanges: SimpleChanges) {
		if (simpleChanges.products.currentValue) {
			this.shownProducts = [...simpleChanges.products.currentValue];
		}
	}

	setLayout(value): void {
		this.layout = value;
	}

	sort(): void {
		if (this.sortControl.value === "name_asc") {
			this.shownProducts.sort((a: any, b: any) => a.name.localeCompare(b.name));
		} else if (this.sortControl.value === "name_desc") {
			this.shownProducts.sort((a: any, b: any) => b.name.localeCompare(a.name));
		} else if (this.sortControl.value === "price_asc") {
			this.shownProducts.sort((a: any, b: any) => a.price - b.price);
		} else if (this.sortControl.value === "price_desc") {
			this.shownProducts.sort((a: any, b: any) => b.price - a.price);
		} else if (this.sortControl.value === "reward_asc") {
			this.shownProducts.sort((a: any, b: any) => a.reward - b.reward);
		} else if (this.sortControl.value === "reward_desc") {
			this.shownProducts.sort((a: any, b: any) => b.reward - a.reward);
		} else if (this.sortControl.value === "default") {
			this.shownProducts = this.products;
		}
	}

	goToProduct(id) {
		this.navigationService.goToProduct(id);
	}
}
