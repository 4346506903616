<div class="container">
	<div class="block-zone__body">
		<div class="block-zone__card category-card category-card--layout--overlay">
			<div class="category-card__body">
				<div class="category-card__overlay-image">
					<img
						[src]="image ? (imagePath + category.mainImage.filename) : ('assets/images/categories/category-1.jpg')"
						sizes="(max-width: 575px) 530px, 305px"
						alt=""
					>
				</div>
				<div class="category-card__overlay-image category-card__overlay-image--blur">
					<img
						[src]="image ? (imagePath + category.mainImage.filename) : ('assets/images/categories/category-1.jpg')"
						sizes="(max-width: 575px) 530px, 305px"
						alt=""
					>
				</div>
				<div class="category-card__content">
					<div class="category-card__info" *ngIf="category">
						<div class="category-card__name">
							<a (click)="goToCategory(category)">{{ category.name[translate.currentLang] }}</a>
						</div>
						<ul class="category-card__children">
							<li *ngFor="let child of category.children | categoryActive">
								<a (click)="goToCategory(child)">{{ child.name[translate.currentLang]}}</a>
							</li>
						</ul>
						<div class="category-card__actions">
							<a (click)="goToCategory(category)" class="btn btn-primary btn-sm">{{ 'BUTTON_SHOP_ALL'|translate }}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="block-zone__widget">
			<div class="block-zone__widget-header">
				<div class="block-zone__tabs">
					<button
						*ngFor="let tab of tabs"
						type="button"
						class="block-zone__tabs-button"
						[class.block-zone__tabs-button--active]="tab === currentTab"
						(click)="setCurrentTab(tab)"
					>{{ tab.name|translate }}</button>
				</div>
				<app-arrow
					class="block-zone__arrow block-zone__arrow--prev"
					direction="prev"
					(click)="carousel.prev()"
				></app-arrow>
				<app-arrow
					class="block-zone__arrow block-zone__arrow--next"
					direction="next"
					(click)="carousel.next()"
				></app-arrow>
			</div>
			<div *ngIf="showFeatured" class="block-zone__widget-body">
				<div
					class="block-zone__carousel"
					[class.block-zone__carousel--loading]="loading"
				>
					<div class="block-zone__carousel-loader"></div>

					<owl-carousel-o [options]="carouselOptions" appOwlPreventClick>
						<ng-template *ngFor="let product of featuredProducts" carouselSlide>
							<div class="block-zone__carousel-item">
								<app-product-card
												  [product]="product"
												  [image]="product?.gallery[0]?.filename"
								></app-product-card>
							</div>
						</ng-template>
					</owl-carousel-o>
				</div>
			</div>

			<div *ngIf="showBestSellers" class="block-zone__widget-body">
				<div
					class="block-zone__carousel"
					[class.block-zone__carousel--loading]="loading"
				>
					<div class="block-zone__carousel-loader"></div>

					<owl-carousel-o [options]="carouselOptions" appOwlPreventClick>
						<ng-template *ngFor="let product of bestsellersProducts" carouselSlide>
							<div class="block-zone__carousel-item">
								<app-product-card
												  [product]="product"
								></app-product-card>
							</div>
						</ng-template>
					</owl-carousel-o>
				</div>
			</div>

			<div *ngIf="showTopRated" class="block-zone__widget-body">
				<div
					class="block-zone__carousel"
					[class.block-zone__carousel--loading]="loading"
				>
					<div class="block-zone__carousel-loader"></div>

					<owl-carousel-o [options]="carouselOptions" appOwlPreventClick>
						<ng-template *ngFor="let product of topRatedProducts" carouselSlide>
							<div class="block-zone__carousel-item">
								<app-product-card
												  [product]="product"
								></app-product-card>
							</div>
						</ng-template>
					</owl-carousel-o>
				</div>
			</div>
		</div>
	</div>
</div>

