import {Component, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DashboardService} from "../../dashboard.service";
import {Router} from "@angular/router";
import {Base} from "../../../../core/base";
import {takeUntil} from "rxjs/operators";
import {TranslationKeys} from "../../../../core/data/translation-keys";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent extends Base implements OnInit {
	changePassForm: FormGroup;
	hide: boolean = true;
	invalidCurrentPass: boolean;
	a: boolean;
	showErrorMessage: boolean;
	errorMessage: string;

	constructor(private dashboardService: DashboardService,
				private router: Router,
				private translate: TranslateService,
				private toastr: ToastrService) {
		super();
	}

	ngOnInit(): void {
		this.changePassForm = this.dashboardService.changePasswordForm();
	}

	change() {
		const changePassBody = {
			currentPassword: this.changePassForm.get('currentPassword').value,
			newPassword: this.changePassForm.get('newPassword').value
		}

		const matchPassword = this.changePassForm.controls['newPassword'].value === this.changePassForm.controls['repeatPassword'].value;

		if (this.changePassForm.valid && matchPassword)
			this.dashboardService.changePassword(changePassBody).pipe(takeUntil(this.destroy$)).subscribe({
					next: () => {
						this.router.navigateByUrl('dashboard/customer-dashboard/customer/account');
						this.toastr.success(this.translate.instant(TranslationKeys.ChangePassword));
					},
					error: (err: any) => {
						this.showErrorMessage = true;
						this.errorMessage = err.error.message;
						this.toastr.error(this.translate.instant(this.errorMessage));
					}
				}
			)
	}
}
