<div class="mobile-menu__setting">
	<button class="mobile-menu__setting-button" (click)="menu.openPanel('TEXT_TOPBAR_LANGUAGE', languagePanel)">
            <mat-icon>language</mat-icon>
		<span class="mobile-menu__setting-title">{{ language | translate }}</span>
		<span class="mobile-menu__setting-arrow"><app-icons icon="arrow-rounded-right-6x9"></app-icons></span>
	</button>
</div>
<div *ngIf="authService.isAuthenticated" class="mobile-menu__setting">
	<button class="mobile-menu__setting-button"
			(click)="menu.openPanel('HEADER_SHOP', shopPanelContent)">
		<span class="material-icons">storefront</span>
		<span class="topbar__button-label">{{ 'SHOPS'|translate }}</span>
		<span class="mobile-menu__setting-arrow"><app-icons icon="arrow-rounded-right-6x9"></app-icons></span>
	</button>
</div>

<ng-template #languagePanel>
		<app-mobile-menu-links [items]="translate.getLangs()" (itemClick)="setLanguage($event)">
			</app-mobile-menu-links>
</ng-template>
<ng-template #shopPanelContent>
		<app-mobile-menu-links [isShops]="true" [items]="shops" (itemClick)="setShop($event)"></app-mobile-menu-links>
</ng-template>
