import {Component, OnInit} from '@angular/core';
import {FilterRating} from "../../../../../core/data/filter.data";

@Component({
	selector: 'app-filter-rating',
	templateUrl: './filter-rating.component.html'
})
export class FilterRatingComponent implements OnInit {

	value = FilterRating;

	constructor() {
	}

	ngOnInit(): void {
	}

}
