import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
	selector: 'app-delete-dialog',
	templateUrl: './delete-dialog.component.html'
})
export class DeleteDialogComponent implements OnInit {
	name: string;

	constructor(private dialogRef: MatDialogRef<DeleteDialogComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	ngOnInit(): void {
		this.name = this.data;
	}

	cancel(): void {
		this.dialogRef.close();
	}

	delete() {
		this.dialogRef.close(true);
	}

}
