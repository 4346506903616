import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from "rxjs";
import {AuthService} from "../../auth/auth.service";
import {AppConfig} from "../../../core/config/appConfig";
import {takeUntil, tap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {
	ShoppingCartReqModel,
	ShoppingCartResModel,
	ShoppingCartsResModel,
	ShoppingCartUpdateModel
} from "../../../core/interfaces/shopping-cart/shopping-cart.model";
import {ApiResultModel} from "../../../core/interfaces/api-result.model";
import {CreateOrderResModel} from "../../../core/interfaces/order/order.model";
import {Base} from "../../../core/base";
import {TranslationKeys} from "../../../core/data/translation-keys";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
	providedIn: 'root'
})
export class CardService extends Base {
	onSupplierTotalAmountChange: ReplaySubject<number> = new ReplaySubject<number>(1);
	onShopChange: ReplaySubject<void> = new ReplaySubject<void>(1);
	cardListChange: ReplaySubject<number> = new ReplaySubject<number>(1);
	cardListCount: number;
	alertMessage : string;
	card: any;
	currentShop: any = {
		quantity: 0,
		suppliers: {},
		cardAmount: 0,
		cardRewardAmount: 0
	};
	private currentShopId: number;

	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private toastr: ToastrService,
		private translate: TranslateService
	) {
		super();
		this.authService.changeShopAddress.pipe(takeUntil(this.destroy$)).subscribe(res => {
			this.currentShopId = res?.id;
			if (!this.card) {
				this.card = {
					shops: {
						[this.currentShopId]: this.currentShop
					}
				};
			}
			this.onShopChange.next();
		});
	}

	addToCard(body: ShoppingCartReqModel): Observable<ApiResultModel<ShoppingCartResModel>> {
		return this.http.post<ApiResultModel<ShoppingCartResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.shoppingCart.addToCard}`, body).pipe(tap({
			next: () => {
				this.cardListChange.next(this.cardListCount);
				this.toastr.success(this.translate.instant(TranslationKeys.AddToCart));
			},
			error: (error) => {
				this.alertMessage = error.error.message;
				this.toastr.error(this.translate.instant(this.alertMessage[0]));
			}
		}))
	}

	getCards(query): Observable<ApiResultModel<ShoppingCartsResModel>> {
		return this.http.get<ApiResultModel<ShoppingCartsResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.shoppingCart.getCards}`, {params: query}).pipe(tap({
			next: (res) => {
				this.cardListCount = res.payload.content.length;
				this.cardListChange.next(this.cardListCount);
			}
		}))
	}

	getCardById(id: string | number): Observable<ApiResultModel<ShoppingCartResModel>> {
		return this.http.get<ApiResultModel<ShoppingCartResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.shoppingCart.getCardById}/${id}`)
	}

	removeCard(cardId: string, itemId: string): Observable<any> {
		return this.http.delete<any>(`${AppConfig.baseUrl}${AppConfig.endpoints.shoppingCart.removeCard}/${cardId}/${itemId}`).pipe(tap({
			next: () => {
				this.cardListChange.next(this.cardListCount);
				this.toastr.success(this.translate.instant(TranslationKeys.DeleteFromCart));
			},
			error: (error) => {
				this.alertMessage = error.error.message;
				this.toastr.error(this.translate.instant(this.alertMessage));
			}
		}))
	}

	removeAllCard(id: string): Observable<any> {
		return this.http.delete<any>(`${AppConfig.baseUrl}${AppConfig.endpoints.shoppingCart.removeAllCard}/${id}`).pipe(tap( {
			next: () => {
				this.toastr.success(this.translate.instant(TranslationKeys.DeleteCart));
			},
			error: (error) => {
				this.alertMessage = error.error.message;
				this.toastr.error(this.translate.instant(this.alertMessage));
			}
		}))
	}

	updateCard(id: string, body: { cartItems: ShoppingCartUpdateModel[] }): Observable<ApiResultModel<ShoppingCartResModel>> {
		return this.http.put<ApiResultModel<ShoppingCartResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.shoppingCart.updateCard}/${id}`, body)
	}

	createOrder(id: { shoppingCart: string, comment: string }): Observable<ApiResultModel<CreateOrderResModel>> {
		return this.http.post<ApiResultModel<CreateOrderResModel>>(`${AppConfig.baseUrl}${AppConfig.endpoints.orders.createOrder}`, id)
	}
}
