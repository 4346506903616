<h2 *ngIf="showTitle" class="account-nav__title">{{ 'HEADER_NAVIGATION' |translate }}</h2>
<ul class="account-nav__list">
	<ng-container *ngIf="isCreated">
		<li class="account-nav__item" *ngFor="let item of customerMenuItems" [routerLinkActive]="menuClass">
			<a [routerLink]="item.url" (click)="closeMenu.emit()">{{item.title | translate}}</a>
		</li>
	</ng-container>
	<li class="account-nav__item" *ngIf="!isCreated">
		<a routerLink="/dashboard/customer-dashboard/customer/create-profile">{{'HEADER_CREATE_PROFILE' | translate}}</a>
	</li>
	<li class="account-nav__divider" role="presentation"></li>
	<li class="account-nav__item">
		<a (click)="logout()">{{ 'LINK_ACCOUNT_LOGOUT'|translate }}</a>
	</li>
</ul>
