<div class="filter-color__list">
	<label *ngFor="let item of filter" class="filter-color__item">
        <span
			class="filter-color__check input-check-color"
			[class.input-check-color--white]="colorType(item.color) === 'white'"
			[class.input-check-color--light]="colorType(item.color) === 'light'"
			[style.color]="item.color"
		>
            <label class="input-check-color__body">
                <input
					class="input-check-color__input"
					type="checkbox"
					[checked]="isItemChecked(item)"
				>
                <span class="input-check-color__box"></span>
                <span class="input-check-color__icon"><app-icons icon="check-12x9"></app-icons></span>
                <span class="input-check-color__stick"></span>
            </label>
        </span>
	</label>
</div>

