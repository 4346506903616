<div class="container">
	<div class="mobile-header__body">
		<button class="mobile-header__menu-button" type="button" (click)="menu.open()">
			<app-icons icon="menu-18x14"></app-icons>
		</button>
		<a class="mobile-header__logo" (click)="goToHome()">
			<app-mobile-logo></app-mobile-logo>
		</a>
		<div
			class="mobile-header__search mobile-search"
			[class.mobile-header__search--open]="searchIsOpen"
			#searchForm1
		>
			<form class="mobile-search__body" (submit)="$event.preventDefault()" [formGroup]="searchForm">
				<label class="sr-only" for="mobile-site-search">{{ 'INPUT_SEARCH_LABEL'|translate }}</label>
<!--				<input-->
<!--					type="text"-->
<!--					id="mobile-site-search"-->
<!--					class="mobile-search__input"-->
<!--					autocapitalize="off"-->
<!--					autocomplete="off"-->
<!--					(input)="onInput($event)"-->
<!--					(focus)="onInputFocus($event)"-->
<!--					(focusout)="toggleSuggestions(false)"-->
<!--					placeholder="{{'INPUT_SEARCH_PLACEHOLDER' | translate }}"-->
<!--					#searchInput-->
<!--				>-->

				<input
					type="text"
					id="mobile-site-search"
					class="mobile-search__input"
					autocapitalize="off"
					autocomplete="off"
					placeholder="{{'INPUT_SEARCH_PLACEHOLDER' | translate }}"
					formControlName="text"
					#searchInput
				>
				<button type="submit" (click)="search()" class="mobile-search__button mobile-search__button--search">
					<app-icons icon="search-20"></app-icons>
				</button>
				<button
					type="button"
					class="mobile-search__button mobile-search__button--close"
					(click)="closeSearch()"
				>
					<app-icons icon="cross-20"></app-icons>
				</button>
				<div class="mobile-search__field"></div>
				<div
					class="search__dropdown search__dropdown--suggestions suggestions"
					[class.search__dropdown--open]="suggestionsIsOpen"
				>
					<div class="suggestions__group" *ngIf="products.length > 0">
						<div class="suggestions__group-title">{{ 'TEXT_PRODUCTS'|translate }}</div>
						<a
							*ngFor="let product of products"
							class="suggestions__item suggestions__product"
							(click)="goToProduct(product)"

						>
							<div class="suggestions__product-image">
								<img
									[src]="product.mainImage?.filename ? imagePath + product.mainImage?.filename : 'assets/images/categories/category-1.jpg'"
									alt="">
							</div>
							<div class="suggestions__product-info">
								<div class="suggestions__product-name">
									{{ product.name }}
								</div>
							</div>
							<div class="suggestions__product-price">
								{{'₽' + product.price }}
							</div>
						</a>
					</div>
				</div>
			</form>
		</div>
		<div class="mobile-header__indicators">
			<div class="mobile-indicator d-md-none" #searchIndicator>
				<button type="button" class="mobile-indicator__button" (click)="openSearch()">
					<span class="mobile-indicator__icon"><app-icons icon="search-20"></app-icons></span>
				</button>
			</div>
			<div class="mobile-indicator d-none d-md-block">
				<a routerLink="/account" class="mobile-indicator__button" (click)="goTo()">
					<span class="mobile-indicator__icon"><app-icons icon="person-20"></app-icons></span>
				</a>
			</div>
			<div (click)="goToWishList()" class="mobile-indicator d-none d-md-block">
				<a routerLink="/shop/wishlist" class="mobile-indicator__button">
                    <span class="mobile-indicator__icon">
                        <app-icons icon="heart-20"></app-icons>
                        <span class="mobile-indicator__counter"
						>{{(wishListCount | async) || 0}}</span>
                    </span>
				</a>
			</div>
			<div class="mobile-indicator" (click)="goToCard()">
				<a routerLink="/shop/cart" class="mobile-indicator__button">
                    <span class="mobile-indicator__icon">
                        <app-icons icon="cart-20"></app-icons>
                        <span class="mobile-indicator__counter"
						>{{(cardListCount | async) || 0}}</span>
                    </span>
				</a>
			</div>
		</div>
	</div>
</div>

