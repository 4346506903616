<div class="filter-list__list">
	<label
		*ngFor="let item of filters"
		class="filter-list__item"
		[class.filter-list__item--disabled]="item.count === 0"
	>
		<div class="filter-list__input">
			<span class="input-check__body">
    		<input class="input-check__input" type="checkbox">
    		<span class="input-check__box"></span>
		</span>
		</div>


		<span class="filter-list__title">{{ item.name }}</span>
		<span *ngIf="item.count" class="filter-list__counter">{{ item.count }}</span>
	</label>
</div>
