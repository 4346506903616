<div *ngIf="isAuthenticated" class="indicator indicator--trigger--click indicator--open">
	<a class="indicator__button" (click)="goToWishListPage()">
        <span class="indicator__icon">
          <span>
			  <app-icons icon="heart-32"></app-icons>
          </span>
          <span class="indicator__counter">{{(wishListCount | async) || 0}}</span>
        </span>
	</a>
</div>
<div class="indicator indicator--trigger--click indicator--open" (clickOutside)="showAccountMenu = false">
	<a class="indicator__button">
		<span *ngIf="!isAuthenticated" (click)="goToAuth()">
			<span class="indicator__icon">
          <span>
			  <app-icons icon="person-32"></app-icons>
          </span>
       	 </span>
			<span class="indicator__value" style="margin-top: 7%">
				{{'LOGIN_SIGNUP' | translate}}
			</span>
		</span>
		<span *ngIf="isAuthenticated" (click)="accountMenu()">
			<span class="indicator__icon">
			  <app-icons icon="person-32"></app-icons>
       	 </span>
			<span class="indicator__title">{{ 'WELCOME' | translate }}</span>
    		<span class="indicator__value">{{'MY_ACCOUNT' | translate}}</span>
	  	</span>
	</a>
	<div class="indicator__content" *ngIf="showAccountMenu && isAuthenticated">
		<app-dashboard-menu [menuClass]="''" class="account-menu" (closeMenu)="closeMenu()"></app-dashboard-menu>
	</div>
</div>
<div *ngIf="isAuthenticated" class="indicator indicator--trigger--click indicator--open"
	 (clickOutside)="showCard = false">
	<a class="indicator__button" (click)="goToShoppingCart()">
        <span class="indicator__icon">
          <span>
			  <app-icons icon="cart-32"></app-icons>
          </span>
          <span class="indicator__counter">{{(cardListCount | async) || 0}}</span>
        </span>
		<span class="indicator__title">{{'₽ '}}</span>
		<span class="indicator__value">{{"HEADER_SHOPPING_CART" | translate}}</span>
	</a>
</div>
